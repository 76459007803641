import { event } from '@rategravity/frontend/modules/user-actions';
import { IconLink } from '@rategravity/own-up-component-library';
import { ShareIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/share';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useActionPipe } from '../../hooks/use-action-pipe';
import { copy } from '../../modules/copy-to-clipboard';
import { pushNotification } from '../../redux/notifications/actions';
import { NotificationCenter } from '../notification';

export const ShareableLinkButton = ({
  url,
  eventProperties
}: {
  url: string;
  eventProperties: { advisor: string; scenario: string };
}) => {
  const dispatch = useDispatch();
  const dispatchAction = useActionPipe();

  const copyLinkEvent = event('clickShareLink', {
    ...eventProperties,
    url: window.location.href
  });

  const onShare = useCallback(() => {
    void (async () => {
      if (typeof navigator.share === 'function') {
        try {
          await navigator.share({
            title: 'Your rates',
            url,
            text: 'Your loan options from Own Up'
          });
          return;
        } catch (err) {
          console.error('error sharing', err);
        }
      }
      copy(url);
      dispatch(pushNotification('Link copied'));
      dispatchAction(copyLinkEvent);
    })();
  }, [copyLinkEvent, dispatch, dispatchAction, url]);

  return (
    <React.Fragment>
      <IconLink icon={ShareIcon} onClick={onShare} link="#">
        Share
      </IconLink>
      <NotificationCenter />
    </React.Fragment>
  );
};
