export interface ScrollTo {
  scrollTo(params: { top?: number; left?: number }): void;
  scrollToTop(): void;
}

export const windowScrollTo: ScrollTo = {
  scrollTo: (params) => {
    window.scrollTo({
      ...params,
      behavior: 'smooth'
    });
  },
  scrollToTop: () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto'
    });
  }
};
