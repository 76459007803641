import { standardizeResponse } from '../authentication';
import {
  lenderRepresentationsConverter,
  lenderReviewConverter,
  ouLenderReviewDataConverter
} from './type';

// zillow reviews
export const fetchLenderReview = async (lenderId: string) =>
  standardizeResponse(
    await fetch(`/api/lender/${lenderId}/reviews`, {
      method: 'GET'
    }),
    lenderReviewConverter
  );

// OU reviews
export const fetchOULenderReview = async (lenderId: string) =>
  standardizeResponse(
    await fetch(`/api/lender/${lenderId}/own-up/reviews`, {
      method: 'GET'
    }),
    ouLenderReviewDataConverter
  );

export const fetchLenderRepresentations = async (lenderIds: string[]) => {
  return await standardizeResponse(
    await fetch(`/api/lender/representations?lenderIds=${lenderIds.join(',')}`, {
      method: 'GET'
    }),
    lenderRepresentationsConverter
  );
};
