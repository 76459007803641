import React from 'react';
import * as colors from '../../../colors';

export const LockIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.75 12.4999C10.75 12.0857 10.4142 11.7499 10 11.7499C9.58579 11.7499 9.25 12.0857 9.25 12.4999V13.4999C9.25 13.9142 9.58579 14.2499 10 14.2499C10.4142 14.2499 10.75 13.9142 10.75 13.4999V12.4999Z"
      fill={colors.CHARCOAL_100}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 1.99994C12.0711 1.99994 13.75 3.67887 13.75 5.74994V7.99994H14C15.1046 7.99994 16 8.89537 16 9.99994V15.9999C16 17.1045 15.1046 17.9999 14 17.9999H6C4.89543 17.9999 4 17.1045 4 15.9999V9.99994C4 8.89537 4.89543 7.99994 6 7.99994H6.25L6.25 5.74994C6.25 3.67887 7.92893 1.99994 10 1.99994ZM12.25 5.74994V7.99994H7.75L7.75 5.74994C7.75 4.5073 8.75736 3.49994 10 3.49994C11.2426 3.49994 12.25 4.5073 12.25 5.74994ZM14 9.49994H6C5.72386 9.49994 5.5 9.7238 5.5 9.99994V15.9999C5.5 16.2761 5.72386 16.4999 6 16.4999H14C14.2761 16.4999 14.5 16.2761 14.5 15.9999V9.99994C14.5 9.7238 14.2761 9.49994 14 9.49994Z"
      fill={colors.CHARCOAL_100}
    />
  </svg>
);
