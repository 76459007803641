import { OwnUpSmallBody } from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: ${({ theme }) => theme.spacing(3, 0, 6)};
  ${({ theme }) => theme.breakpoints.up('lg')} {
    padding: ${({ theme }) => theme.spacing(6, 0, 8)};
  }
`;

const List = styled.ul`
  margin-top: 0;
`;

export const Disclaimer = ({ customDisclaimers }: { customDisclaimers: string[] }) => (
  <Container>
    <OwnUpSmallBody variant="body1"> Please note:</OwnUpSmallBody>
    <List>
      {customDisclaimers.map((customDisclaimer: string, i) => (
        <li key={`disc-${i}`}>
          <OwnUpSmallBody variant="body1">{customDisclaimer}</OwnUpSmallBody>
        </li>
      ))}
    </List>
  </Container>
);
