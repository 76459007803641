import { Func, Func1 } from './func';

export type FilterFunction<
  TOut,
  TArgs extends any[],
  TNextOut,
  TNextArgs extends any[],
  T = void
> = Func1<Func<TNextOut, TNextArgs>, TOut, TArgs, T>;

export interface FilteredFunction<
  TOut,
  TArgs extends any[],
  TFunc extends FilterFunction<any, any, any, any, T>[],
  TSource extends Func<any, any, T>,
  T = void
> extends Func<TOut, TArgs, T> {
  /**
   * The Filters to apply before Source
   */
  readonly filters: TFunc;
  /**
   * The Source Function
   */
  readonly source: TSource;
}

/**
 * Apply a series of filter functions to a source function.
 *
 * A Filter function takes a next filter as it's first argument, it can choose
 * to call the next function or return pre-emptively optionally modifying the
 * inputs and outputs. A Filter function is one of the most general ways of composing
 * functions and is extremely useful as a core concept for things like error handling or
 * ensuring that functions run within a timeout.
 * @param source the function that produces the root result
 */
export function filter<TOut, TArgs extends any[], TOut2, TArgs2 extends any[], T = void>(
  f1: FilterFunction<TOut, TArgs, TOut2, TArgs2, T>,
  source: Func<TOut2, TArgs2, T>
): FilteredFunction<TOut, TArgs, [typeof f1], typeof source, T>;
export function filter<
  TOut,
  TArgs extends any[],
  TOut2,
  TArgs2 extends any[],
  TOut3,
  TArgs3 extends any[],
  T = void
>(
  f1: FilterFunction<TOut, TArgs, TOut2, TArgs2, T>,
  f2: FilterFunction<TOut2, TArgs2, TOut3, TArgs3, T>,
  source: Func<TOut3, TArgs3, T>
): FilteredFunction<TOut, TArgs, [typeof f1, typeof f2], typeof source, T>;
/**
 * Apply a series of filter functions to a source function.
 *
 * A Filter function takes a next filter as it's first argument, it can choose
 * to call the next function or return pre-emptively optionally modifying the
 * inputs and outputs. A Filter function is one of the most general ways of composing
 * functions and is extremely useful as a core concept for things like error handling or
 * ensuring that functions run within a timeout.
 * @param source the function that produces the root result
 */
export function filter<
  TOut,
  TArgs extends any[],
  TOut2,
  TArgs2 extends any[],
  TOut3,
  TArgs3 extends any[],
  TOut4,
  TArgs4 extends any[],
  T = void
>(
  f1: FilterFunction<TOut, TArgs, TOut2, TArgs2, T>,
  f2: FilterFunction<TOut2, TArgs2, TOut3, TArgs3, T>,
  f3: FilterFunction<TOut3, TArgs3, TOut4, TArgs4, T>,
  source: Func<TOut4, TArgs4, T>
): FilteredFunction<TOut, TArgs, [typeof f1, typeof f2, typeof f3], typeof source, T>;
/**
 * Apply a series of filter functions to a source function.
 *
 * A Filter function takes a next filter as it's first argument, it can choose
 * to call the next function or return pre-emptively optionally modifying the
 * inputs and outputs. A Filter function is one of the most general ways of composing
 * functions and is extremely useful as a core concept for things like error handling or
 * ensuring that functions run within a timeout.
 * @param source the function that produces the root result
 */
export function filter<
  TOut,
  TArgs extends any[],
  TOut2,
  TArgs2 extends any[],
  TOut3,
  TArgs3 extends any[],
  TOut4,
  TArgs4 extends any[],
  TOut5,
  TArgs5 extends any[],
  T = void
>(
  f1: FilterFunction<TOut, TArgs, TOut2, TArgs2, T>,
  f2: FilterFunction<TOut2, TArgs2, TOut3, TArgs3, T>,
  f3: FilterFunction<TOut3, TArgs3, TOut4, TArgs4, T>,
  f4: FilterFunction<TOut4, TArgs4, TOut5, TArgs5, T>,
  source: Func<TOut5, TArgs5, T>
): FilteredFunction<TOut, TArgs, [typeof f1, typeof f2, typeof f3, typeof f4], typeof source, T>;
/**
 * Apply a series of filter functions to a source function.
 *
 * A Filter function takes a next filter as it's first argument, it can choose
 * to call the next function or return pre-emptively optionally modifying the
 * inputs and outputs. A Filter function is one of the most general ways of composing
 * functions and is extremely useful as a core concept for things like error handling or
 * ensuring that functions run within a timeout.
 * @param source the function that produces the root result
 */
export function filter<
  TOut,
  TArgs extends any[],
  TOut2,
  TArgs2 extends any[],
  TOut3,
  TArgs3 extends any[],
  TOut4,
  TArgs4 extends any[],
  TOut5,
  TArgs5 extends any[],
  TOut6,
  TArgs6 extends any[],
  T = void
>(
  f1: FilterFunction<TOut, TArgs, TOut2, TArgs2, T>,
  f2: FilterFunction<TOut2, TArgs2, TOut3, TArgs3, T>,
  f3: FilterFunction<TOut3, TArgs3, TOut4, TArgs4, T>,
  f4: FilterFunction<TOut4, TArgs4, TOut5, TArgs5, T>,
  f5: FilterFunction<TOut5, TArgs5, TOut6, TArgs6, T>,
  source: Func<TOut6, TArgs6, T>
): FilteredFunction<
  TOut,
  TArgs,
  [typeof f1, typeof f2, typeof f3, typeof f4, typeof f5],
  typeof source,
  T
>;
/**
 * Apply a series of filter functions to a source function.
 *
 * A Filter function takes a next filter as it's first argument, it can choose
 * to call the next function or return pre-emptively optionally modifying the
 * inputs and outputs. A Filter function is one of the most general ways of composing
 * functions and is extremely useful as a core concept for things like error handling or
 * ensuring that functions run within a timeout.
 * @param source the function that produces the root result
 */
export function filter<
  TOut,
  TArgs extends any[],
  TOut2,
  TArgs2 extends any[],
  TOut3,
  TArgs3 extends any[],
  TOut4,
  TArgs4 extends any[],
  TOut5,
  TArgs5 extends any[],
  TOut6,
  TArgs6 extends any[],
  TOut7,
  TArgs7 extends any[],
  T = void
>(
  f1: FilterFunction<TOut, TArgs, TOut2, TArgs2, T>,
  f2: FilterFunction<TOut2, TArgs2, TOut3, TArgs3, T>,
  f3: FilterFunction<TOut3, TArgs3, TOut4, TArgs4, T>,
  f4: FilterFunction<TOut4, TArgs4, TOut5, TArgs5, T>,
  f5: FilterFunction<TOut5, TArgs5, TOut6, TArgs6, T>,
  f6: FilterFunction<TOut6, TArgs6, TOut7, TArgs7, T>,
  source: Func<TOut7, TArgs7, T>
): FilteredFunction<
  TOut,
  TArgs,
  [typeof f1, typeof f2, typeof f3, typeof f4, typeof f5, typeof f6],
  typeof source,
  T
>;
/**
 * Apply a series of filter functions to a source function.
 *
 * A Filter function takes a next filter as it's first argument, it can choose
 * to call the next function or return pre-emptively optionally modifying the
 * inputs and outputs. A Filter function is one of the most general ways of composing
 * functions and is extremely useful as a core concept for things like error handling or
 * ensuring that functions run within a timeout.
 * @param source the function that produces the root result
 */
export function filter<
  TOut,
  TArgs extends any[],
  TOut2,
  TArgs2 extends any[],
  TOut3,
  TArgs3 extends any[],
  TOut4,
  TArgs4 extends any[],
  TOut5,
  TArgs5 extends any[],
  TOut6,
  TArgs6 extends any[],
  TOut7,
  TArgs7 extends any[],
  TOut8,
  TArgs8 extends any[],
  T = void
>(
  f1: FilterFunction<TOut, TArgs, TOut2, TArgs2, T>,
  f2: FilterFunction<TOut2, TArgs2, TOut3, TArgs3, T>,
  f3: FilterFunction<TOut3, TArgs3, TOut4, TArgs4, T>,
  f4: FilterFunction<TOut4, TArgs4, TOut5, TArgs5, T>,
  f5: FilterFunction<TOut5, TArgs5, TOut6, TArgs6, T>,
  f6: FilterFunction<TOut6, TArgs6, TOut7, TArgs7, T>,
  f7: FilterFunction<TOut7, TArgs7, TOut8, TArgs8, T>,
  source: Func<TOut8, TArgs8, T>
): FilteredFunction<
  TOut,
  TArgs,
  [typeof f1, typeof f2, typeof f3, typeof f4, typeof f5, typeof f6, typeof f7],
  typeof source,
  T
>;
/**
 * Apply a series of filter functions to a source function.
 *
 * A Filter function takes a next filter as it's first argument, it can choose
 * to call the next function or return pre-emptively optionally modifying the
 * inputs and outputs. A Filter function is one of the most general ways of composing
 * functions and is extremely useful as a core concept for things like error handling or
 * ensuring that functions run within a timeout.
 * @param source the function that produces the root result
 */
export function filter<
  TOut,
  TArgs extends any[],
  TOut2,
  TArgs2 extends any[],
  TOut3,
  TArgs3 extends any[],
  TOut4,
  TArgs4 extends any[],
  TOut5,
  TArgs5 extends any[],
  TOut6,
  TArgs6 extends any[],
  TOut7,
  TArgs7 extends any[],
  TOut8,
  TArgs8 extends any[],
  TOut9,
  TArgs9 extends any[],
  T = void
>(
  f1: FilterFunction<TOut, TArgs, TOut2, TArgs2, T>,
  f2: FilterFunction<TOut2, TArgs2, TOut3, TArgs3, T>,
  f3: FilterFunction<TOut3, TArgs3, TOut4, TArgs4, T>,
  f4: FilterFunction<TOut4, TArgs4, TOut5, TArgs5, T>,
  f5: FilterFunction<TOut5, TArgs5, TOut6, TArgs6, T>,
  f6: FilterFunction<TOut6, TArgs6, TOut7, TArgs7, T>,
  f7: FilterFunction<TOut7, TArgs7, TOut8, TArgs8, T>,
  f8: FilterFunction<TOut8, TArgs8, TOut9, TArgs9, T>,
  source: Func<TOut9, TArgs9, T>
): FilteredFunction<
  TOut,
  TArgs,
  [typeof f1, typeof f2, typeof f3, typeof f4, typeof f5, typeof f6, typeof f7, typeof f8],
  typeof source,
  T
>;
/**
 * Apply a series of filter functions to a source function.
 *
 * A Filter function takes a next filter as it's first argument, it can choose
 * to call the next function or return pre-emptively optionally modifying the
 * inputs and outputs. A Filter function is one of the most general ways of composing
 * functions and is extremely useful as a core concept for things like error handling or
 * ensuring that functions run within a timeout.
 * @param source the function that produces the root result
 */
export function filter<
  TOut,
  TArgs extends any[],
  TOut2,
  TArgs2 extends any[],
  TOut3,
  TArgs3 extends any[],
  TOut4,
  TArgs4 extends any[],
  TOut5,
  TArgs5 extends any[],
  TOut6,
  TArgs6 extends any[],
  TOut7,
  TArgs7 extends any[],
  TOut8,
  TArgs8 extends any[],
  TOut9,
  TArgs9 extends any[],
  TOut10,
  TArgs10 extends any[],
  T = void
>(
  f1: FilterFunction<TOut, TArgs, TOut2, TArgs2, T>,
  f2: FilterFunction<TOut2, TArgs2, TOut3, TArgs3, T>,
  f3: FilterFunction<TOut3, TArgs3, TOut4, TArgs4, T>,
  f4: FilterFunction<TOut4, TArgs4, TOut5, TArgs5, T>,
  f5: FilterFunction<TOut5, TArgs5, TOut6, TArgs6, T>,
  f6: FilterFunction<TOut6, TArgs6, TOut7, TArgs7, T>,
  f7: FilterFunction<TOut7, TArgs7, TOut8, TArgs8, T>,
  f8: FilterFunction<TOut8, TArgs8, TOut9, TArgs9, T>,
  f9: FilterFunction<TOut9, TArgs9, TOut10, TArgs10, T>,
  source: Func<TOut10, TArgs10, T>
): FilteredFunction<
  TOut,
  TArgs,
  [
    typeof f1,
    typeof f2,
    typeof f3,
    typeof f4,
    typeof f5,
    typeof f6,
    typeof f7,
    typeof f8,
    typeof f9
  ],
  typeof source,
  T
>;
/**
 * Apply a series of filter functions to a source function.
 *
 * A Filter function takes a next filter as it's first argument, it can choose
 * to call the next function or return pre-emptively optionally modifying the
 * inputs and outputs. A Filter function is one of the most general ways of composing
 * functions and is extremely useful as a core concept for things like error handling or
 * ensuring that functions run within a timeout.
 * @param source the function that produces the root result
 */
export function filter<
  TOut,
  TArgs extends any[],
  TOut2,
  TArgs2 extends any[],
  TOut3,
  TArgs3 extends any[],
  TOut4,
  TArgs4 extends any[],
  TOut5,
  TArgs5 extends any[],
  TOut6,
  TArgs6 extends any[],
  TOut7,
  TArgs7 extends any[],
  TOut8,
  TArgs8 extends any[],
  TOut9,
  TArgs9 extends any[],
  TOut10,
  TArgs10 extends any[],
  TOut11,
  TArgs11 extends any[],
  T = void
>(
  f1: FilterFunction<TOut, TArgs, TOut2, TArgs2, T>,
  f2: FilterFunction<TOut2, TArgs2, TOut3, TArgs3, T>,
  f3: FilterFunction<TOut3, TArgs3, TOut4, TArgs4, T>,
  f4: FilterFunction<TOut4, TArgs4, TOut5, TArgs5, T>,
  f5: FilterFunction<TOut5, TArgs5, TOut6, TArgs6, T>,
  f6: FilterFunction<TOut6, TArgs6, TOut7, TArgs7, T>,
  f7: FilterFunction<TOut7, TArgs7, TOut8, TArgs8, T>,
  f8: FilterFunction<TOut8, TArgs8, TOut9, TArgs9, T>,
  f9: FilterFunction<TOut9, TArgs9, TOut10, TArgs10, T>,
  f10: FilterFunction<TOut10, TArgs10, TOut11, TArgs11, T>,
  source: Func<TOut11, TArgs11, T>
): FilteredFunction<
  TOut,
  TArgs,
  [
    typeof f1,
    typeof f2,
    typeof f3,
    typeof f4,
    typeof f5,
    typeof f6,
    typeof f7,
    typeof f8,
    typeof f9,
    typeof f10
  ],
  typeof source,
  T
>;
/**
 * Apply a series of filter functions to a source function.
 *
 * A Filter function takes a next filter as it's first argument, it can choose
 * to call the next function or return pre-emptively optionally modifying the
 * inputs and outputs. A Filter function is one of the most general ways of composing
 * functions and is extremely useful as a core concept for things like error handling or
 * ensuring that functions run within a timeout.
 * @param source the function that produces the root result
 */
export function filter<
  TOut,
  TArgs extends any[],
  TOut2,
  TArgs2 extends any[],
  TOut3,
  TArgs3 extends any[],
  TOut4,
  TArgs4 extends any[],
  TOut5,
  TArgs5 extends any[],
  TOut6,
  TArgs6 extends any[],
  TOut7,
  TArgs7 extends any[],
  TOut8,
  TArgs8 extends any[],
  TOut9,
  TArgs9 extends any[],
  TOut10,
  TArgs10 extends any[],
  TOut11,
  TArgs11 extends any[],
  TOut12,
  TArgs12 extends any[],
  T = void
>(
  f1: FilterFunction<TOut, TArgs, TOut2, TArgs2, T>,
  f2: FilterFunction<TOut2, TArgs2, TOut3, TArgs3, T>,
  f3: FilterFunction<TOut3, TArgs3, TOut4, TArgs4, T>,
  f4: FilterFunction<TOut4, TArgs4, TOut5, TArgs5, T>,
  f5: FilterFunction<TOut5, TArgs5, TOut6, TArgs6, T>,
  f6: FilterFunction<TOut6, TArgs6, TOut7, TArgs7, T>,
  f7: FilterFunction<TOut7, TArgs7, TOut8, TArgs8, T>,
  f8: FilterFunction<TOut8, TArgs8, TOut9, TArgs9, T>,
  f9: FilterFunction<TOut9, TArgs9, TOut10, TArgs10, T>,
  f10: FilterFunction<TOut10, TArgs10, TOut11, TArgs11, T>,
  f11: FilterFunction<TOut11, TArgs11, TOut12, TArgs12, T>,
  source: Func<TOut12, TArgs12, T>
): FilteredFunction<
  TOut,
  TArgs,
  [
    typeof f1,
    typeof f2,
    typeof f3,
    typeof f4,
    typeof f5,
    typeof f6,
    typeof f7,
    typeof f8,
    typeof f9,
    typeof f10,
    typeof f11
  ],
  typeof source,
  T
>;
/**
 * Apply a series of filter functions to a source function.
 *
 * A Filter function takes a next filter as it's first argument, it can choose
 * to call the next function or return pre-emptively optionally modifying the
 * inputs and outputs. A Filter function is one of the most general ways of composing
 * functions and is extremely useful as a core concept for things like error handling or
 * ensuring that functions run within a timeout.
 * @param source the function that produces the root result
 */
export function filter<
  TOut,
  TArgs extends any[],
  TOut2,
  TArgs2 extends any[],
  TOut3,
  TArgs3 extends any[],
  TOut4,
  TArgs4 extends any[],
  TOut5,
  TArgs5 extends any[],
  TOut6,
  TArgs6 extends any[],
  TOut7,
  TArgs7 extends any[],
  TOut8,
  TArgs8 extends any[],
  TOut9,
  TArgs9 extends any[],
  TOut10,
  TArgs10 extends any[],
  TOut11,
  TArgs11 extends any[],
  TOut12,
  TArgs12 extends any[],
  TOut13,
  TArgs13 extends any[],
  T = void
>(
  f1: FilterFunction<TOut, TArgs, TOut2, TArgs2, T>,
  f2: FilterFunction<TOut2, TArgs2, TOut3, TArgs3, T>,
  f3: FilterFunction<TOut3, TArgs3, TOut4, TArgs4, T>,
  f4: FilterFunction<TOut4, TArgs4, TOut5, TArgs5, T>,
  f5: FilterFunction<TOut5, TArgs5, TOut6, TArgs6, T>,
  f6: FilterFunction<TOut6, TArgs6, TOut7, TArgs7, T>,
  f7: FilterFunction<TOut7, TArgs7, TOut8, TArgs8, T>,
  f8: FilterFunction<TOut8, TArgs8, TOut9, TArgs9, T>,
  f9: FilterFunction<TOut9, TArgs9, TOut10, TArgs10, T>,
  f10: FilterFunction<TOut10, TArgs10, TOut11, TArgs11, T>,
  f11: FilterFunction<TOut11, TArgs11, TOut12, TArgs12, T>,
  f12: FilterFunction<TOut12, TArgs12, TOut13, TArgs13, T>,
  source: Func<TOut13, TArgs13, T>
): FilteredFunction<
  TOut,
  TArgs,
  [
    typeof f1,
    typeof f2,
    typeof f3,
    typeof f4,
    typeof f5,
    typeof f6,
    typeof f7,
    typeof f8,
    typeof f9,
    typeof f10,
    typeof f11,
    typeof f12
  ],
  typeof source,
  T
>;
/**
 * Apply a series of filter functions to a source function.
 *
 * A Filter function takes a next filter as it's first argument, it can choose
 * to call the next function or return pre-emptively optionally modifying the
 * inputs and outputs. A Filter function is one of the most general ways of composing
 * functions and is extremely useful as a core concept for things like error handling or
 * ensuring that functions run within a timeout.
 * @param source the function that produces the root result
 */
export function filter<
  TOut,
  TArgs extends any[],
  TOut2,
  TArgs2 extends any[],
  TOut3,
  TArgs3 extends any[],
  TOut4,
  TArgs4 extends any[],
  TOut5,
  TArgs5 extends any[],
  TOut6,
  TArgs6 extends any[],
  TOut7,
  TArgs7 extends any[],
  TOut8,
  TArgs8 extends any[],
  TOut9,
  TArgs9 extends any[],
  TOut10,
  TArgs10 extends any[],
  TOut11,
  TArgs11 extends any[],
  TOut12,
  TArgs12 extends any[],
  TOut13,
  TArgs13 extends any[],
  TOut14,
  TArgs14 extends any[],
  T = void
>(
  f1: FilterFunction<TOut, TArgs, TOut2, TArgs2, T>,
  f2: FilterFunction<TOut2, TArgs2, TOut3, TArgs3, T>,
  f3: FilterFunction<TOut3, TArgs3, TOut4, TArgs4, T>,
  f4: FilterFunction<TOut4, TArgs4, TOut5, TArgs5, T>,
  f5: FilterFunction<TOut5, TArgs5, TOut6, TArgs6, T>,
  f6: FilterFunction<TOut6, TArgs6, TOut7, TArgs7, T>,
  f7: FilterFunction<TOut7, TArgs7, TOut8, TArgs8, T>,
  f8: FilterFunction<TOut8, TArgs8, TOut9, TArgs9, T>,
  f9: FilterFunction<TOut9, TArgs9, TOut10, TArgs10, T>,
  f10: FilterFunction<TOut10, TArgs10, TOut11, TArgs11, T>,
  f11: FilterFunction<TOut11, TArgs11, TOut12, TArgs12, T>,
  f12: FilterFunction<TOut12, TArgs12, TOut13, TArgs13, T>,
  f13: FilterFunction<TOut13, TArgs13, TOut14, TArgs14, T>,
  source: Func<TOut14, TArgs14, T>
): FilteredFunction<
  TOut,
  TArgs,
  [
    typeof f1,
    typeof f2,
    typeof f3,
    typeof f4,
    typeof f5,
    typeof f6,
    typeof f7,
    typeof f8,
    typeof f9,
    typeof f10,
    typeof f11,
    typeof f12,
    typeof f13
  ],
  typeof source,
  T
>;
/**
 * Apply a series of filter functions to a source function.
 *
 * A Filter function takes a next filter as it's first argument, it can choose
 * to call the next function or return pre-emptively optionally modifying the
 * inputs and outputs. A Filter function is one of the most general ways of composing
 * functions and is extremely useful as a core concept for things like error handling or
 * ensuring that functions run within a timeout.
 * @param source the function that produces the root result
 */
export function filter<
  TOut,
  TArgs extends any[],
  TOut2,
  TArgs2 extends any[],
  TOut3,
  TArgs3 extends any[],
  TOut4,
  TArgs4 extends any[],
  TOut5,
  TArgs5 extends any[],
  TOut6,
  TArgs6 extends any[],
  TOut7,
  TArgs7 extends any[],
  TOut8,
  TArgs8 extends any[],
  TOut9,
  TArgs9 extends any[],
  TOut10,
  TArgs10 extends any[],
  TOut11,
  TArgs11 extends any[],
  TOut12,
  TArgs12 extends any[],
  TOut13,
  TArgs13 extends any[],
  TOut14,
  TArgs14 extends any[],
  TOut15,
  TArgs15 extends any[],
  T = void
>(
  f1: FilterFunction<TOut, TArgs, TOut2, TArgs2, T>,
  f2: FilterFunction<TOut2, TArgs2, TOut3, TArgs3, T>,
  f3: FilterFunction<TOut3, TArgs3, TOut4, TArgs4, T>,
  f4: FilterFunction<TOut4, TArgs4, TOut5, TArgs5, T>,
  f5: FilterFunction<TOut5, TArgs5, TOut6, TArgs6, T>,
  f6: FilterFunction<TOut6, TArgs6, TOut7, TArgs7, T>,
  f7: FilterFunction<TOut7, TArgs7, TOut8, TArgs8, T>,
  f8: FilterFunction<TOut8, TArgs8, TOut9, TArgs9, T>,
  f9: FilterFunction<TOut9, TArgs9, TOut10, TArgs10, T>,
  f10: FilterFunction<TOut10, TArgs10, TOut11, TArgs11, T>,
  f11: FilterFunction<TOut11, TArgs11, TOut12, TArgs12, T>,
  f12: FilterFunction<TOut12, TArgs12, TOut13, TArgs13, T>,
  f13: FilterFunction<TOut13, TArgs13, TOut14, TArgs14, T>,
  f14: FilterFunction<TOut14, TArgs14, TOut15, TArgs15, T>,
  source: Func<TOut15, TArgs15, T>
): FilteredFunction<
  TOut,
  TArgs,
  [
    typeof f1,
    typeof f2,
    typeof f3,
    typeof f4,
    typeof f5,
    typeof f6,
    typeof f7,
    typeof f8,
    typeof f9,
    typeof f10,
    typeof f11,
    typeof f12,
    typeof f13,
    typeof f14
  ],
  typeof source,
  T
>;
/**
 * Apply a series of filter functions to a source function.
 *
 * A Filter function takes a next filter as it's first argument, it can choose
 * to call the next function or return pre-emptively optionally modifying the
 * inputs and outputs. A Filter function is one of the most general ways of composing
 * functions and is extremely useful as a core concept for things like error handling or
 * ensuring that functions run within a timeout.
 * @param source the function that produces the root result
 */
export function filter<
  TOut,
  TArgs extends any[],
  TOut2,
  TArgs2 extends any[],
  TOut3,
  TArgs3 extends any[],
  TOut4,
  TArgs4 extends any[],
  TOut5,
  TArgs5 extends any[],
  TOut6,
  TArgs6 extends any[],
  TOut7,
  TArgs7 extends any[],
  TOut8,
  TArgs8 extends any[],
  TOut9,
  TArgs9 extends any[],
  TOut10,
  TArgs10 extends any[],
  TOut11,
  TArgs11 extends any[],
  TOut12,
  TArgs12 extends any[],
  TOut13,
  TArgs13 extends any[],
  TOut14,
  TArgs14 extends any[],
  TOut15,
  TArgs15 extends any[],
  TOut16,
  TArgs16 extends any[],
  T = void
>(
  f1: FilterFunction<TOut, TArgs, TOut2, TArgs2, T>,
  f2: FilterFunction<TOut2, TArgs2, TOut3, TArgs3, T>,
  f3: FilterFunction<TOut3, TArgs3, TOut4, TArgs4, T>,
  f4: FilterFunction<TOut4, TArgs4, TOut5, TArgs5, T>,
  f5: FilterFunction<TOut5, TArgs5, TOut6, TArgs6, T>,
  f6: FilterFunction<TOut6, TArgs6, TOut7, TArgs7, T>,
  f7: FilterFunction<TOut7, TArgs7, TOut8, TArgs8, T>,
  f8: FilterFunction<TOut8, TArgs8, TOut9, TArgs9, T>,
  f9: FilterFunction<TOut9, TArgs9, TOut10, TArgs10, T>,
  f10: FilterFunction<TOut10, TArgs10, TOut11, TArgs11, T>,
  f11: FilterFunction<TOut11, TArgs11, TOut12, TArgs12, T>,
  f12: FilterFunction<TOut12, TArgs12, TOut13, TArgs13, T>,
  f13: FilterFunction<TOut13, TArgs13, TOut14, TArgs14, T>,
  f14: FilterFunction<TOut14, TArgs14, TOut15, TArgs15, T>,
  f15: FilterFunction<TOut15, TArgs15, TOut16, TArgs16, T>,
  source: Func<TOut16, TArgs16, T>
): FilteredFunction<
  TOut,
  TArgs,
  [
    typeof f1,
    typeof f2,
    typeof f3,
    typeof f4,
    typeof f5,
    typeof f6,
    typeof f7,
    typeof f8,
    typeof f9,
    typeof f10,
    typeof f11,
    typeof f12,
    typeof f13,
    typeof f14,
    typeof f15
  ],
  typeof source,
  T
>;
/**
 * Apply a series of filter functions to a source function.
 *
 * A Filter function takes a next filter as it's first argument, it can choose
 * to call the next function or return pre-emptively optionally modifying the
 * inputs and outputs. A Filter function is one of the most general ways of composing
 * functions and is extremely useful as a core concept for things like error handling or
 * ensuring that functions run within a timeout.
 * @param source the function that produces the root result
 */
export function filter<
  TOut,
  TArgs extends any[],
  TOut2,
  TArgs2 extends any[],
  TOut3,
  TArgs3 extends any[],
  TOut4,
  TArgs4 extends any[],
  TOut5,
  TArgs5 extends any[],
  TOut6,
  TArgs6 extends any[],
  TOut7,
  TArgs7 extends any[],
  TOut8,
  TArgs8 extends any[],
  TOut9,
  TArgs9 extends any[],
  TOut10,
  TArgs10 extends any[],
  TOut11,
  TArgs11 extends any[],
  TOut12,
  TArgs12 extends any[],
  TOut13,
  TArgs13 extends any[],
  TOut14,
  TArgs14 extends any[],
  TOut15,
  TArgs15 extends any[],
  TOut16,
  TArgs16 extends any[],
  TOut17,
  TArgs17 extends any[],
  T = void
>(
  f1: FilterFunction<TOut, TArgs, TOut2, TArgs2, T>,
  f2: FilterFunction<TOut2, TArgs2, TOut3, TArgs3, T>,
  f3: FilterFunction<TOut3, TArgs3, TOut4, TArgs4, T>,
  f4: FilterFunction<TOut4, TArgs4, TOut5, TArgs5, T>,
  f5: FilterFunction<TOut5, TArgs5, TOut6, TArgs6, T>,
  f6: FilterFunction<TOut6, TArgs6, TOut7, TArgs7, T>,
  f7: FilterFunction<TOut7, TArgs7, TOut8, TArgs8, T>,
  f8: FilterFunction<TOut8, TArgs8, TOut9, TArgs9, T>,
  f9: FilterFunction<TOut9, TArgs9, TOut10, TArgs10, T>,
  f10: FilterFunction<TOut10, TArgs10, TOut11, TArgs11, T>,
  f11: FilterFunction<TOut11, TArgs11, TOut12, TArgs12, T>,
  f12: FilterFunction<TOut12, TArgs12, TOut13, TArgs13, T>,
  f13: FilterFunction<TOut13, TArgs13, TOut14, TArgs14, T>,
  f14: FilterFunction<TOut14, TArgs14, TOut15, TArgs15, T>,
  f15: FilterFunction<TOut15, TArgs15, TOut16, TArgs16, T>,
  f16: FilterFunction<TOut16, TArgs16, TOut17, TArgs17, T>,
  source: Func<TOut17, TArgs17, T>
): FilteredFunction<
  TOut,
  TArgs,
  [
    typeof f1,
    typeof f2,
    typeof f3,
    typeof f4,
    typeof f5,
    typeof f6,
    typeof f7,
    typeof f8,
    typeof f9,
    typeof f10,
    typeof f11,
    typeof f12,
    typeof f13,
    typeof f14,
    typeof f15,
    typeof f16
  ],
  typeof source,
  T
>;
/**
 * Apply a series of filter functions to a source function.
 *
 * A Filter function takes a next filter as it's first argument, it can choose
 * to call the next function or return pre-emptively optionally modifying the
 * inputs and outputs. A Filter function is one of the most general ways of composing
 * functions and is extremely useful as a core concept for things like error handling or
 * ensuring that functions run within a timeout.
 * @param source the function that produces the root result
 */
export function filter<
  TOut,
  TArgs extends any[],
  TOut2,
  TArgs2 extends any[],
  TOut3,
  TArgs3 extends any[],
  TOut4,
  TArgs4 extends any[],
  TOut5,
  TArgs5 extends any[],
  TOut6,
  TArgs6 extends any[],
  TOut7,
  TArgs7 extends any[],
  TOut8,
  TArgs8 extends any[],
  TOut9,
  TArgs9 extends any[],
  TOut10,
  TArgs10 extends any[],
  TOut11,
  TArgs11 extends any[],
  TOut12,
  TArgs12 extends any[],
  TOut13,
  TArgs13 extends any[],
  TOut14,
  TArgs14 extends any[],
  TOut15,
  TArgs15 extends any[],
  TOut16,
  TArgs16 extends any[],
  TOut17,
  TArgs17 extends any[],
  TOut18,
  TArgs18 extends any[],
  T = void
>(
  f1: FilterFunction<TOut, TArgs, TOut2, TArgs2, T>,
  f2: FilterFunction<TOut2, TArgs2, TOut3, TArgs3, T>,
  f3: FilterFunction<TOut3, TArgs3, TOut4, TArgs4, T>,
  f4: FilterFunction<TOut4, TArgs4, TOut5, TArgs5, T>,
  f5: FilterFunction<TOut5, TArgs5, TOut6, TArgs6, T>,
  f6: FilterFunction<TOut6, TArgs6, TOut7, TArgs7, T>,
  f7: FilterFunction<TOut7, TArgs7, TOut8, TArgs8, T>,
  f8: FilterFunction<TOut8, TArgs8, TOut9, TArgs9, T>,
  f9: FilterFunction<TOut9, TArgs9, TOut10, TArgs10, T>,
  f10: FilterFunction<TOut10, TArgs10, TOut11, TArgs11, T>,
  f11: FilterFunction<TOut11, TArgs11, TOut12, TArgs12, T>,
  f12: FilterFunction<TOut12, TArgs12, TOut13, TArgs13, T>,
  f13: FilterFunction<TOut13, TArgs13, TOut14, TArgs14, T>,
  f14: FilterFunction<TOut14, TArgs14, TOut15, TArgs15, T>,
  f15: FilterFunction<TOut15, TArgs15, TOut16, TArgs16, T>,
  f16: FilterFunction<TOut16, TArgs16, TOut17, TArgs17, T>,
  f17: FilterFunction<TOut17, TArgs17, TOut18, TArgs18, T>,
  source: Func<TOut18, TArgs18, T>
): FilteredFunction<
  TOut,
  TArgs,
  [
    typeof f1,
    typeof f2,
    typeof f3,
    typeof f4,
    typeof f5,
    typeof f6,
    typeof f7,
    typeof f8,
    typeof f9,
    typeof f10,
    typeof f11,
    typeof f12,
    typeof f13,
    typeof f14,
    typeof f15,
    typeof f16,
    typeof f17
  ],
  typeof source,
  T
>;
/**
 * Apply a series of filter functions to a source function.
 *
 * A Filter function takes a next filter as it's first argument, it can choose
 * to call the next function or return pre-emptively optionally modifying the
 * inputs and outputs. A Filter function is one of the most general ways of composing
 * functions and is extremely useful as a core concept for things like error handling or
 * ensuring that functions run within a timeout.
 * @param source the function that produces the root result
 */
export function filter<
  TOut,
  TArgs extends any[],
  TOut2,
  TArgs2 extends any[],
  TOut3,
  TArgs3 extends any[],
  TOut4,
  TArgs4 extends any[],
  TOut5,
  TArgs5 extends any[],
  TOut6,
  TArgs6 extends any[],
  TOut7,
  TArgs7 extends any[],
  TOut8,
  TArgs8 extends any[],
  TOut9,
  TArgs9 extends any[],
  TOut10,
  TArgs10 extends any[],
  TOut11,
  TArgs11 extends any[],
  TOut12,
  TArgs12 extends any[],
  TOut13,
  TArgs13 extends any[],
  TOut14,
  TArgs14 extends any[],
  TOut15,
  TArgs15 extends any[],
  TOut16,
  TArgs16 extends any[],
  TOut17,
  TArgs17 extends any[],
  TOut18,
  TArgs18 extends any[],
  TOut19,
  TArgs19 extends any[],
  T = void
>(
  f1: FilterFunction<TOut, TArgs, TOut2, TArgs2, T>,
  f2: FilterFunction<TOut2, TArgs2, TOut3, TArgs3, T>,
  f3: FilterFunction<TOut3, TArgs3, TOut4, TArgs4, T>,
  f4: FilterFunction<TOut4, TArgs4, TOut5, TArgs5, T>,
  f5: FilterFunction<TOut5, TArgs5, TOut6, TArgs6, T>,
  f6: FilterFunction<TOut6, TArgs6, TOut7, TArgs7, T>,
  f7: FilterFunction<TOut7, TArgs7, TOut8, TArgs8, T>,
  f8: FilterFunction<TOut8, TArgs8, TOut9, TArgs9, T>,
  f9: FilterFunction<TOut9, TArgs9, TOut10, TArgs10, T>,
  f10: FilterFunction<TOut10, TArgs10, TOut11, TArgs11, T>,
  f11: FilterFunction<TOut11, TArgs11, TOut12, TArgs12, T>,
  f12: FilterFunction<TOut12, TArgs12, TOut13, TArgs13, T>,
  f13: FilterFunction<TOut13, TArgs13, TOut14, TArgs14, T>,
  f14: FilterFunction<TOut14, TArgs14, TOut15, TArgs15, T>,
  f15: FilterFunction<TOut15, TArgs15, TOut16, TArgs16, T>,
  f16: FilterFunction<TOut16, TArgs16, TOut17, TArgs17, T>,
  f17: FilterFunction<TOut17, TArgs17, TOut18, TArgs18, T>,
  f18: FilterFunction<TOut18, TArgs18, TOut19, TArgs19, T>,
  source: Func<TOut19, TArgs19, T>
): FilteredFunction<
  TOut,
  TArgs,
  [
    typeof f1,
    typeof f2,
    typeof f3,
    typeof f4,
    typeof f5,
    typeof f6,
    typeof f7,
    typeof f8,
    typeof f9,
    typeof f10,
    typeof f11,
    typeof f12,
    typeof f13,
    typeof f14,
    typeof f15,
    typeof f16,
    typeof f17,
    typeof f18
  ],
  typeof source,
  T
>;
/**
 * Apply a series of filter functions to a source function.
 *
 * A Filter function takes a next filter as it's first argument, it can choose
 * to call the next function or return pre-emptively optionally modifying the
 * inputs and outputs. A Filter function is one of the most general ways of composing
 * functions and is extremely useful as a core concept for things like error handling or
 * ensuring that functions run within a timeout.
 * @param source the function that produces the root result
 */
export function filter<
  TOut,
  TArgs extends any[],
  TOut2,
  TArgs2 extends any[],
  TOut3,
  TArgs3 extends any[],
  TOut4,
  TArgs4 extends any[],
  TOut5,
  TArgs5 extends any[],
  TOut6,
  TArgs6 extends any[],
  TOut7,
  TArgs7 extends any[],
  TOut8,
  TArgs8 extends any[],
  TOut9,
  TArgs9 extends any[],
  TOut10,
  TArgs10 extends any[],
  TOut11,
  TArgs11 extends any[],
  TOut12,
  TArgs12 extends any[],
  TOut13,
  TArgs13 extends any[],
  TOut14,
  TArgs14 extends any[],
  TOut15,
  TArgs15 extends any[],
  TOut16,
  TArgs16 extends any[],
  TOut17,
  TArgs17 extends any[],
  TOut18,
  TArgs18 extends any[],
  TOut19,
  TArgs19 extends any[],
  TOut20,
  TArgs20 extends any[],
  T = void
>(
  f1: FilterFunction<TOut, TArgs, TOut2, TArgs2, T>,
  f2: FilterFunction<TOut2, TArgs2, TOut3, TArgs3, T>,
  f3: FilterFunction<TOut3, TArgs3, TOut4, TArgs4, T>,
  f4: FilterFunction<TOut4, TArgs4, TOut5, TArgs5, T>,
  f5: FilterFunction<TOut5, TArgs5, TOut6, TArgs6, T>,
  f6: FilterFunction<TOut6, TArgs6, TOut7, TArgs7, T>,
  f7: FilterFunction<TOut7, TArgs7, TOut8, TArgs8, T>,
  f8: FilterFunction<TOut8, TArgs8, TOut9, TArgs9, T>,
  f9: FilterFunction<TOut9, TArgs9, TOut10, TArgs10, T>,
  f10: FilterFunction<TOut10, TArgs10, TOut11, TArgs11, T>,
  f11: FilterFunction<TOut11, TArgs11, TOut12, TArgs12, T>,
  f12: FilterFunction<TOut12, TArgs12, TOut13, TArgs13, T>,
  f13: FilterFunction<TOut13, TArgs13, TOut14, TArgs14, T>,
  f14: FilterFunction<TOut14, TArgs14, TOut15, TArgs15, T>,
  f15: FilterFunction<TOut15, TArgs15, TOut16, TArgs16, T>,
  f16: FilterFunction<TOut16, TArgs16, TOut17, TArgs17, T>,
  f17: FilterFunction<TOut17, TArgs17, TOut18, TArgs18, T>,
  f18: FilterFunction<TOut18, TArgs18, TOut19, TArgs19, T>,
  f19: FilterFunction<TOut19, TArgs19, TOut20, TArgs20, T>,
  source: Func<TOut20, TArgs20, T>
): FilteredFunction<
  TOut,
  TArgs,
  [
    typeof f1,
    typeof f2,
    typeof f3,
    typeof f4,
    typeof f5,
    typeof f6,
    typeof f7,
    typeof f8,
    typeof f9,
    typeof f10,
    typeof f11,
    typeof f12,
    typeof f13,
    typeof f14,
    typeof f15,
    typeof f16,
    typeof f17,
    typeof f18,
    typeof f19
  ],
  typeof source,
  T
>;
/**
 * Apply a series of filter functions to a source function.
 *
 * A Filter function takes a next filter as it's first argument, it can choose
 * to call the next function or return pre-emptively optionally modifying the
 * inputs and outputs. A Filter function is one of the most general ways of composing
 * functions and is extremely useful as a core concept for things like error handling or
 * ensuring that functions run within a timeout.
 * @param source the function that produces the root result
 */
export function filter<TOut, TArgs extends any[], T = void>(
  f1: FilterFunction<TOut, TArgs, any, any, T>,
  ...fs: (FilterFunction<any, any, any, any, T> | Func<any, any, T>)[]
): FilteredFunction<TOut, TArgs, [typeof f1, ...typeof fs], (typeof fs)[0], T> {
  const source: Func<any, any, T> = fs.pop()!;
  const filters: FilterFunction<any, any, any, any, T>[] = [f1, ...fs];
  const impl = function (this: T, ...args: TArgs): TOut {
    return (
      filters.reduceRight(
        (s: Func<any, any>, f) => f.bind(this, s) as unknown as Func<any, any>,
        source.bind(this) as Func<any, any>
      ) as Func<any, any>
    )(...args);
  };
  Object.defineProperties(impl, {
    filters: {
      value: filters,
      writable: false,
      enumerable: true
    },
    source: {
      value: source,
      writable: false,
      enumerable: true
    }
  });
  return impl as FilteredFunction<TOut, TArgs, [typeof f1, ...typeof fs], (typeof fs)[0], T>;
}
