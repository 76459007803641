import React from 'react';
import * as colors from '../../../colors';
import { useRandomId } from '../../../hooks/use-random-id';

export const ShareIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1545:46633)">
      <path
        d="M16.25 9.50004C16.6642 9.50004 17 9.16426 17 8.75004V3.75C17 3.55109 16.921 3.36032 16.7803 3.21967C16.6397 3.07902 16.4489 3 16.25 3L11.25 3.00004C10.8358 3.00005 10.5 3.33584 10.5 3.75005C10.5 4.16426 10.8358 4.50005 11.25 4.50004L14.4394 4.50002L7.46967 11.4697C7.17678 11.7626 7.17678 12.2375 7.46967 12.5304C7.76256 12.8233 8.23744 12.8233 8.53033 12.5304L15.5 5.5607V8.75004C15.5 9.16426 15.8358 9.50004 16.25 9.50004Z"
        fill={colors.CHARCOAL_100}
      />
      <path
        d="M4.5 6.25003C4.5 6.11196 4.61193 6.00003 4.75 6.00003H7.875C8.28921 6.00003 8.625 5.66424 8.625 5.25003C8.625 4.83582 8.28921 4.50003 7.875 4.50003H4.75C3.7835 4.50003 3 5.28353 3 6.25003V15.25C3 16.2165 3.7835 17 4.75 17H13.75C14.7165 17 15.5 16.2165 15.5 15.25V12.125C15.5 11.7108 15.1642 11.375 14.75 11.375C14.3358 11.375 14 11.7108 14 12.125V15.25C14 15.3881 13.8881 15.5 13.75 15.5H4.75C4.61193 15.5 4.5 15.3881 4.5 15.25V6.25003Z"
        fill={colors.CHARCOAL_100}
      />
    </g>
    <defs>
      <clipPath id={useRandomId('clip')}>
        <rect width="14" height="14" transform="translate(3 3)" />
      </clipPath>
    </defs>
  </svg>
);
