import React from 'react';
import * as colors from '../../../colors';

export const CalendarRescheduleIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 0C4.41421 0 4.75 0.335786 4.75 0.75V1.5H13.25V0.75C13.25 0.335786 13.5858 0 14 0C14.4142 0 14.75 0.335786 14.75 0.75V1.5H15.25C16.7688 1.5 18 2.73122 18 4.25V9.89097C19.2246 10.9454 20 12.5072 20 14.25C20 17.4256 17.4256 20 14.25 20C12.0705 20 10.1742 18.7874 9.19901 17H2.75C1.23122 17 0 15.7688 0 14.25V4.25C0 2.73122 1.23122 1.5 2.75 1.5H3.25V0.75C3.25 0.335786 3.58579 0 4 0ZM13.25 3V3.75C13.25 4.16421 13.5858 4.5 14 4.5C14.4142 4.5 14.75 4.16421 14.75 3.75V3H15.25C15.9404 3 16.5 3.55964 16.5 4.25V5.75L1.5 5.75V4.25C1.5 3.55964 2.05964 3 2.75 3H3.25V3.75C3.25 4.16421 3.58579 4.5 4 4.5C4.41421 4.5 4.75 4.16421 4.75 3.75V3H13.25ZM8.63625 15.5C8.54704 15.0976 8.5 14.6793 8.5 14.25C8.5 11.0744 11.0744 8.5 14.25 8.5C15.0485 8.5 15.8089 8.66275 16.5 8.95689V7.25L1.5 7.25V14.25C1.5 14.9404 2.05964 15.5 2.75 15.5H8.63625ZM14.25 18.5C16.5972 18.5 18.5 16.5972 18.5 14.25C18.5 11.9028 16.5972 10 14.25 10C11.9028 10 10 11.9028 10 14.25C10 16.5972 11.9028 18.5 14.25 18.5ZM13.25 14.9C13.25 15.259 13.541 15.55 13.9 15.55H15.9C16.259 15.55 16.55 15.259 16.55 14.9C16.55 14.541 16.259 14.25 15.9 14.25H14.55V12.4C14.55 12.041 14.259 11.75 13.9 11.75C13.541 11.75 13.25 12.041 13.25 12.4V14.9Z"
      fill={colors.CHARCOAL_100}
    />
  </svg>
);
