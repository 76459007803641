import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LenderRepresentation, LenderReview, OULenderReviewData } from '../../modules/lenders/type';
import { LendersState } from './state';

const initialState: LendersState = {
  allLenders: {},
  loadingLenderDirectLenders: true
};

export const lenderSlice = createSlice({
  name: 'lender',
  initialState,
  reducers: {
    fetchLenderReview: (state, { payload }: PayloadAction<{ lenderId: string }>) => {
      state.allLenders = {
        ...state.allLenders,
        [payload.lenderId]: {
          lenderReview: { loadingStatus: 'pending' },
          ouLenderReviewData: { loadingStatus: 'pending' }
        }
      };
    },
    fetchLenderReviewFailed: (state, { payload }: PayloadAction<{ lenderId: string }>) => {
      state.allLenders = {
        ...state.allLenders,
        [payload.lenderId]: {
          ...state.allLenders[payload.lenderId],
          lenderReview: {
            loadingStatus: 'failed'
          }
        }
      };
    },
    fetchLenderReviewNotFound: (state, { payload }: PayloadAction<{ lenderId: string }>) => {
      state.allLenders = {
        ...state.allLenders,
        [payload.lenderId]: {
          ...state.allLenders[payload.lenderId],
          lenderReview: {
            loadingStatus: 'lenderId_not_found'
          }
        }
      };
    },
    fetchLenderReviewSucceeded: (
      state,
      { payload }: PayloadAction<{ lenderId: string; lenderReview: LenderReview }>
    ) => {
      state.allLenders = {
        ...state.allLenders,
        [payload.lenderId]: {
          ...state.allLenders[payload.lenderId],
          lenderReview: payload.lenderReview
        }
      };
    },
    fetchOULenderReviewFailed: (state, { payload }: PayloadAction<{ lenderId: string }>) => {
      state.allLenders = {
        ...state.allLenders,
        [payload.lenderId]: {
          ...state.allLenders[payload.lenderId],
          ouLenderReviewData: {
            loadingStatus: 'failed'
          }
        }
      };
    },
    fetchOULenderReviewNotFound: (state, { payload }: PayloadAction<{ lenderId: string }>) => {
      state.allLenders = {
        ...state.allLenders,
        [payload.lenderId]: {
          ...state.allLenders[payload.lenderId],
          ouLenderReviewData: {
            loadingStatus: 'lenderId_not_found'
          }
        }
      };
    },
    fetchOULenderReviewSucceeded: (
      state,
      { payload }: PayloadAction<{ lenderId: string; ouLenderReviewData: OULenderReviewData }>
    ) => {
      state.allLenders = {
        ...state.allLenders,
        [payload.lenderId]: {
          ...state.allLenders[payload.lenderId],
          ouLenderReviewData: payload.ouLenderReviewData
        }
      };
    },
    fetchLenderRepresentations: (state) => {
      state.loadingLenderDirectLenders = true;
    },
    fetchLenderRepresentationsFailed: (state) => {
      state.loadingLenderDirectLenders = false;
    },
    fetchLenderRepresentationsSucceeded: (
      state,
      { payload: { lenders } }: PayloadAction<{ lenders: LenderRepresentation[] }>
    ) => {
      state.allLenders = lenders.reduce((accum, lender) => {
        accum[lender.id] = { ...state.allLenders[lender.id], lenderRepresentation: lender };
        return accum;
      }, state.allLenders);

      state.loadingLenderDirectLenders = false;
    }
  }
});

export const {
  fetchLenderReview,
  fetchLenderReviewFailed,
  fetchLenderReviewNotFound,
  fetchLenderReviewSucceeded,
  fetchOULenderReviewFailed,
  fetchOULenderReviewNotFound,
  fetchOULenderReviewSucceeded,
  fetchLenderRepresentations,
  fetchLenderRepresentationsFailed,
  fetchLenderRepresentationsSucceeded
} = lenderSlice.actions;

export const reducer = lenderSlice.reducer;
