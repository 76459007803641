import React from 'react';
import * as colors from '../../../colors';

export const CheckIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.7724 5.21181C17.0696 5.5003 17.0767 5.97513 16.7882 6.27236L8.53819 14.7724C8.3983 14.9165 8.20644 14.9985 8.0056 15C7.80475 15.0015 7.61169 14.9224 7.46967 14.7803L3.21967 10.5303C2.92678 10.2374 2.92678 9.76256 3.21967 9.46967C3.51256 9.17678 3.98744 9.17678 4.28033 9.46967L7.99203 13.1814L15.7118 5.22764C16.0003 4.93041 16.4751 4.92333 16.7724 5.21181Z"
      fill={colors.CHARCOAL_100}
    />
  </svg>
);
