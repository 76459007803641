import { OwnUpSmallBody, SLATE_100 } from '@rategravity/own-up-component-library';
import fecha from 'fecha';
import React from 'react';
import styled from 'styled-components';

const Text = styled(OwnUpSmallBody)`
  color: ${SLATE_100};
`;

export interface RatesUpdatedTimeProps {
  updatedTime: Date;
}

export const RatesUpdatedTime = ({ updatedTime }: RatesUpdatedTimeProps) => (
  <Text variant="body1">Updated {fecha.format(updatedTime, 'MMMM D, YYYY [at] h:mm A')}</Text>
);
