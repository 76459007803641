import { MutableRefObject, useContext, useEffect } from 'react';
import { IntersectionContext } from './context';

export const useIntersectionObserver = (
  ref: MutableRefObject<HTMLElement | null>,
  callback: (intersections: IntersectionObserverEntry[]) => void
): void => {
  const observer = useContext(IntersectionContext);
  useEffect(() => {
    if (ref.current) {
      return observer.observe(ref.current, callback);
    }
  }, [callback, ref, observer]);
};
