import { currency } from '@rategravity/frontend/modules/numbers';
import {
  ALOE_100,
  MOSS_50,
  OwnUpBody,
  OwnUpFillAccordion,
  OwnUpIcon,
  OwnUpIconTooltipTriggerBodyRegular,
  OwnUpLargeBodyMedium,
  SLATE_100,
  SLATE_50,
  WHITE_100
} from '@rategravity/own-up-component-library';
import { EducationIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/education';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { displayFeeValue, resolveFeeValue } from '../../modules/fee-helpers';
import { FeeValue } from '../../redux/state';

interface Row<T> {
  name: string;
  value: T;
  description?: string;
}

const AccordionWrapper = styled.div`
  margin-bottom: 20px;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: none;
  margin-bottom: 12px;
`;

const Accordion = styled(OwnUpFillAccordion)<{ expanded: boolean; backgroundColor: string }>`
  background-color: white;
  .MuiAccordionSummary-root {
    background-color: ${({ backgroundColor }) => backgroundColor};
    border-radius: ${({ expanded }) => (expanded ? '8px 8px 0 0' : '8px')};
    ${({ expanded }) => (expanded ? undefined : 'transition: border-radius ease 1s')};
    > .MuiAccordionSummary-content > p.MuiTypography-body1 {
      width: 100%;
    }
  }
  .MuiAccordionDetails-root {
    padding: 8px 24px 24px;
    background-color: ${({ backgroundColor }) => backgroundColor};
    > p.MuiTypography-body1 {
      width: 100%;
      margin: 0;
    }
  }
`;

const AccordionHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Divider = styled.hr`
  border: 0;
  height: 1px;
  background: ${SLATE_50};
  margin: 24px 0;
`;

const LoanEstimateRow = styled.div`
  display: flex;
  margin-top: 16px;
  & span {
    margin-right: 12px;
  }
`;

const SubtitleBody = styled(OwnUpBody)`
  color: ${SLATE_100};
`;

export const FeeAccordionRow = ({
  title,
  value,
  tooltip
}: {
  title: string;
  value: string;
  tooltip?: string;
}) => (
  <RowContainer>
    {!!tooltip ? (
      <OwnUpIconTooltipTriggerBodyRegular description={tooltip}>
        <OwnUpLargeBodyMedium variant="body1">{title}</OwnUpLargeBodyMedium>
      </OwnUpIconTooltipTriggerBodyRegular>
    ) : (
      <OwnUpLargeBodyMedium variant="body1">{title}</OwnUpLargeBodyMedium>
    )}

    <OwnUpLargeBodyMedium variant="body1">{value}</OwnUpLargeBodyMedium>
  </RowContainer>
);

export const FeeAccordion = ({
  title,
  subtitle,
  loanEstimateSection,
  rows,
  defaultOpen,
  backgroundColor = WHITE_100
}: {
  title: string;
  subtitle: string;
  loanEstimateSection?: string;
  rows: Row<FeeValue>[];
  defaultOpen: boolean;
  backgroundColor?: string;
}) => {
  const [expanded, setExpanded] = useState(defaultOpen);
  const totalSum = useMemo(
    () =>
      currency(
        rows.reduce((sum, { value }) => sum + resolveFeeValue(value), 0),
        0
      ),
    [rows]
  );

  return (
    <AccordionWrapper>
      <Accordion
        expanded={expanded}
        $expandIconPosition="left"
        backgroundColor={backgroundColor}
        onChange={() => setExpanded(!expanded)}
        title={
          <AccordionHeader>
            <OwnUpLargeBodyMedium variant="body1">{title}</OwnUpLargeBodyMedium>
            {!expanded && <OwnUpLargeBodyMedium variant="body1">{totalSum}</OwnUpLargeBodyMedium>}
          </AccordionHeader>
        }
      >
        <SubtitleBody variant="body2">{subtitle}</SubtitleBody>
        {!!loanEstimateSection && (
          <LoanEstimateRow>
            <OwnUpIcon icon={EducationIcon} color={ALOE_100} />
            <OwnUpBody variant="body2">{loanEstimateSection}</OwnUpBody>
          </LoanEstimateRow>
        )}
        <Divider />
        {rows.map(({ name, value, description }, i) => (
          <RowContainer key={i}>
            {!!description ? (
              <OwnUpIconTooltipTriggerBodyRegular description={description}>
                {name}
              </OwnUpIconTooltipTriggerBodyRegular>
            ) : (
              <OwnUpBody variant="body1">{name}</OwnUpBody>
            )}
            <OwnUpBody variant="body1">{displayFeeValue(value)}</OwnUpBody>
          </RowContainer>
        ))}
        <Divider />
        <FeeAccordionRow title="Total" value={totalSum} />
      </Accordion>
    </AccordionWrapper>
  );
};

const TableHeader = styled(AccordionHeader)`
  padding: 0;
`;

const TableWrapper = styled(AccordionWrapper)`
  padding: 24px;
  background-color: ${MOSS_50};
`;

export const FeeTable = ({ title, rows }: { title: string; rows: Row<string>[] }) => (
  <TableWrapper>
    <TableHeader>
      <OwnUpLargeBodyMedium variant="body1">{title}</OwnUpLargeBodyMedium>
    </TableHeader>
    <Divider />
    {rows.map(({ name, value, description }, i) => (
      <RowContainer key={i}>
        {!!description ? (
          <OwnUpIconTooltipTriggerBodyRegular description={description}>
            {name}
          </OwnUpIconTooltipTriggerBodyRegular>
        ) : (
          <OwnUpBody variant="body1">{name}</OwnUpBody>
        )}
        <OwnUpBody variant="body1">{value}</OwnUpBody>
      </RowContainer>
    ))}
  </TableWrapper>
);
