import React from 'react';
import * as colors from '../../../colors';

export const EmailIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 5.5C1.5 4.39543 2.39543 3.5 3.5 3.5H16.5C17.6046 3.5 18.5 4.39543 18.5 5.5V14.5C18.5 15.6046 17.6046 16.5 16.5 16.5H3.5C2.39543 16.5 1.5 15.6046 1.5 14.5V5.5ZM16.4008 5H3.59919L9.82942 10.8149C9.92547 10.9045 10.0745 10.9045 10.1706 10.8149L16.4008 5ZM3 6.49258V14.5C3 14.7761 3.22386 15 3.5 15H16.5C16.7761 15 17 14.7761 17 14.5V6.49258L11.1941 11.9115C10.5217 12.539 9.47831 12.539 8.80594 11.9115L3 6.49258Z"
      fill={colors.CHARCOAL_100}
    />
  </svg>
);
