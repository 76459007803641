import { event } from '@rategravity/frontend/modules/user-actions';
import { OwnUpThemeProvider, ownUpWhiteTheme } from '@rategravity/own-up-component-library';
import { useAdvisors } from '@rategravity/own-up-component-library-legacy';
import { push, replace } from 'connected-react-router';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useActionPipe } from '../../../hooks/use-action-pipe';
import { findLicensedAdvisors } from '../../../modules/find-licensed-advisors';
import { consultStateSelector } from '../../../redux/selector/consult-state-selector';
import {
  lenderDataSelector,
  lockEventPropertiesSelector,
  offerSelector
} from '../../redux/selector';
import { OfferDetailsPage } from '.';

export const OfferDetailsConnect = (props: { rootPath: string; id: string; offerId: string }) => {
  const validOffer = useSelector(offerSelector);
  const { advisor, scenario } = useSelector(lockEventPropertiesSelector);
  const lenderData = useSelector(lenderDataSelector);
  // only show comparison shopping if offer has a fixed rate
  const showComparisonShopping = validOffer
    .map(({ loanType }) => loanType.includes('fixed'))
    .orElse(() => false);
  const allAdvisors = useAdvisors();
  const state = useSelector(consultStateSelector);
  const licensedAdvisors = findLicensedAdvisors(allAdvisors, state);

  const dispatch = useDispatch();
  const dispatchAction = useActionPipe();
  const onClose = () => dispatch(replace(props.rootPath));
  const lockOfferFromDetailsEvent = event('Request Lock', {
    advisor,
    scenario,
    url: window.location.href
  });
  const onLockRequest = (offerId: string) => {
    dispatchAction(lockOfferFromDetailsEvent);
    return dispatch(
      push({
        pathname: `${props.rootPath}/lock/${offerId}`,
        state: {
          fromId: props.id
        }
      })
    );
  };
  const onCompareOffer = () => {
    dispatchAction(event('clickedCompare-offerDetails'));
    dispatch(
      push({
        pathname: `${props.rootPath}/compare/${props.offerId}`,
        state: {
          fromId: props.id
        }
      })
    );
  };
  if (validOffer) {
    return (
      <OwnUpThemeProvider theme={ownUpWhiteTheme}>
        <OfferDetailsPage
          onClose={onClose}
          onLockRequest={onLockRequest}
          lenderData={lenderData}
          onCompareOffer={onCompareOffer}
          showComparisonShopping={showComparisonShopping}
          licensedAdvisors={licensedAdvisors}
        />
      </OwnUpThemeProvider>
    );
  }

  return (
    <Redirect
      to={{
        pathname: props.rootPath
      }}
    />
  );
};
