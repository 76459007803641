import {
  OwnUpFillButtonPrimary,
  OwnUpFillButtonPrimaryAccent,
  OwnUpTooltip
} from '@rategravity/own-up-component-library';
import { LockIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/lock';
import React from 'react';
import { LockStatus } from '../../modules/get-lock-status';

export interface LockStatusProps {
  lockStatus: LockStatus;
  onLockRequest: () => void;
}

export const LockComponent = ({ lockStatus, onLockRequest }: LockStatusProps) => {
  switch (lockStatus) {
    case 'PENDING':
      return (
        <OwnUpFillButtonPrimaryAccent
          iconPosition="left"
          icon={LockIcon}
          disabled
          style={{ opacity: '.4' }}
        >
          Requested lock
        </OwnUpFillButtonPrimaryAccent>
      );
    case 'AVAILABLE':
      return (
        <OwnUpFillButtonPrimary iconPosition="left" icon={LockIcon} onClick={onLockRequest}>
          Request to lock
        </OwnUpFillButtonPrimary>
      );
    default:
      return (
        <OwnUpTooltip
          description={
            lockStatus === 'EXPIRED'
              ? 'Rates may be expired. Request a refresh or contact your advisor.'
              : "Unable to lock. You've either requested to lock another rate or already locked one."
          }
        >
          <span>
            <OwnUpFillButtonPrimary
              iconPosition="left"
              icon={LockIcon}
              disabled
              style={{ opacity: '.4' }}
            >
              Request to lock
            </OwnUpFillButtonPrimary>
          </span>
        </OwnUpTooltip>
      );
  }
};
