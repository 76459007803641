import { OwnUpComponentLibraryProvider } from '@rategravity/own-up-component-library';
import { SplitFactory } from '@splitsoftware/splitio-react';
import React, { useMemo } from 'react';
import { Route } from 'react-router';
import { trackingInstance } from '../modules/1pt-tracking-instance';
import { Loading } from './components/loading-page/wrapper';
import { CompareConnect } from './pages/compare/wrapper';
import { LockOfferConnect } from './pages/lock-offer/wrapper';
import { OfferDetailsConnect } from './pages/offer-details/wrapper';
import { RatesPageRedux } from './pages/rates/wrapper';
const splitUrl = process.env.SPLIT_URL || 'https://staging.split.ownup.com';

export const RatesAppWrapper = ({ rootPath, id }: { rootPath: string; id: string }) => {
  const splitConfig = useMemo(
    () => ({
      core: {
        authorizationKey: process.env.SPLIT_API_KEY || 'qdjduua8u5orl12gguii27vam9g73rccr5lf',
        key: trackingInstance.getAnonymousId() || '',
        trafficType: 'Anonymous'
      },
      urls: {
        sdk: `${splitUrl}/sdk-api`,
        events: `${splitUrl}/events-api`,
        auth: `${splitUrl}/auth-api`
      }
    }),
    []
  );
  return (
    <SplitFactory config={splitConfig}>
      <OwnUpComponentLibraryProvider>
        <RatesApp rootPath={rootPath} id={id} />
      </OwnUpComponentLibraryProvider>
    </SplitFactory>
  );
};

export const RatesApp = ({
  rootPath,
  id,
  useDefaultHeader
}: {
  rootPath: string;
  id: string;
  useDefaultHeader?: boolean;
}) => (
  <Loading id={id}>
    <div>
      <RatesPageRedux rootPath={rootPath} id={id} useDefaultHeader={useDefaultHeader} />
      <Route
        path={`${rootPath}/details/:offerId`}
        render={({
          match: {
            params: { offerId }
          }
        }) => <OfferDetailsConnect rootPath={rootPath} id={id} offerId={offerId} />}
      />
      <Route
        path={`${rootPath}/lock/:offerId`}
        render={({
          match: {
            params: { offerId }
          }
        }) => <LockOfferConnect rootPath={rootPath} id={id} offerId={offerId} />}
      />
      <Route
        path={`${rootPath}/compare/:offerId`}
        render={() => <CompareConnect rootPath={rootPath} />}
      />
    </div>
  </Loading>
);
