import {
  FOG_10,
  FOG_20,
  MYSTIC_40,
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridWrapper,
  ownUpMidtoneTheme,
  OwnUpThemeProvider
} from '@rategravity/own-up-component-library';
import React, { PropsWithChildren, useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';
import styled, { keyframes } from 'styled-components';
import { Page } from '../../../components/page';
import { useScrollTo } from '../../../components/scrollable-wrapper';
import { colors } from '../../../modules/colors';
import { Disclaimer } from '../../components/disclaimer';
import { FTUBannerView } from '../../components/ftu-onboarding/wrapper';
import { RateQuoteHeader } from '../../components/rate-quote-header';

const breakpoint = 'max-width: 720px';

const BackgroundColorContainer = styled.div<{
  backgroundColor: string;
  mobileBackgroundColor?: string;
}>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  @media (${breakpoint}) {
    background-color: ${({ mobileBackgroundColor }) => mobileBackgroundColor};
  }
`;

const RateGrid = ({
  backgroundColor,
  mobileBackgroundColor,
  children
}: PropsWithChildren<{ backgroundColor: string; mobileBackgroundColor?: string }>) => (
  <BackgroundColorContainer
    backgroundColor={backgroundColor}
    mobileBackgroundColor={mobileBackgroundColor}
  >
    <OwnUpGridWrapper>
      <OwnUpGridContainer variant="slim">
        <OwnUpGridItem xs={12}>{children}</OwnUpGridItem>
      </OwnUpGridContainer>
    </OwnUpGridWrapper>
  </BackgroundColorContainer>
);

const showPage = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const ContentContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  animation-name: ${showPage};
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  background-color: ${colors.WHITE};
  @media (${breakpoint}) {
    justify-content: flex-start;
  }
`;

const FTU = () => (
  <OwnUpThemeProvider theme={ownUpMidtoneTheme}>
    <RateGrid backgroundColor={MYSTIC_40}>
      <FTUBannerView />
    </RateGrid>
  </OwnUpThemeProvider>
);

interface RatePageProps {
  header: JSX.Element;
  scenario: {
    title: string;
    value: string;
  }[];
  updatedTime: JSX.Element | null;
  note: string;
  rateList: JSX.Element;
  shareableLink: JSX.Element | null;
  customDisclaimers: string[];
  accountId: string;
}

export const RatePage = ({
  header,
  scenario,
  updatedTime,
  note,
  rateList,
  shareableLink,
  customDisclaimers,
  accountId
}: RatePageProps) => {
  const scrollTo = useScrollTo();
  useLayoutEffect(() => {
    scrollTo.scrollToTop();
  }, [scrollTo]);

  return (
    <Page header={header}>
      <Helmet title="Your Loan Options" />
      <ContentContainer>
        <OwnUpThemeProvider>
          <>
            <RateQuoteHeader
              note={note}
              scenario={scenario}
              updatedTime={updatedTime}
              shareableLink={shareableLink}
              accountId={accountId}
            />
            <FTU />
          </>

          <BackgroundColorContainer backgroundColor={colors.WHITE} mobileBackgroundColor={FOG_10}>
            {rateList}
          </BackgroundColorContainer>
          <RateGrid backgroundColor={FOG_20}>
            <Disclaimer customDisclaimers={customDisclaimers} />
          </RateGrid>
        </OwnUpThemeProvider>
      </ContentContainer>
    </Page>
  );
};
