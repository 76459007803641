import { useCallback, useMemo } from 'react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadAction } from '../../redux/loading';
import { ApplicationState } from '../../redux/state';
import { LoadingComponent, LoadingComponentProps } from '.';

export const Loading = ({
  id,
  ...props
}: Omit<LoadingComponentProps, 'loading' | 'error' | 'load'>) => {
  const dispatch = useDispatch();
  const allRates = useSelector((state: ApplicationState) => state.rates).allRates;
  const additionalProps = useMemo(() => {
    if (!allRates[id] || allRates[id].loading) {
      return {
        loading: true,
        error: false
      };
    }
    return {
      loading: false,
      error: allRates[id].error
    };
  }, [allRates, id]);
  const load = useCallback(() => dispatch(loadAction(id)), [id, dispatch]);
  return <LoadingComponent id={id} {...props} {...additionalProps} load={load} />;
};
