import { currency, rate as formatRate } from '@rategravity/frontend/modules/numbers';
import {
  createOwnUpComponent,
  createOwnUpStyle,
  fonts,
  OwnUpButton,
  OwnUpIcon,
  OwnUpStatus,
  OwnUpText
} from '@rategravity/own-up-component-library-legacy';
import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../modules/colors';
import { isNonZeroish } from '../../modules/non-zeroish';

export interface MobileRateCardProps {
  rate: number;
  apr: number;
  lenderName: string;
  squareLogo: string;
  points: number;
  lenderCredit: number;
  pAndI: number;
  lenderFees: number;
  fixedLenderFees: number;
  showInterestOnly: boolean;
  showLockRequested: boolean;
  showLockButton: boolean;
  onLock: () => void;
  onCompare: () => void;
  onViewCostBreakdown: () => void;
  showCompareButton: boolean;
}

const RateCard = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background-color: ${colors.WHITE};
  box-shadow:
    -4px -4px 10px rgba(0, 0, 0, 0.03),
    6px 6px 20px rgba(0, 0, 0, 0.05);
  padding-top: 16px;
  margin-top: ${({ theme }) => theme.spacing(3)}px;
`;

const Lender = styled.div`
  display: flex;
  margin: 0 0 12px 24px;
`;

const Text = createOwnUpComponent(
  OwnUpText,
  createOwnUpStyle({
    fontSize: '14px',
    variants: {
      rate: {
        fontSize: '20px',
        ...fonts.GRAPHIK_MEDIUM
      },
      apr: {
        fontSize: '20px'
      },
      lender: {
        fontSize: '12px'
      },
      requested: {
        fontSize: '12px',
        ...fonts.GRAPHIK_MEDIUM
      }
    }
  })
);

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 6px 24px;
`;

const Divider = styled.hr`
  border: 0;
  height: 1px;
  background: ${colors.GREY};
  margin: 6px 24px;
`;

const LockButton = styled(OwnUpButton)`
  margin: 12px 24px 0;
  border-radius: 8px;
  border: none;
  background-color: ${colors.FLORAL_WHITE};
  text-transform: none;
`;

const ButtonContainerLayout = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin: 16px 20px 24px;
`;

const CompareButton = styled(OwnUpButton).attrs({ variant: 'square' })`
  border-radius: 8px;
  background-color: transparent;
  color: ${colors.CHARCOAL};
  border: 2px solid ${colors.MINT};
  margin: 0 4px;
  width: fill-available;
  line-height: 24px;
  padding: 0;
`;

const DetailsButton = styled(CompareButton)`
  background-color: ${colors.MINT};
`;

const Bottom = styled.div`
  background-color: ${colors.SAGE};
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  border-radius: 0 0 16px 16px;
  margin-top: 16px;
`;

const RateCardRow = ({ title, value }: { title: string; value: React.ReactNode }) => (
  <Row>
    <Text variant="alt">{title}</Text>
    {typeof value === 'string' ? <Text>{value}</Text> : value}
  </Row>
);

export const MobileRateCard = ({
  rate,
  apr,
  lenderName,
  squareLogo,
  lenderCredit,
  points,
  pAndI,
  lenderFees,
  fixedLenderFees,
  showInterestOnly,
  showLockRequested,
  showLockButton,
  onLock,
  onCompare,
  onViewCostBreakdown,
  showCompareButton
}: MobileRateCardProps) => (
  <RateCard onClick={onViewCostBreakdown}>
    {showLockRequested && (
      <OwnUpStatus
        icon="Unlock"
        variant="warn"
        style={{ margin: '0 0 16px 24px', width: 'fit-content' }}
      >
        <Text variant="requested">Requested lock</Text>
      </OwnUpStatus>
    )}
    <Lender>
      {!!squareLogo && (
        <img
          src={squareLogo}
          alt={`${lenderName} logo`}
          width="15px"
          style={{ marginRight: '8px' }}
        />
      )}
      <Text variant="lender">{lenderName}</Text>
    </Lender>
    <RateCardRow
      title="Rate / APR"
      value={
        <Text variant="apr">
          <Text variant="rate">{formatRate(rate, 3)}</Text> / {formatRate(apr, 3)}
        </Text>
      }
    />
    <Divider />
    <RateCardRow
      title={`Mo. ${showInterestOnly ? 'Interest' : 'Principal & Interest'}`}
      value={currency(pAndI, 0)}
    />
    {isNonZeroish(lenderCredit) && (
      <RateCardRow title="Lender credits" value={currency(lenderCredit, 0)} />
    )}
    {isNonZeroish(points) && <RateCardRow title="Points" value={currency(points, 0)} />}
    <RateCardRow title="Fixed lender fees" value={currency(fixedLenderFees, 0)} />
    <Divider />
    <RateCardRow title="Net/Total origination costs" value={currency(lenderFees, 0)} />
    {showCompareButton ? (
      <ButtonContainerLayout>
        <CompareButton
          onClick={(e) => {
            e.stopPropagation();
            onCompare();
          }}
        >
          Compare
        </CompareButton>
        <DetailsButton>View details</DetailsButton>
      </ButtonContainerLayout>
    ) : (
      <React.Fragment>
        {showLockButton && (
          <LockButton
            variant="square"
            onClick={(e) => {
              e.stopPropagation();
              onLock();
            }}
          >
            <OwnUpIcon icon="Unlock" style={{ marginRight: '12px' }} />
            <Text variant="bold">Lock this rate</Text>
          </LockButton>
        )}
        <Bottom>
          <Text variant="bold">View offer details</Text>
          <OwnUpIcon icon="Hamburger" />
        </Bottom>
      </React.Fragment>
    )}
  </RateCard>
);
