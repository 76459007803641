import { Optional } from '@rategravity/core-lib/optional';
import React, { useLayoutEffect } from 'react';
import styled from 'styled-components';
import { useIsMobile } from '../../../hooks/is-mobile';
import { AnalysisProps, AnalysisSection } from './analysis';
import { OfferHeader } from './header';

const Container = styled.div`
  ${({ theme }) => theme.breakpoints.up('md')} {
    overflow: hidden;
    height: 100%;
  }
`;

const AnalysisContentContainer = styled.div`
  padding: 0 40px;
  margin: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  ${({ theme }) => theme.breakpoints.only('md')} {
    padding: 0 40px 0 32px;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    background-color: white;
    padding: 32px 40px 0;
  }
  ${({ theme }) => theme.breakpoints.only('xs')} {
    padding: 32px 24px 0;
  }
`;

export interface OfferAnalysisProps {
  cta: JSX.Element;
  analysis: Optional<AnalysisProps>;
  isScrollingToAnalysis: boolean;
  setScrollingToAnalysis: React.Dispatch<React.SetStateAction<boolean>>;
  tab: number;
  event: (_: string) => void;
}

export const OfferAnalysis = ({
  cta,
  analysis,
  isScrollingToAnalysis,
  setScrollingToAnalysis,
  tab,
  event
}: OfferAnalysisProps) => {
  const scrollableDiv = document.getElementsByClassName('modal-body')[0];
  const breakEvenDiv = document.getElementById('break-even');
  const isMobile = useIsMobile(767);
  useLayoutEffect(() => {
    if (!!scrollableDiv && isScrollingToAnalysis) {
      const top = isMobile && !!breakEvenDiv ? breakEvenDiv.offsetTop - 32 : 0;
      setTimeout(() => scrollableDiv.scrollTo({ top, behavior: 'smooth' }), 200);
      setScrollingToAnalysis(false);
    }
  }, [scrollableDiv, isScrollingToAnalysis, breakEvenDiv, setScrollingToAnalysis, isMobile]);
  return (
    <Container>
      <AnalysisContentContainer>
        <OfferHeader showOnDesktop />
        <div id="break-even">
          {tab === 0 &&
            analysis.map<React.ReactNode>((props) => (
              <AnalysisSection key="analysis" {...props} event={event} />
            ))}
        </div>
      </AnalysisContentContainer>
      {cta}
    </Container>
  );
};
