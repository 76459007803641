import * as t from 'type-shift';
import { StandardizedResponse } from '../authentication';

export type LoadResponse =
  | {
      status: 'Pending';
    }
  | {
      status: 'Ready';
      rates: {};
      shareId?: string;
    };

export const loadResponseConverter = t
  .shape({ status: t.oneOf(['Pending', 'Ready']).default(() => 'Ready') })
  .pipe(
    t.taggedUnion<LoadResponse>('status', {
      /* eslint-disable @typescript-eslint/naming-convention */
      Pending: t.strict({
        status: t.literal('Pending')
      }),
      Ready: t.strict({
        status: t.literal('Ready'),
        rates: t.shape({}),
        shareId: t.optional(t.string)
      })
      /* eslint-enable @typescript-eslint/naming-convention */
    })
  );

export type StandardizedPublishedRatesResponse = StandardizedResponse<LoadResponse>;

export const requestLockConverter = t.shape({});
