import {
  ALOE_100,
  MOSS_100,
  OwnUpExtraSmallHeadlineMedium,
  OwnUpSmallBody
} from '@rategravity/own-up-component-library';
import React, { Dispatch, SetStateAction, useLayoutEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { AvailableAdvisors } from '../../../components/available-advisors/available-advisors';
import { YcbmButton } from '../../../components/schedule-call-button';
import { useIsMobile } from '../../../hooks/is-mobile';
import { outsideOffersSelector } from '../../../redux/outside-offers/selectors';
import {
  OfferDetailsSending,
  OfferDetailsSent
} from '../../components/comparison-shopping/outside-offer-sending';

interface OfferComparisonCTAProps {
  ycbmLink: string;
  event: (_: string) => void;
}

export interface OutsideOfferCTAProps extends OfferComparisonCTAProps {
  licensedAdvisors: string[];
  setScrollingToCta: Dispatch<SetStateAction<boolean>>;
  isScrollingToCta: boolean;
}

const CtaContainer = styled.div`
  background-color: ${MOSS_100};
  padding: 40px;
  display: flex;
  flex-direction: column;
  height: 100%;
  > button {
    width: fit-content;
  }
  ${({ theme }) => theme.breakpoints.only('md')} {
    padding-top: 32px;
    padding-left: 32px;
  }
  ${({ theme }) => theme.breakpoints.only('sm')} {
    padding-top: 32px;
  }
  ${({ theme }) => theme.breakpoints.only('xs')} {
    padding: 32px 24px 40px;
    > button {
      width: unset;
    }
  }
`;

const Text = styled(OwnUpSmallBody)`
  margin: 16px 0 32px;
  ${({ theme }) => theme.breakpoints.up('lg')} {
    margin: 16px 0 40px;
  }
`;

export const OfferComparisonCTA = ({ ycbmLink, event }: OfferComparisonCTAProps) => (
  <CtaContainer>
    <OwnUpExtraSmallHeadlineMedium variant="h1">
      Get support, not sales
    </OwnUpExtraSmallHeadlineMedium>
    <Text variant="body1">
      We hate pushy sales calls, too. Get your questions answered so you confidently understand your
      best options, whether we offer them or not.
    </Text>
    <YcbmButton
      beforeNavigation={() => event('scheduleCall-comparisonShopping')}
      ycbmLink={ycbmLink}
    />
  </CtaContainer>
);

const Bullets = styled.ul`
  padding-left: 16px;
  margin-bottom: 32px;
  > li {
    margin-top: 8px;
    padding-left: 8px;
    ::marker {
      color: ${ALOE_100};
    }
  }
`;

export const OutsideOfferCTA = ({
  licensedAdvisors,
  ycbmLink,
  event,
  isScrollingToCta,
  setScrollingToCta
}: OutsideOfferCTAProps) => {
  const { pending, offerSubmitted } = useSelector(outsideOffersSelector);
  const isMobile = useIsMobile(767);
  const SendingComponent = useMemo(() => {
    if (offerSubmitted === true && !pending) {
      return (
        <OfferDetailsSent
          ycbmLink={ycbmLink}
          beforeNavigation={() => event('outsideOffer-scheduleCall-comparisonShopping')}
        />
      );
    } else if (pending) {
      return <OfferDetailsSending />;
    } else {
      return null;
    }
  }, [event, pending, ycbmLink, offerSubmitted]);
  const scrollableDiv = document.getElementsByClassName('modal-body')[0];
  const ooCtaDiv = document.getElementById('outside-offer-cta');
  useLayoutEffect(() => {
    if (!!scrollableDiv && isScrollingToCta) {
      const top = isMobile ? ooCtaDiv?.offsetTop : 0;
      scrollableDiv.scrollTo({ top, behavior: 'smooth' });
      setScrollingToCta(false);
    }
  }, [scrollableDiv, isScrollingToCta, ooCtaDiv, setScrollingToCta, isMobile]);

  return (
    <CtaContainer id="outside-offer-cta">
      {SendingComponent}
      <OwnUpExtraSmallHeadlineMedium variant="h5">Our promise</OwnUpExtraSmallHeadlineMedium>
      <OwnUpSmallBody variant="body1" style={{ marginTop: '16px' }}>
        Your Home Advisor works with you to find the right offer for your needs and lock the best
        rate possible.
      </OwnUpSmallBody>
      <Bullets>
        <li>
          <OwnUpSmallBody variant="body1">No hard sales tactics</OwnUpSmallBody>
        </li>
        <li>
          <OwnUpSmallBody variant="body1">No spam</OwnUpSmallBody>
        </li>
        <li>
          <OwnUpSmallBody variant="body1">Unbiased advice</OwnUpSmallBody>
        </li>
      </Bullets>
      <AvailableAdvisors advisors={licensedAdvisors} columnBreakpoint="md" />
    </CtaContainer>
  );
};
