import { loggedInFetch, standardizeResponse } from '../authentication';
import { loadResponseConverter, requestLockConverter } from './type';

export const fetchPublishedRates = async ({
  accountId,
  rateQuoteRequestId
}: {
  accountId: string;
  rateQuoteRequestId: string;
}) =>
  standardizeResponse(
    await loggedInFetch(`/api/account/${accountId}/rates/${rateQuoteRequestId}`, {
      method: 'GET'
    }),
    loadResponseConverter
  );

export const fetchRequestLockOffer = async ({
  rateQuoteRequestId,
  accountId,
  offerId
}: {
  rateQuoteRequestId: string;
  accountId: string;
  offerId: string;
}) =>
  standardizeResponse(
    await loggedInFetch(`/api/account/${accountId}/rates/${rateQuoteRequestId}/lock`, {
      method: 'POST',
      body: JSON.stringify({ offerId })
    }),
    requestLockConverter
  );
