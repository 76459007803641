import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SerializableData } from './api';
import { AccountAttributes, State } from './state';

export const accountAttributesSlice = createSlice({
  name: 'accountAttributes',
  initialState: { data: null, loading: false, error: '' } as State,
  reducers: {
    fetchAccountAttributeSuccess: (
      s,
      { payload: { data } }: PayloadAction<{ data: Record<string, SerializableData> }>
    ) => ({
      ...s,
      data: {
        ...(s.data || {}),
        ...data
      } as AccountAttributes,
      loading: false,
      error: ''
    }),
    fetchAccountAttributeFailure: (
      s,
      { payload: { error } }: PayloadAction<{ error: string }>
    ) => ({
      ...s,
      loading: false,
      error
    }),
    fetchAccountAttributes: (s) => ({
      ...s,
      loading: true
    }),
    updateAccountAttributes: (_, __: PayloadAction<{ data: Record<string, SerializableData> }>) => {
      /* no-op */
    }
  }
});

export const {
  fetchAccountAttributeSuccess,
  fetchAccountAttributeFailure,
  fetchAccountAttributes,
  updateAccountAttributes
} = accountAttributesSlice.actions;

export const reducer = accountAttributesSlice.reducer;
