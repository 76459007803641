import React, { useState } from 'react';
import styled from 'styled-components';
import { RateListPermutationProps } from '../rate-list/permutation';
import { Header } from './header';

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

export type AccordionFields = RateListPermutationProps['attributes'];

interface AccordionProps {
  name: string;
  fields?: AccordionFields;
  subtitle?: string;
  children: JSX.Element | JSX.Element[];
  arrowDisplay?: string;
  className?: string;
  onClose?: () => void;
}

const ContentContainer = styled.div<{ isOpen: boolean }>`
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  height: ${({ isOpen }) => (isOpen ? undefined : '0px')};
`;

export const Accordion = ({
  name,
  fields,
  subtitle,
  children,
  arrowDisplay,
  className,
  onClose = () => {
    /* no op */
  }
}: AccordionProps) => {
  const [isOpen, setOpen] = useState(true);

  return (
    <div className={className}>
      <Header
        isOpen={isOpen}
        setOpen={(a) => {
          isOpen && onClose();
          setOpen(a);
        }}
        subtitle={subtitle}
        name={name}
        fields={fields}
        arrowDisplay={arrowDisplay}
      />
      <ContentContainer isOpen={isOpen}>{children}</ContentContainer>
    </div>
  );
};
