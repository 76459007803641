import { currency, rate as formatRate } from '@rategravity/frontend/modules/numbers';
import {
  MOSS_50,
  OwnUpBody,
  OwnUpLargeBodyMedium,
  OwnUpSmallBody,
  SLATE_100,
  SLATE_50
} from '@rategravity/own-up-component-library';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { resolveFeeValue } from '../../modules/fee-helpers';
import { FeeValue } from '../../redux/state';
import { FeeAccordion, FeeAccordionRow, FeeTable } from './fee-accordion';

interface FeeFields {
  name: string;
  value: FeeValue;
  description: string;
}
interface ValueWithDescription {
  value: number;
  description: string;
}

export interface FeeBreakdownProps {
  condoFee: number | null;
  downPayment: number | null;
  realEstateTax: ValueWithDescription;
  homeInsurance: ValueWithDescription;
  pAndI: ValueWithDescription;
  monthlyMI: FeeFields;
  interestOnly: boolean;
  initialCap: ValueWithDescription;
  periodicCap: ValueWithDescription;
  lifetimeCap: ValueWithDescription;
  lenderFees: FeeFields[];
  loanServiceFees: FeeFields[];
  titleFees: FeeFields[];
  govFees: FeeFields[];
  prepaidFees: FeeFields[];
  closingFinanced: number;
  hasEstimates: boolean;
  hasOptionals: boolean;
  sellerConcessions: number;
  earnestMoneyDeposit: number;
}

const Disclaimers = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
`;

const CashToCloseCard = styled.div`
  padding: 18px 24px 24px;
  background-color: ${MOSS_50};
`;

const SubtitleBody = styled(OwnUpBody)`
  color: ${SLATE_100};
`;

const Divider = styled.hr`
  border: 0;
  height: 1px;
  background: ${SLATE_50};
  margin: 24px 0;
`;

const sumFeeGroup = (fees: FeeFields[]): number => {
  return fees.map(({ value }) => resolveFeeValue(value)).reduce((l, r) => l + r, 0);
};

const sellerConcessionTooltip =
  'Seller concessions can not exceed total fees (Lender Fees, Loan Services Fees, Title Services Fees, State & Local Fees, and Prepaids & Escrows). We have adjusted your seller concessions to cover only the fees for this loan product.';

export const FeeBreakdown = ({
  condoFee,
  downPayment,
  realEstateTax,
  homeInsurance,
  pAndI,
  monthlyMI,
  interestOnly,
  initialCap,
  lifetimeCap,
  periodicCap,
  lenderFees = [],
  loanServiceFees = [],
  titleFees = [],
  govFees = [],
  prepaidFees = [],
  hasEstimates,
  hasOptionals,
  closingFinanced,
  sellerConcessions,
  earnestMoneyDeposit
}: FeeBreakdownProps) => {
  const totalFees = useMemo(
    () =>
      sumFeeGroup(lenderFees) +
      sumFeeGroup(loanServiceFees) +
      sumFeeGroup(titleFees) +
      sumFeeGroup(govFees) +
      sumFeeGroup(prepaidFees),
    [lenderFees, loanServiceFees, titleFees, govFees, prepaidFees]
  );
  // only apply seller concessions toward fees
  const concessions = sellerConcessions > totalFees ? totalFees : sellerConcessions;
  return (
    <div>
      {(!!initialCap.value || !!periodicCap.value || !!lifetimeCap.value) && (
        <FeeTable
          title="ARM details"
          rows={[
            {
              name: 'Initial Cap',
              value: formatRate(initialCap.value, 0),
              description: initialCap.description
            },
            {
              name: 'Periodic Cap',
              value: formatRate(periodicCap.value, 0),
              description: periodicCap.description
            },
            {
              name: 'Lifetime Cap',
              value: formatRate(lifetimeCap.value, 0),
              description: lifetimeCap.description
            }
          ]}
        />
      )}

      <FeeAccordion
        title="Monthly payment"
        subtitle="All the costs involved in your monthly payment"
        rows={[
          { name: `Mo. ${interestOnly ? 'Interest' : 'Principal & Interest'}`, ...pAndI },
          { name: 'Real Estate Tax', ...realEstateTax },
          { name: `Home Insurance${downPayment ? '**' : ''}`, ...homeInsurance },
          { name: 'Condo/HOA Fee', value: condoFee || 0 },
          { ...monthlyMI, name: `${monthlyMI.name}*` }
        ].filter(({ value }) => value !== 0)}
        defaultOpen={true}
        backgroundColor={MOSS_50}
      />

      <CashToCloseCard>
        <OwnUpLargeBodyMedium variant="body1" style={{ marginBottom: '26px' }}>
          Cash to close
        </OwnUpLargeBodyMedium>
        <SubtitleBody variant="body1">
          An estimate of how much money you&apos;ll need to pay at closing.
        </SubtitleBody>

        <Divider />
        <FeeAccordion
          title="Lender fees"
          subtitle="These are the most important fees to compare between lenders since all other fees in an offer are standard fees that will be the same at closing."
          loanEstimateSection="Section A + Lender Credits on your Loan Estimate"
          rows={lenderFees}
          defaultOpen={true}
        />
        <SubtitleBody variant="body1" style={{ margin: '24px 0' }}>
          All costs below may be estimated differently between lenders, but are standard fees that
          will cost the same at closing no matter which offer you choose.
        </SubtitleBody>
        <FeeAccordion
          title="Loan services"
          subtitle="These are fees that the lender passes on from third parties, but does not profit from. Some of these fees may depend on your loan type (FHA, VA, or USDA)."
          loanEstimateSection="Section B on your Loan Estimate"
          rows={loanServiceFees}
          defaultOpen={false}
        />
        <FeeAccordion
          title="Title services"
          subtitle="These fees are charged by a Title company to ensure there are no defects in the deed or title. You can shop for different title providers, so these costs are not specific to the lender."
          loanEstimateSection="Section C + H on your Loan Estimate"
          rows={titleFees}
          defaultOpen={false}
        />
        <FeeAccordion
          title="State & local fees"
          subtitle="These fees are charged by your state and local government. Lenders may use different estimates, but these costs will be the same across lenders when you finalize your loan."
          loanEstimateSection="Section E on your Loan Estimate"
          rows={govFees}
          defaultOpen={false}
        />
        <FeeAccordion
          title="Prepaids & escrows"
          subtitle="You’re required to prepay some of your property taxes and home insurance at closing."
          loanEstimateSection="Section F on your Loan Estimate"
          rows={prepaidFees}
          defaultOpen={false}
        />
        <Divider />

        {!!downPayment && <FeeAccordionRow title="Down payment" value={currency(downPayment, 0)} />}
        {!!earnestMoneyDeposit && (
          <FeeAccordionRow
            title="Earnest money deposit"
            value={currency(-1 * earnestMoneyDeposit, 0)}
          />
        )}
        {!!closingFinanced && (
          <FeeAccordionRow title="Financed closing**" value={currency(closingFinanced, 0)} />
        )}
        {!!sellerConcessions && (
          <FeeAccordionRow
            title="Seller concessions"
            value={currency(-1 * concessions, 0)}
            tooltip={sellerConcessions === concessions ? undefined : sellerConcessionTooltip}
          />
        )}
        {
          <FeeAccordionRow
            title="Total cash to close"
            value={currency(
              Math.max(
                totalFees +
                  (downPayment || 0) -
                  earnestMoneyDeposit -
                  closingFinanced -
                  concessions,
                0
              ),
              0
            )}
          />
        }
      </CashToCloseCard>

      <Disclaimers>
        {typeof monthlyMI.value === 'number' && monthlyMI.value > 0 && (
          <OwnUpSmallBody variant="body1">
            * This is an estimated {monthlyMI.name} (AKA Mortgage Insurance). Your actual{' '}
            {monthlyMI.name} is often, but not always, lower.
          </OwnUpSmallBody>
        )}
        {hasEstimates && <OwnUpSmallBody variant="body1">** Our best estimate</OwnUpSmallBody>}
        {hasOptionals && <OwnUpSmallBody variant="body1">&dagger; Optional</OwnUpSmallBody>}
      </Disclaimers>
    </div>
  );
};
