import { OwnUpFillButtonPrimary } from '@rategravity/own-up-component-library';
import { ChevronDownIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/chevron-down';
import { ChevronUpIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/chevron-up';
import React from 'react';
import styled from 'styled-components';

const ToggleButton = styled(OwnUpFillButtonPrimary).attrs({ iconPosition: 'right' })`
  margin-top: ${({ theme }) => theme.spacing(4)}px;
`;

export interface ShowAllProps {
  togglable: boolean;
  showAll: boolean;
  onShowAll: () => void;
  countAllOffers: number;
}

export const ShowAllToggle = ({ togglable, showAll, onShowAll, countAllOffers }: ShowAllProps) =>
  togglable ? (
    <ToggleButton onClick={onShowAll} icon={showAll ? ChevronUpIcon : ChevronDownIcon}>
      {showAll ? 'Show less' : `Show all offers (${countAllOffers})`}
    </ToggleButton>
  ) : null;
