import { of } from '@rategravity/core-lib/optional';
import {
  OwnUpAppBar,
  OwnUpCallButton,
  OwnUpText,
  useAdvisors
} from '@rategravity/own-up-component-library-legacy';
import React from 'react';
import { useSelector } from 'react-redux';
import { useIsMobile } from '../../../hooks/is-mobile';
import { advisorSelector } from '../../redux/selector';

export const HeaderView = () => {
  const advisorId = useSelector(advisorSelector);
  const allAdvisors = useAdvisors();
  const advisorPhone = advisorId.flatMap((id) => of(allAdvisors[id])).flatMap((a) => of(a.phone));
  return <RatesHeader advisorPhoneNumber={advisorPhone.value || ''} />;
};

export const RatesHeader = ({ advisorPhoneNumber }: { advisorPhoneNumber: string }) => {
  const isMobile = useIsMobile();
  return (
    <OwnUpAppBar variant="minimal">
      {isMobile && <OwnUpText variant="bold">Your loan options</OwnUpText>}
      {!!advisorPhoneNumber ? (
        <OwnUpCallButton
          variant={['header', 'headerAlt']}
          phoneNumber={advisorPhoneNumber}
          fullText={advisorPhoneNumber}
        />
      ) : (
        <span />
      )}
    </OwnUpAppBar>
  );
};
