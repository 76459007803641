import { event } from '@rategravity/frontend/modules/user-actions';
import { FOG_60, OwnUpSideModal } from '@rategravity/own-up-component-library';
import { RouterAction } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useActionPipe } from '../../../hooks/use-action-pipe';
import { isCompleteLenderBioReview, LenderData } from '../../../rates/redux/selector';
import { Lender } from '../../components/lender';
import { Tabs } from '../../components/scrollable-menus/tabs';
import { CostBreakdown } from './cost-breakdown';
import { LenderReputation } from './lender-reputation';

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

interface OfferDetailsPageProps {
  onClose: () => void;
  onLockRequest: (s: string) => RouterAction;
  onCompareOffer: () => void;
  lenderData: LenderData;
  showComparisonShopping: boolean;
  licensedAdvisors: string[];
}

const StyledModal = styled(OwnUpSideModal)`
  .modal-body {
    padding: 0;
    max-width: max(100vw, 480px);
    width: fill-available;
    margin-left: 48px;
    :focus {
      outline: none;
    }
    ${({ theme }) => theme.breakpoints.up('lg')} {
      width: 960px;
      margin-left: 0;
    }
    ${({ theme }) => theme.breakpoints.down('xs')} {
      margin-left: 0;
    }
    > h2.MuiTypography-h2 {
      margin-top: 48px;
      ${({ theme }) => theme.breakpoints.down('xs')} {
        margin-top: 24px;
      }
    }
    > .modal-exit-button {
      margin: 24px 24px 0 0;
    }
    > .modal-content {
      padding: 0;
      margin: 40px 0 0 0;
      max-width: unset;
    }
  }
`;

const LenderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 40px;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin: 0 24px;
  }
`;

const Divider = styled.div`
  border-bottom: 2px solid ${FOG_60};
  position: relative;
  top: -2px;
  margin-bottom: -2px;
`;

export const OfferDetailsPage = ({
  onClose,
  onLockRequest,
  onCompareOffer,
  lenderData: { lenderInfo, lenderBio },
  showComparisonShopping
}: OfferDetailsPageProps) => {
  const [tab, setTab] = useState<number>(0);
  const dispatchAction = useActionPipe();
  useEffect(() => {
    dispatchAction(event('openOfferDetails'));
  }, [dispatchAction]);
  const lenderOverviewAction = () => {
    dispatchAction(event('clicked-lenderOverview'));
  };
  const costBreakdownAction = () => {
    dispatchAction(event('clicked-costBreakdown'));
  };
  const scheduleCallAction = () => {
    dispatchAction(event('clickedScheduleCall-OfferDetails'));
  };
  const showTabs =
    lenderBio.highlights.length > 0 ||
    (!!lenderBio.lenderReview && isCompleteLenderBioReview(lenderBio.lenderReview));

  return (
    <StyledModal
      onClose={onClose}
      open={true}
      title={
        (
          <LenderWrapper>
            <Lender {...lenderInfo} />
          </LenderWrapper>
        ) as any
      }
    >
      <React.Fragment>
        {showTabs && (
          <Tabs
            items={[
              {
                key: 0,
                text: 'Cost breakdown',
                active: tab === 0,
                action: lenderOverviewAction,
                width: 'unset'
              },
              {
                key: 1,
                text: 'Lender reviews',
                active: tab === 1,
                action: costBreakdownAction,
                width: 'unset'
              }
            ]}
            setTab={setTab}
            containerProps={{
              padding: '0 16px'
            }}
          />
        )}
        <Divider />
        {tab === 0 || !showTabs ? (
          <CostBreakdown
            compareProps={{ showComparisonShopping, onCompareOffer }}
            onLockRequest={onLockRequest}
            scheduleCallAction={scheduleCallAction}
          />
        ) : (
          <LenderReputation
            highlights={lenderBio.highlights}
            lenderReview={lenderBio.lenderReview}
          />
        )}
      </React.Fragment>
    </StyledModal>
  );
};
