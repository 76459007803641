import {
  FOG_40,
  IconLink,
  OwnUpSmallBody,
  OwnUpSmallBodyMedium,
  OwnUpSmallSubheadlineMedium,
  OwnUpSmallSubheadlineRegular
} from '@rategravity/own-up-component-library';
import { ChevronDownIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/chevron-down';
import { ChevronRightIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/chevron-right';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { AccordionFields } from '.';

const HeaderDetailsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  border-radius: 8px;
  padding: 7px;
  background: ${FOG_40};
`;

const HeaderDetailsFields = styled.div`
  display: flex;
  flex-direction: row;
  flex: 2;
  margin-left: 16px;
`;

const PermutationFields = ({ fields }: { fields: AccordionFields }) => (
  <HeaderDetailsFields>
    <HeaderDetailsContainer>
      {fields.map(({ displayField, displayValue }, idx) => (
        <Fragment key={`perm-${idx}`}>
          {idx != 0 && <PipeSpan />}
          <OwnUpSmallBodyMedium variant="body1">{displayField}</OwnUpSmallBodyMedium>
          &nbsp;
          <OwnUpSmallBody variant="body1">{displayValue}</OwnUpSmallBody>
        </Fragment>
      ))}
    </HeaderDetailsContainer>
  </HeaderDetailsFields>
);

const DetailsDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Subtitle = styled(OwnUpSmallSubheadlineRegular).attrs({ variant: 'h1' })`
  padding-left: ${({ theme }) => theme.spacing(2)}px;
`;
export const DetailsContainer = ({
  name,
  subtitle,
  fields = []
}: {
  name: string;
  subtitle?: string;
  fields?: AccordionFields;
}) => (
  <DetailsDiv>
    <OwnUpSmallSubheadlineMedium variant="h1">{name}</OwnUpSmallSubheadlineMedium>
    {subtitle && <Subtitle>{subtitle}</Subtitle>}
    {fields.length > 0 && <PermutationFields fields={fields} />}
  </DetailsDiv>
);

const HeaderDiv = styled.div`
  padding: ${({ theme }) => theme.spacing(4, 0)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const PipeSpan = () => <span style={{ padding: '0 10px' }}>|</span>;

const Expander = styled(IconLink)`
  margin-left: ${({ theme }) => theme.spacing(1)}px;
  white-space: nowrap;
`;

export const Header = ({
  isOpen,
  setOpen,
  name,
  subtitle,
  fields,
  arrowDisplay
}: {
  isOpen: boolean;
  setOpen: (value: React.SetStateAction<boolean>) => void;
  name: string;
  subtitle?: string;
  fields?: AccordionFields;
  arrowDisplay?: string;
}) => (
  <HeaderDiv onClick={() => setOpen(!isOpen)}>
    <DetailsContainer name={name} fields={fields} subtitle={subtitle} />
    <Expander icon={isOpen ? ChevronDownIcon : ChevronRightIcon}>{arrowDisplay}</Expander>
  </HeaderDiv>
);
