import { of } from '@rategravity/core-lib/optional';
import * as t from 'type-shift';
import { resolveFeeValue } from '../../modules/fee-helpers';
import { Offer, OfferPermutation, Rates } from '../state';

const feeValue = t.number.or(t.literal('Unknown')).default(() => 0);
const resolvedFeeValue = feeValue.pipe((v) => resolveFeeValue(v));

const parseOffer = t.strict<Offer>({
  apr: t.number,
  cashOutValue: t.number.or(t.null).default(() => 0),
  downPayment: t.number.or(t.null).default(() => 0),
  rolledIn: t.number.default(() => 0),
  upFrontInsuranceFee: t.optional(t.strict({ label: t.string, value: t.number })),
  closingFinanced: t.number.default(() => 0),
  earnestMoneyDeposit: t.number.or(t.null.pipe(() => 0)).default(() => 0),
  sellerConcessions: t.number.default(
    t
      .forPath(
        [t.ParentPath, 'allFees'],
        t.array(t.strict({ name: t.string, value: resolvedFeeValue }))
      )
      .pipe((fees) =>
        of(fees.find(({ name }) => name === 'Seller Concessions'))
          .map(({ value }) => value * -1) // seller concessions previously stored as negative fee
          .orElse(() => 0)
      )
  ),
  id: t.string,
  initialCap: t.forPath(
    [t.ParentPath, 'caps', 'initial'],
    t.number.default(() => 0)
  ),
  lifetimeCap: t.forPath(
    [t.ParentPath, 'caps', 'lifetime'],
    t.number.default(() => 0)
  ),
  periodicCap: t.forPath(
    [t.ParentPath, 'caps', 'periodic'],
    t.number.default(() => 0)
  ),
  lenderName: t.string,
  lender: t.optional(
    t.strict({
      lenderId: t.optional(t.string),
      licensedStates: t.array(t.string),
      yearFounded: t.optional(t.number),
      fundedAmountLastYear: t.optional(t.string)
    })
  ),
  loanType: t.string,
  logo: t.string,
  squareLogo: t.string,
  monthlyMI: t.strict({
    label: t.string.default(() => 'PMI'),
    value: t.number.default(() => 0),
    monthsOfPayment: t.number.or(t.coerce([t.null], undefined)) // coerce null to undefined
  }),
  nmls: t.string,
  pAndI: t.number,
  interestOnly: t.boolean.default(() => false),
  rate: t.number,
  rateLock: t.number,
  recommend: t.boolean.default(() => false),
  scenarioLabel: t.forPath(
    [t.ParentPath, 'label'],
    t.string.default(() => '')
  ),
  allFees: t
    .array(
      t.strict({
        name: t.string,
        value: feeValue,
        type: t.string,
        modifiers: t.array(t.string),
        group: t.string
      })
    )
    .default(() => []),
  competitivenessPercentile: t.number.or(t.null).default(() => null)
});

const parseOfferPermutation = t.strict<OfferPermutation>({
  name: t.string,
  attributes: t.array(
    t.strict({
      name: t.string,
      value: t.number.or(t.string).or(t.array(t.number)),
      format: t.optional(t.string)
    })
  ),
  offers: t.array(parseOffer)
});

export const loadActionType = 'Load' as const;
export const loadAction = (id: string) => ({
  type: loadActionType,
  payload: { id }
});

export type LoadAction = ReturnType<typeof loadAction>;

export const dataLoadedActionType = 'DataLoaded' as const;
export interface DataLoadedAction {
  type: typeof dataLoadedActionType;
  payload: Rates;
}

const dataLoadedConverter = t.strict<DataLoadedAction['payload']>({
  advisor: t.string,
  condoFee: t.number.or(t.null).default(() => 0),
  existingLoanSize: t.number.or(t.null).default(() => 0),
  homeInsurance: t.number.or(t.null.pipe(() => 0)).default(() => 0),
  id: t.string,
  note: t.forPath(
    [t.ParentPath, 'personalNote'],
    t.string.default(() => '')
  ),
  address: t.optional(
    t.strict({
      street: t.string.default(() => '')
    })
  ),
  occupancy: t.string,
  propertyType: t.string,
  propertyState: t.optional(t.string),
  primaryBorrower: t.string,
  purchasePrice: t.number.or(t.null).default(() => 0),
  propertyValue: t.number.or(t.null).default(() => 0),
  realEstateTax: t.number.or(t.null.pipe(() => 0)).default(() => 0),
  updatedTime: t.number,
  offerPermutations: t.array(parseOfferPermutation),
  expirationTime: t.optional(t.number),
  shareId: t.optional(t.string),
  lockedOfferId: t.optional(t.string.or(t.null)),
  sampleRate: t.boolean,
  isLicensedState: t.boolean,
  consistentPermutationFields: t.array(
    t.strict({
      title: t.string,
      value: t.number.or(t.string),
      format: t.optional(t.string)
    })
  )
});

export const dataLoadedAction = (data: unknown) => ({
  type: dataLoadedActionType,
  payload: dataLoadedConverter(data)
});

export const errorLoadingActionType = 'ErrorLoading' as const;
export const errorLoadingAction = (id: string) => ({
  type: errorLoadingActionType,
  payload: { id }
});

export type ErrorLoadingAction = ReturnType<typeof errorLoadingAction>;
