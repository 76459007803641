import { Actions, identify } from '@rategravity/frontend/modules/user-actions';
import { Store } from 'redux';
import { Observable, ReplaySubject } from 'rxjs';
import { ApplicationState, LoadedRate } from '../redux/state';

export function enhance(
  actionPipe: Observable<Actions>,
  store: Store<ApplicationState>
): Observable<Actions> {
  const withEnhancedData = new ReplaySubject<Actions>(10);
  let extraData: Record<string, unknown> = {};
  let previousState: ApplicationState;
  const onStateChange = (state: ApplicationState) => {
    const id = state.router.location.pathname.split('rates/')[1].split('/')[0];
    // emit identify on load
    const rateQuote = state.rates.allRates[id] as LoadedRate;
    if (
      rateQuote &&
      rateQuote.loading === false &&
      rateQuote.error === false &&
      (previousState === undefined || previousState!.rates.allRates[id].loading === true)
    ) {
      withEnhancedData.next(
        identify(undefined, {
          advisor: rateQuote.advisor
        })
      );
    }
    previousState = state;
    extraData = { advisor: rateQuote ? rateQuote.advisor : null };
  };

  store.subscribe(() => {
    onStateChange(store.getState());
  });

  actionPipe
    .map((d) => {
      if ('properties' in d) {
        return { ...d, properties: { ...extraData, ...d.properties } };
      } else {
        return d;
      }
    })
    .subscribe(withEnhancedData);

  onStateChange(store.getState());

  return withEnhancedData;
}
