import {
  FOG_20,
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridWrapper
} from '@rategravity/own-up-component-library';
import { RouterAction } from 'connected-react-router';
import React from 'react';
import styled from 'styled-components';
import { AdvisorView } from '../../components/advisor';
import { FeeBreakdownView } from '../../views/offer-details-fee-view';
import { OfferSummaryView } from '../../views/offer-details-summary-view';

const Container = styled.div`
  margin-bottom: 48px;
`;

const TopWrapper = styled.div`
  background-color: ${FOG_20};
  margin-bottom: 40px;
  ${({ theme }) => theme.breakpoints.only('xs')} {
    margin-bottom: 32px;
  }
`;

const GridWrapper = styled(OwnUpGridWrapper)`
  ${({ theme }) => theme.breakpoints.up('xl')} {
    width: unset;
  }
  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin: 0 40px;
  }
`;

export const CostBreakdown = ({
  compareProps,
  onLockRequest,
  scheduleCallAction
}: {
  compareProps: { showComparisonShopping: boolean; onCompareOffer: () => void };
  onLockRequest: (s: string) => RouterAction;
  scheduleCallAction: () => void;
}) => (
  <Container>
    <TopWrapper>
      <GridWrapper>
        <OwnUpGridContainer variant="slim" spacing={0}>
          <OwnUpGridItem xs={12}>
            <OfferSummaryView compareProps={compareProps} onLockRequest={onLockRequest} />
          </OwnUpGridItem>
        </OwnUpGridContainer>
      </GridWrapper>
    </TopWrapper>
    <GridWrapper>
      <OwnUpGridContainer variant="slim" spacing={0}>
        <OwnUpGridItem xs={12} md={7} lg={8}>
          <FeeBreakdownView />
        </OwnUpGridItem>
        <OwnUpGridItem xs={12} md={5} lg={4}>
          <AdvisorView scheduleCallAction={scheduleCallAction} />
        </OwnUpGridItem>
      </OwnUpGridContainer>
    </GridWrapper>
  </Container>
);
