import {
  MINT_100,
  OwnUpBody,
  OwnUpIcon,
  PINE_100,
  SAGE_100
} from '@rategravity/own-up-component-library';
import { SuccessDarkIcon } from '@rategravity/own-up-component-library/icon-library/notification-icons/success-dark';
import React from 'react';
import styled from 'styled-components';
import { AnimatedEllipsis } from '../../../components/animated/ellipsis';
import { AnimatedHourglassWrapper } from '../../../components/icons/hourglass-icon';
import { YcbmButton } from '../../../components/schedule-call-button';

const Container = styled.div`
  margin-bottom: 32px;
  background-color: ${SAGE_100};
  border-radius: 8px;
  padding: 24px;
  ${({ theme }) => theme.breakpoints.up('lg')} {
    margin-bottom: 40px;
    padding: 32px;
  }
`;

const BodyWrapperContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const OfferDetailsSending = () => (
  <Container>
    <BodyWrapperContent>
      <AnimatedHourglassWrapper />
      <OwnUpBody variant="body1" style={{ marginLeft: '16px' }}>
        Just a moment, your offer details are sending
        <AnimatedEllipsis />
      </OwnUpBody>
    </BodyWrapperContent>
  </Container>
);

interface OutsideOfferDetailsSentProps {
  ycbmLink: string;
  beforeNavigation: () => void;
}

const BookingButtonContainer = styled.div`
  margin-top: 24px;
  ${({ theme }) => theme.breakpoints.up('lg')} {
    margin-top: 32px;
  }
`;

const Icon = styled(OwnUpIcon)`
  > svg > circle {
    fill: ${MINT_100} !important;
  }
`;

export const OfferDetailsSent = ({ ycbmLink, beforeNavigation }: OutsideOfferDetailsSentProps) => (
  <Container>
    <BodyWrapperContent>
      <Icon icon={SuccessDarkIcon} height="32px" color={`${PINE_100} !important`} />
      <OwnUpBody variant="body1" style={{ marginLeft: '16px' }}>
        All set, let&apos;s take the next step!
      </OwnUpBody>
    </BodyWrapperContent>
    <BookingButtonContainer>
      <YcbmButton beforeNavigation={beforeNavigation} ycbmLink={ycbmLink} />
    </BookingButtonContainer>
  </Container>
);
