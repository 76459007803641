import { event } from '@rategravity/frontend/modules/user-actions';
import React from 'react';
import { useSelector } from 'react-redux';
import { ShareableLinkButton } from '../../../components/link-button';
import { useActionPipe } from '../../../hooks/use-action-pipe';
import { manifestAccountInfoSelector } from '../../../redux/manifest';
import { RateListRedux } from '../../components/rate-list/wrapper';
import { RatesUpdatedTime } from '../../components/updated-time';
import {
  customDisclaimersSelector,
  lockEventPropertiesSelector,
  noteSelector,
  scenarioSelector,
  updatedTimeSelector
} from '../../redux/selector';
import { LoadedRate, ApplicationState as RatesApplicationState } from '../../redux/state';
import { RatePage } from '.';
import { HeaderView } from './header';

export const RatesPageRedux = ({
  rootPath,
  id,
  useDefaultHeader = true
}: {
  rootPath: string;
  id: string;
  useDefaultHeader?: boolean;
}) => {
  const shareId = useSelector(
    ({ rates: { allRates } }: RatesApplicationState) => (allRates[id] as LoadedRate).shareId
  );
  const updatedTime = useSelector(updatedTimeSelector);
  const noteData = useSelector(noteSelector);
  const scenarioProps = useSelector(scenarioSelector);
  const customDisclaimers = useSelector(customDisclaimersSelector);
  const eventProperties = useSelector(lockEventPropertiesSelector);
  const actionPipe = useActionPipe();
  actionPipe(event('viewed-quotes'));
  const { accountId } = useSelector(manifestAccountInfoSelector);

  return (
    <RatePage
      header={useDefaultHeader ? <HeaderView /> : <></>}
      scenario={scenarioProps.isSome() ? scenarioProps.value : []}
      updatedTime={
        updatedTime.isSome() ? <RatesUpdatedTime updatedTime={updatedTime.value} /> : null
      }
      note={noteData.value || ''}
      rateList={<RateListRedux id={id} rootPath={rootPath} eventProperties={eventProperties} />}
      shareableLink={
        useDefaultHeader ? null : (
          <ShareableLinkButton
            url={`${window.location.origin}/rates/${shareId}`}
            eventProperties={eventProperties}
          />
        )
      }
      customDisclaimers={customDisclaimers}
      accountId={accountId}
    />
  );
};
