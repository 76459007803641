import { all, Optional } from '@rategravity/core-lib/optional';
import { computeBreakEven } from '@rategravity/core-lib/rates';
import { createSelector } from 'reselect';
import { AnalysisSectionProps } from '../../components/comparison-shopping/analysis';
import {
  offerACostInputs,
  offerALifetimeCosts,
  offerAMonthlyPayment,
  offerAUpfrontCosts,
  offerBCostInputs,
  offerBLifetimeCosts,
  offerBMonthlyPayment,
  offerBUpfrontCosts
} from '../../redux/selector';
import { ComparePageProps } from '.';

const determineFactors = (
  baseFactors: string[],
  a: { mortgageInsurance: number; presentValue: number },
  b: { mortgageInsurance: number; presentValue: number }
): string => {
  const factors = [...baseFactors];
  if (a.mortgageInsurance > 0 || b.mortgageInsurance > 0) {
    factors.unshift('mortgage insurance');
  }
  if (a.presentValue !== b.presentValue) {
    factors.unshift('principal');
  }
  if (factors.length > 1) {
    factors.push(`& ${factors.pop()}`);
  }
  if (factors.length > 2) {
    return factors.join(', ');
  }
  return factors.join(' ');
};

export const breakEvenAnalysis = createSelector(
  // use of a nested selector makes testing easier
  createSelector(
    offerACostInputs,
    offerBCostInputs,
    (a, b): Optional<number> =>
      all([a, b]).map(([aInput, bInput]) => computeBreakEven(aInput, bInput))
  ),
  (result): Optional<AnalysisSectionProps['breakEven']> =>
    result.map((breakEvenResult): AnalysisSectionProps['breakEven'] => {
      if (Number.isFinite(breakEvenResult)) {
        if (breakEvenResult === 0) {
          return { variant: 'same' };
        }
        const breakEvenYears = Math.floor(Math.abs(breakEvenResult) / 12);
        const breakEvenMonths = Math.abs(breakEvenResult) % 12;
        const breakEvenTime = [];
        if (breakEvenYears === 1) {
          breakEvenTime.push('1 year');
        } else if (breakEvenYears > 1) {
          breakEvenTime.push(`${breakEvenYears} years`);
        }
        if (breakEvenMonths === 1) {
          breakEvenTime.push('1 month');
        } else if (breakEvenMonths > 1) {
          breakEvenTime.push(`${breakEvenMonths} months`);
        }
        return {
          variant: 'breakeven',
          timeSpan: `more than ${breakEvenTime.join(' ')}`,
          offer: breakEvenResult < 0 ? 'B' : 'A'
        };
      } else {
        return {
          variant: 'better',
          offer: breakEvenResult < 0 ? 'A' : 'B'
        };
      }
    })
);

export const costAtClosingAnalysis = createSelector(
  offerAUpfrontCosts,
  offerBUpfrontCosts,
  (a, b): Optional<AnalysisSectionProps['atClosing']> =>
    all([a, b]).map(([costA, costB]): AnalysisSectionProps['atClosing'] => {
      const aLessB = Math.floor(costA - costB);
      return {
        offer: aLessB < 0 ? 'A' : 'B',
        savings: Math.abs(aLessB),
        factors: 'lender fees'
      };
    })
);

export const monthlyPaymentAnalysis = createSelector(
  offerAMonthlyPayment,
  offerBMonthlyPayment,
  offerACostInputs,
  offerBCostInputs,
  (a, b, aCostInputs, bCostInputs): Optional<AnalysisSectionProps['monthly']> =>
    all([a, b, aCostInputs, bCostInputs]).map(
      ([paymentA, paymentB, inputA, inputB]): AnalysisSectionProps['monthly'] => {
        const aLessB = Math.floor(paymentA - paymentB);
        return {
          offer: aLessB < 0 ? 'A' : 'B',
          savings: Math.abs(aLessB),
          factors: determineFactors(['interest'], inputA, inputB)
        };
      }
    )
);

export const lifeTimeCostAnalysis = createSelector(
  offerALifetimeCosts,
  offerBLifetimeCosts,
  offerACostInputs,
  offerBCostInputs,
  (costA, costB, a, b): Optional<AnalysisSectionProps['lifetime']> =>
    all([costA, costB, a, b]).map(
      ([costValueA, costValueB, costInputA, costInputB]): AnalysisSectionProps['lifetime'] => {
        const aLessB = Math.floor(
          costValueA + costInputA.presentValue - (costValueB + costInputB.presentValue)
        );
        const termYears = Math.ceil(Math.max(costInputA.loanTerm, costInputB.loanTerm));
        return {
          timeFrame: `In ${termYears} years`,
          offer: aLessB < 0 ? 'A' : 'B',
          savings: Math.abs(aLessB),
          factors: determineFactors(['interest', 'lender fees'], costInputA, costInputB)
        };
      }
    )
);

export const analysisSelector = createSelector(
  breakEvenAnalysis,
  costAtClosingAnalysis,
  monthlyPaymentAnalysis,
  lifeTimeCostAnalysis,
  (maybeBreakEven, maybeAtClosing, maybeMonthly, maybeLifetime): ComparePageProps['analysis'] =>
    all([maybeBreakEven, maybeAtClosing, maybeMonthly, maybeLifetime]).map(
      ([breakEven, atClosing, monthly, lifetime]) => ({
        breakEven,
        atClosing,
        monthly,
        lifetime
      })
    )
);
