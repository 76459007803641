import {
  FOG_20,
  OwnUpBody,
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridOffset,
  OwnUpGridWrapper,
  OwnUpIconButton,
  ownUpLightTheme,
  OwnUpSmallHeadlineBook,
  OwnUpThemeProvider,
  PropsWithTheme
} from '@rategravity/own-up-component-library';
import { ChevronLeftIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/chevron-left';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Scenario } from '../../components/scenario';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(3)}px` /* 24px */};
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding-bottom: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(2)}px` /* 16px */};
  }
`;

const Body = styled(OwnUpBody)`
  margin: 16px 0;
`;

const BackgroundColorContainer = styled.div`
  background-color: ${FOG_20};
  width: 100vw;
  padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(6, 0, 8) /* 48px 0px 64px */};
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(6, 0) /* 48px 0px */};
  }
`;

export interface RatePageProps {
  scenario: {
    title: string;
    value: string;
  }[];
  updatedTime: JSX.Element | null;
  note: string;
  shareableLink: JSX.Element | null;
  accountId: string;
}

export const RateQuoteHeader = ({
  scenario,
  updatedTime,
  note,
  shareableLink,
  accountId
}: RatePageProps) => {
  const history = useHistory();
  return (
    <OwnUpThemeProvider theme={ownUpLightTheme}>
      <BackgroundColorContainer>
        <OwnUpGridWrapper>
          <OwnUpGridContainer variant="slim">
            <OwnUpGridItem xs={12}>
              <Row>
                {accountId && (
                  <OwnUpIconButton
                    icon={ChevronLeftIcon}
                    name="Back"
                    size={1.5}
                    onClick={() => history.push(`/account/${accountId}`)}
                  />
                )}
                {shareableLink}
              </Row>
            </OwnUpGridItem>
            <OwnUpGridItem xs={12} lg={6} xl={5}>
              <OwnUpSmallHeadlineBook variant="h1">Your loan options</OwnUpSmallHeadlineBook>
              <Body variant="body1">{note}</Body>
              {updatedTime}
            </OwnUpGridItem>
            <OwnUpGridOffset xl={1} style={{ padding: 0 }} />
            <OwnUpGridItem xs={12} md={10} lg={6}>
              <Scenario scenarioFields={scenario} />
            </OwnUpGridItem>
          </OwnUpGridContainer>
        </OwnUpGridWrapper>
      </BackgroundColorContainer>
    </OwnUpThemeProvider>
  );
};
