import { OwnUpLargeBody, OwnUpSmallHeadlineBook } from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';

const BodyText = styled(OwnUpLargeBody)`
  margin: 16px 0 48px;
`;

export const FTUTemplate = ({
  title,
  body,
  content
}: {
  title: string;
  body: string;
  content: JSX.Element;
}) => (
  <div>
    <OwnUpSmallHeadlineBook variant="h1">{title}</OwnUpSmallHeadlineBook>
    <BodyText variant="body1" style={{ whiteSpace: 'pre-line' }}>
      {body}
    </BodyText>
    {content}
  </div>
);
