import { OwnUpFillButtonPrimary } from '@rategravity/own-up-component-library';
import { PhoneIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/phone';
import React from 'react';

export type YcbmButtonProps = React.PropsWithChildren<{
  beforeNavigation: () => void;
  ycbmLink: string;
  showIcon?: boolean;
  icon?: JSX.Element;
  useFullWidth?: boolean;
}>;

export const YcbmButton = ({
  beforeNavigation,
  ycbmLink,
  showIcon = true,
  icon,
  useFullWidth
}: YcbmButtonProps) => {
  let iconToUse = showIcon ? <PhoneIcon /> : undefined;
  if (icon) {
    iconToUse = icon;
  }
  return (
    <OwnUpFillButtonPrimary
      to={ycbmLink}
      onClick={(e) => {
        // If beforeNavigation is defined, prevent the typical link behavior
        e.preventDefault();
        // ...then perform the beforeNavigation function.
        beforeNavigation();
        // Allow background tasks to run before leaving
        setTimeout(() => {
          window.location.href = ycbmLink;
        }, 10);
      }}
      icon={iconToUse}
      fullWidth={useFullWidth}
    >
      Schedule a call
    </OwnUpFillButtonPrimary>
  );
};
