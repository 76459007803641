import { currency } from '@rategravity/frontend/modules/numbers';
import { OwnUpBody } from '@rategravity/own-up-component-library';
import React from 'react';
import { Highlight, HighlightProps } from '../vertical-highlights';

export interface GenericHighlightProps {
  title: HighlightProps['title'];
  helpText?: HighlightProps['helpText'];
  timeFrame: string;
  offer: 'A' | 'B';
  savings: number;
  factors: string;
}

export const GenericHighlight = ({
  title,
  helpText,
  timeFrame,
  offer,
  savings,
  factors
}: GenericHighlightProps) => (
  <Highlight
    title={title}
    helpText={helpText}
    body={
      savings > 0 ? (
        <OwnUpBody variant="body1">
          {timeFrame}, <em>Offer {offer}</em> saves you <em>{currency(savings, 0)}</em> in {factors}
          .
        </OwnUpBody>
      ) : (
        <OwnUpBody variant="body1">
          {timeFrame}, Offer A and Offer B cost the same in {factors}.
        </OwnUpBody>
      )
    }
  />
);
