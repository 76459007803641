import { currency, rate as formatRate } from '@rategravity/frontend/modules/numbers';
import {
  FOG_100,
  FOG_20,
  OwnUpBody,
  OwnUpFillButtonPrimary,
  ownUpLightTheme,
  OwnUpSmallBody,
  OwnUpSmallBodyMedium,
  OwnUpSmallSubheadlineMedium,
  OwnUpSmallSubheadlineRegular,
  OwnUpThemeProvider
} from '@rategravity/own-up-component-library';
import { LockIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/lock';
import React from 'react';
import styled from 'styled-components';
import { LockRequest } from '../../redux/state';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  :not(:last-child) {
    margin-bottom: 8px;
  }
`;

const LineItem = ({ label, value }: { label: string; value: string }) => (
  <Row>
    <OwnUpSmallBody variant="body1">{label}</OwnUpSmallBody>
    <OwnUpSmallBodyMedium variant="body1">{value}</OwnUpSmallBodyMedium>
  </Row>
);

const Container = styled.div`
  background-color: ${FOG_20};
  border-radius: 8px 8px 0 0;
  padding: 24px;
`;

const Divider = styled.hr`
  border: 0;
  height: 1px;
  background: ${FOG_100};
  margin: 24px 0;
`;

const Bottom = styled.div`
  background-color: ${FOG_100};
  border-radius: 0 0 8px 8px;
  padding: 24px;
`;

const Lender = styled(Row)`
  margin-bottom: 16px !important;
  justify-content: flex-start;
`;

const Rate = styled(Row)`
  align-items: flex-end;
`;

const LockText = styled(OwnUpBody)`
  margin: 32px 0 40px;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin: 24px 0 32px;
  }
`;

export const LockConfirmation = ({
  data: {
    loanType,
    apr,
    rate,
    lenderCredit,
    points,
    rateLock,
    pAndI,
    totalClosing,
    logo,
    lender,
    lenderFees
  },
  onLock
}: {
  data: LockRequest;
  onLock: () => void;
}) => (
  <OwnUpThemeProvider theme={ownUpLightTheme}>
    <Container>
      <Lender>
        <img
          src={logo}
          alt={`${lender} logo`}
          style={{ marginRight: '8px', height: '20px', width: 'auto' }}
        />
        <OwnUpSmallBody variant="body1">{lender}</OwnUpSmallBody>
      </Lender>
      <Rate>
        <OwnUpSmallBody variant="body1">Rate / APR</OwnUpSmallBody>
        <OwnUpSmallSubheadlineRegular variant="h1" style={{ display: 'inline-flex' }}>
          <OwnUpSmallSubheadlineMedium variant="h1">
            {formatRate(rate, 3)} /&nbsp;
          </OwnUpSmallSubheadlineMedium>
          {formatRate(apr, 3)}
        </OwnUpSmallSubheadlineRegular>
      </Rate>
      <LineItem label="Product type" value={loanType} />
      <LineItem label="Lock days" value={`${rateLock} days`} />
      <Divider />
      <LineItem label="Mo. Principal &amp; Interest" value={currency(pAndI, 0)} />
      <LineItem
        label={points > 0 ? 'Points' : 'Lender credit'}
        value={currency(points > 0 ? points : lenderCredit, 0)}
      />
      <LineItem label="Lender fees" value={currency(lenderFees, 0)} />
    </Container>
    <Bottom>
      <LineItem label="Due at closing" value={currency(totalClosing, 0)} />
    </Bottom>
    <LockText variant="body1">
      By requesting to lock your rate, we will notify your advisor of your request. At that time
      they will confirm your rate and all your lock details.
    </LockText>
    <OwnUpFillButtonPrimary icon={LockIcon} onClick={onLock}>
      Request lock
    </OwnUpFillButtonPrimary>
  </OwnUpThemeProvider>
);
