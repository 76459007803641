import {
  OwnUpIcon,
  OwnUpTable,
  OwnUpTableCell,
  OwnUpText,
  PRIMARY,
  PRIMARY_DISABLED,
  useSortedArray
} from '@rategravity/own-up-component-library-legacy';
import React, { useLayoutEffect, useMemo } from 'react';
import styled from 'styled-components';
import { MonthlyMI } from '../../redux/state';
import { DesktopRateRow, DesktopRateRowProps } from './desktop-row';
import { ShowAllToggle } from './show-all-toggle';
import { useTogglable } from './use-togglable';

export interface DesktopRateTableProps {
  rows: ({ offerId: string; recommend: boolean } & Omit<
    DesktopRateRowProps,
    | 'sorted'
    | 'showMortgageInsurance'
    | 'showLC'
    | 'showPoints'
    | 'onViewCostBreakdown'
    | 'onLock'
    | 'showCompareButton'
    | 'onCompare'
  >)[];
  showMortgageInsurance: boolean;
  showLC: boolean;
  showPoints: boolean;
  showInterestOnly: boolean;
  onViewCostBreakdown: (offerId: string) => void;
  onLock: (offerId: string) => void;
  onCompare: (offerId: string) => void;
  showCompareButton: boolean;
  onToggle: (open: boolean) => void;
}

const PermutationTableContainer = styled.div`
  margin-bottom: 32px;
`;

const Table = styled(OwnUpTable)`
  box-shadow: none;
  box-sizing: border-box;
  & .ou-table__inner-container {
    margin: 0;
  }
  & th {
    border-bottom: none;
  }
`;

const NonSelectableText = styled(OwnUpText)`
  font-size: 12px;
  user-select: none;
  text-align: left;
`;

const SortableHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  min-height: 44px;
`;

const IconBox = styled.div`
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  transform: translate(0, -7px);
`;

const SortableHeader = ({
  children,
  sortOrder,
  direction
}: React.PropsWithChildren<{ sortOrder?: number; direction?: 'ASC' | 'DESC' }>) => (
  <SortableHeaderContainer>
    <NonSelectableText variant={sortOrder === 0 ? 'bold' : undefined}>{children}</NonSelectableText>
    <IconBox>
      <OwnUpIcon
        color={sortOrder === 0 && direction === 'DESC' ? PRIMARY : PRIMARY_DISABLED}
        icon="TriangleUp"
        variant="noTop"
        height="7px"
        width="7px"
      />
      <OwnUpIcon
        color={sortOrder === 0 && direction === 'ASC' ? PRIMARY : PRIMARY_DISABLED}
        icon="TriangleDown"
        variant="noTop"
        height="7px"
        width="7px"
      />
    </IconBox>
  </SortableHeaderContainer>
);

export const DesktopRateTable = ({
  rows,
  showInterestOnly,
  showMortgageInsurance,
  showLC,
  showPoints,
  onViewCostBreakdown,
  onLock,
  onCompare,
  showCompareButton,
  onToggle
}: DesktopRateTableProps) => {
  const [filteredRows, { ...toggleProps }] = useTogglable(rows, onToggle);

  // Monthly MI is an object so need to specify a custom sortBy function for it,
  // everything else can use the default
  const sortByInput = useMemo(
    () => ({
      monthlyMI: (a: MonthlyMI, b: MonthlyMI) => a.value - b.value
    }),
    []
  );
  const [sortedRows, sortBy, sorted] = useSortedArray(filteredRows, sortByInput);
  useLayoutEffect(() => {
    sortBy('rate');
  }, [sortBy]);

  const monthlyInsuranceHeader = useMemo(
    () => (rows.length > 0 ? rows[0].monthlyMI.label : 'PMI'),
    [rows]
  );

  return (
    <PermutationTableContainer>
      <Table
        variant="roundedCorners"
        headers={[
          <NonSelectableText key="lender">Lender</NonSelectableText>,
          <OwnUpTableCell variant="clickable" key="rate" onClick={() => sortBy('rate')}>
            <SortableHeader {...sorted.rate}>Rate / APR</SortableHeader>
          </OwnUpTableCell>,
          <OwnUpTableCell variant="clickable" key="pAndI" onClick={() => sortBy('pAndI')}>
            <SortableHeader {...sorted.pAndI}>
              {showInterestOnly ? (
                'Mo. Interest'
              ) : (
                <React.Fragment>
                  Mo. Principal
                  <br />
                  &amp; Interest
                </React.Fragment>
              )}
            </SortableHeader>
          </OwnUpTableCell>,
          ...(showMortgageInsurance
            ? [
                <OwnUpTableCell
                  variant="clickable"
                  key="monthlyMI"
                  onClick={() => sortBy('monthlyMI')}
                >
                  <SortableHeader {...sorted.monthlyMI}>{monthlyInsuranceHeader}</SortableHeader>
                </OwnUpTableCell>
              ]
            : []),
          ...(showLC
            ? [
                <OwnUpTableCell
                  variant="clickable"
                  key="lenderCredit"
                  onClick={() => sortBy('lenderCredit')}
                >
                  <SortableHeader {...sorted.lenderCredit}>Lender credits</SortableHeader>
                </OwnUpTableCell>
              ]
            : []),
          ...(showPoints
            ? [
                <OwnUpTableCell variant="clickable" key="points" onClick={() => sortBy('points')}>
                  <SortableHeader {...sorted.points}>Points</SortableHeader>
                </OwnUpTableCell>
              ]
            : []),
          <OwnUpTableCell variant="clickable" key="fees" onClick={() => sortBy('fixedLenderFees')}>
            <SortableHeader {...sorted.fixedLenderFees}>Fixed lender fees</SortableHeader>
          </OwnUpTableCell>,
          <OwnUpTableCell variant="clickable" key="cost" onClick={() => sortBy('lenderFees')}>
            <SortableHeader {...sorted.lenderFees}>Net/Total origination costs</SortableHeader>
          </OwnUpTableCell>,
          null
        ]}
        data={sortedRows.map(({ offerId, ...row }) => (
          <DesktopRateRow
            key={offerId}
            showMortgageInsurance={showMortgageInsurance}
            showLC={showLC}
            showPoints={showPoints}
            sorted={sorted}
            onViewCostBreakdown={() => onViewCostBreakdown(offerId)}
            onLock={() => onLock(offerId)}
            showCompareButton={showCompareButton}
            onCompare={() => onCompare(offerId)}
            {...row}
          />
        ))}
      />
      <ShowAllToggle {...toggleProps} />
    </PermutationTableContainer>
  );
};
