import { Advisor } from '@rategravity/own-up-component-library-legacy';

/**
 * Return an array of advisor 'short names' for 
     those advisors that are licensed in the given state 
 */
export const findLicensedAdvisors = (
  advisors: Record<string, Advisor>,
  state: string
): string[] => {
  return Object.entries(advisors).reduce(
    (licensedAdvisors, [currentAdvisorName, currentAdvisorData]) =>
      currentAdvisorData.licensed.includes(state) && !(currentAdvisorData as any).deleted
        ? [...licensedAdvisors, currentAdvisorName]
        : licensedAdvisors,
    [] as string[]
  );
};
