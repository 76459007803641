import { TypographyProps } from '@material-ui/core';
import {
  CACTUS_100,
  OwnUpSmallBodyMedium,
  PropsWithTheme
} from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';
import { Breakpoint } from './available-advisors/available-advisors';

const GreenDot = styled.span`
  color: ${CACTUS_100};
  font-weight: bold;
  margin-right: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2)}px;
`;

const TextContainer = styled.div<{ columnBreakpoint: Breakpoint }>`
  color: ${({ theme }: PropsWithTheme<{}>) => theme.palette.text.primary};
  display: flex;
  align-items: start;
  margin: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(0, 0, 0, 3)};
  ${({ theme, columnBreakpoint }) => theme.breakpoints.down(columnBreakpoint)} {
    margin: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2, 0, 0, 0)};
  }
  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(1.5, 0, 0, 0)};
  }
  & > * {
    display: flex;
  }
`;

export type GreenDotTextProps = React.PropsWithChildren<{
  columnBreakpoint?: Breakpoint;
  TextElement?: React.FC<TypographyProps>;
}>;

export const GreenDotText = ({
  children,
  columnBreakpoint = 'xs',
  TextElement = OwnUpSmallBodyMedium,
  ...props
}: GreenDotTextProps) => (
  <TextContainer columnBreakpoint={columnBreakpoint} {...props}>
    <TextElement aria-hidden variant="body1">
      {/** Hide this element from screen readers. */}
      <GreenDot>&#8226;</GreenDot>
    </TextElement>
    <TextElement variant="body1">{children}</TextElement>
  </TextContainer>
);
