import { RouterAction } from 'connected-react-router';
import React, { Fragment } from 'react';
import { useIsMobile } from '../../../hooks/is-mobile';
import { MobilePermutationsMenu } from './mobile-rate-list/mobile-rate-list';
import { RateListPermutation, RateListPermutationProps } from './permutation';

export interface RateActions {
  onDetailsClick: (s: string) => RouterAction;
  onLockClick: (s: string) => RouterAction | undefined;
  onCompareClick: (s: string) => RouterAction;
}

export interface RateListProps extends RateActions {
  offerPermutations: Array<
    Pick<RateListPermutationProps, 'name' | 'attributes' | 'offers' | 'loanType'>
  >;
}

export const RateList = ({
  onDetailsClick,
  onLockClick,
  onCompareClick,
  offerPermutations
}: RateListProps) => {
  const isMobile = useIsMobile();
  const numFixedRateOffers = offerPermutations.reduce(
    (acc, { name, offers }) => acc + (name.includes('Fixed') ? offers.length : 0),
    0
  );
  // only show comparison shopping if there is more than 1 fixed rate offer
  const showComparisonShopping = numFixedRateOffers > 1;
  return (
    <div className="ratelist__container">
      {isMobile ? (
        <MobilePermutationsMenu
          onDetailsClick={onDetailsClick}
          onLockClick={onLockClick}
          onCompareClick={onCompareClick}
          offerPermutations={offerPermutations}
          showComparisonShopping={showComparisonShopping}
        />
      ) : (
        <Fragment>
          {offerPermutations.map((permutation, i) => (
            <RateListPermutation
              key={i}
              onDetailsClick={onDetailsClick}
              onLockClick={onLockClick}
              onCompareClick={onCompareClick}
              showComparisonShopping={showComparisonShopping}
              {...permutation}
            />
          ))}
        </Fragment>
      )}
    </div>
  );
};
