import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../modules/colors';
import { getActiveTabTextStyle, SmallTabText, TabText } from './tab-text';

export interface MenuItem {
  key: number;
  text: string | JSX.Element;
  active: boolean;
  width?: string;
  action?: () => void;
}

interface ContainerProps {
  padding?: string;
  width?: string;
}

interface SetMenuProps {
  items: MenuItem[];
  setTab: (key: number) => void;
  containerProps?: ContainerProps;
}

const Container = styled.div<{ width?: string; padding?: string }>`
  padding: ${({ padding }) => padding || '0 12px'};
  width: ${({ width }) => width || 'calc(100% - 24px)'};
  @media (max-width: 720px) {
    display: flex;
    padding: 0;
    width: 100%;
  }
`;

const Item = styled.button<{ isActive: boolean; width?: string; margin?: string }>`
  position: relative;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0 24px 12px;
  margin: ${({ margin }) => (margin ? margin : '0 12px')};
  width: ${({ width }) => (width ? width : '160px')};

  @media (max-width: 720px) {
    flex-grow: 1;
    margin: 0;
    width: unset;
  }
  &:after {
    position: absolute;
    left: 0;
    bottom: 0;
    content: '';
    height: 3px;
    width: 100%;
    background-color: ${colors.SUCCESS};
    opacity: ${({ isActive }) => (isActive ? 1 : 0)};
    transition: opacity 300ms;
    z-index: 10;
  }
  &:hover {
    > * {
      ${getActiveTabTextStyle(true)}
    }
  }
`;

export const Tabs = ({
  items,
  setTab,
  containerProps,
  size = 'large'
}: SetMenuProps & { size?: 'small' | 'large' }) => {
  const Text = size === 'large' ? TabText : SmallTabText;
  return (
    <Container
      width={containerProps && containerProps.width}
      padding={containerProps && containerProps.padding}
    >
      {items.map((item) => (
        <Item
          key={item.key}
          onClick={() => {
            setTab(item.key);
            if (item.action !== undefined) {
              item.action();
            }
          }}
          isActive={item.active}
          width={item.width}
          margin="0px"
        >
          <Text variant="body1" isActive={item.active}>
            {item.text}
          </Text>
        </Item>
      ))}
    </Container>
  );
};
