import { FOG_20, FOG_60, OwnUpOverline, WALDEN_100 } from '@rategravity/own-up-component-library';
import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { Tabs } from '../../scrollable-menus/tabs';
import { AccordionBody, AccordionBodyProps } from './accordion-body';
import { AccordionHeader, AccordionHeaderProps } from './accordion-header';
import { OutsideOffer } from './outside-offer';

const AccordionContainer = styled.div`
  margin-bottom: 20px;
  width: 100%;
`;

const Accordion = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  height: 100%;
  background-color: ${FOG_20};
  padding: 24px 24px 24px 40px;
  ${({ theme }) => theme.breakpoints.only('sm')} {
    padding: 0 40px 12px;
  }
  ${({ theme }) => theme.breakpoints.only('xs')} {
    padding: 0 24px 12px;
  }
`;

const TabsWrapper = styled.div`
  background-color: white;
  padding-top: 24px;
  width: auto;
  > div {
    display: flex;
    > button {
      padding: 0 0 12px;
    }
  }
`;

export type OffersAccordionProps = Pick<AccordionBodyProps, 'offerPermutations'> & {
  sections: (Pick<AccordionHeaderProps, 'title'> & Pick<AccordionBodyProps, 'selectedId'>)[];
  onSelect: (title: AccordionHeaderProps['title'], id: string) => void;
  className?: string;
  event: (_: string) => void;
  openableSections: boolean[];
  offersAccordionDispatch: Dispatch<{
    type: 'edit' | 'select';
    index: number;
  }>;
  tab: number;
  setTab: (_: number) => void;
  propertyValue: number | null;
  setScrollingToCta: Dispatch<SetStateAction<boolean>>;
};

const isShareableLink = (path: string) => path.indexOf('account') === -1;

export const Badge = styled(OwnUpOverline)`
  background-color: ${WALDEN_100};
  color: white;
  display: inline;
  padding: 4px 4px 2px 5px;
  margin-left: 5px;
  border-radius: 5px;
`;

const Divider = styled.div`
  border-bottom: 2px solid ${FOG_60};
  position: relative;
  top: -2px;
  margin-bottom: -2px;
`;

export const OffersAccordion = ({
  sections,
  offerPermutations,
  onSelect,
  className,
  event,
  openableSections,
  offersAccordionDispatch,
  tab,
  setTab,
  propertyValue,
  setScrollingToCta
}: OffersAccordionProps) => {
  const showOutsideOffer = !isShareableLink(window.location.href);
  const excludedIds = sections
    .map(({ selectedId }) => selectedId)
    .filter((id): id is string => !!id);

  return (
    <Accordion className={className}>
      {sections.map(({ title, selectedId }, index) => (
        <React.Fragment key={title}>
          <AccordionContainer>
            <AccordionHeader
              open={openableSections[index]}
              title={title}
              selected={selectedId !== null}
              onEdit={() => {
                event('offerEdit-comparisonShopping');
                offersAccordionDispatch({ type: 'edit', index });
              }}
              showOutsideOffer={showOutsideOffer && index === 1}
            />
            {index === 1 && openableSections[index] && showOutsideOffer && (
              <TabsWrapper>
                <Tabs
                  items={[
                    { key: 0, text: 'Own Up offer', active: tab === 0, width: '50%' },
                    {
                      key: 1,
                      text: (
                        <div>
                          Outside offer <Badge>NEW</Badge>
                        </div>
                      ),
                      active: tab === 1,
                      width: '50%'
                    }
                  ]}
                  setTab={(key: number) => {
                    event(
                      key === 0
                        ? 'clickedOwnUpOffersTab-comparisonShopping'
                        : 'clickedOutsideOffersTab-comparisonShopping'
                    );
                    setTab(key);
                  }}
                  containerProps={{
                    width: '100%',
                    padding: '0'
                  }}
                  size="small"
                />
                <Divider />
              </TabsWrapper>
            )}
            {tab === 0 || index === 0 || !openableSections[index] ? (
              <AccordionBody
                open={openableSections[index]}
                selectedId={selectedId}
                excludedIds={excludedIds}
                offerPermutations={offerPermutations}
                onSelect={(id: string) => {
                  onSelect(title, id);
                  offersAccordionDispatch({ type: 'select', index });
                  // send an event each time a unique combination is selected (as long as both A and B are selected)
                  if (index === 1 || sections[1].selectedId !== null) {
                    event('analysisGenerated-comparisonShopping');
                  }
                }}
              />
            ) : (
              <OutsideOffer
                propertyValue={propertyValue}
                event={event}
                setScrollingToCta={setScrollingToCta}
              />
            )}
          </AccordionContainer>
        </React.Fragment>
      ))}
    </Accordion>
  );
};
