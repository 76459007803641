import { event } from '@rategravity/frontend/modules/user-actions';
import {
  CACTUS_100,
  FOG_20,
  FOG_40,
  OwnUpBody,
  OwnUpDetailMedium,
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpLargeSubheadlineRegular,
  OwnUpSmallBody,
  OwnUpUnderlineLink,
  WALDEN_100
} from '@rategravity/own-up-component-library';
import { CheckIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/check';
import React, { useCallback, useMemo } from 'react';
import styled, { keyframes } from 'styled-components';
import zillowLogo from '../../assets/zillow-logo.png';
import { ReviewStars } from '../../components/review-stars';
import { ActionDispatcher } from '../../hooks/use-action-pipe/hook';
import { isCompleteLenderBioReview, LenderBio as LenderBioType } from '../../rates/redux/selector';

export interface LenderBioProps extends LenderBioType {
  zillowBoxClassName?: string;
  isLenderDirect?: boolean;
  lenderInfo?: { lenderName?: string };
  dispatchAction?: ActionDispatcher;
}

const Bullet = styled.li`
  display: flex;
  align-items: flex-start;
  background-position: 100%;
  margin: 0;
  min-height: 20px;
  svg path {
    fill: ${CACTUS_100};
  }
  > span {
    margin-right: 8px;
  }
`;

const BulletContainer = styled.ul`
  display: flex;
  flex-direction: column;
  text-align: right;
  padding-left: 24px;
  ${({ theme }) => theme.breakpoints.down('md')} {
    padding-left: 0px;
    margin-bottom: 0px;
    margin-top: 32px;
  }
  > li:not(:last-child) {
    margin-bottom: 12px;
  }
`;

const Bullets = ({ bullets }: { bullets: string[] }) => (
  <BulletContainer>
    {bullets.map((text, i) => (
      <Bullet key={i}>
        <span style={{ height: '20px' }}>
          <CheckIcon />
        </span>
        <OwnUpSmallBody variant="body1" style={{ textAlign: 'left' }}>
          {text}
        </OwnUpSmallBody>
      </Bullet>
    ))}
  </BulletContainer>
);

const ZillowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  background-color: ${FOG_20};
  ${({ theme }) => theme.breakpoints.up('xs')} {
    padding: 20px;
    margin-top: 16px;
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 24px;
  }
  ${({ theme }) => theme.breakpoints.up('lg')} {
    padding-top: 24px;
    margin-top: 24px;
  }
`;

export const pulse = keyframes`
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
`;

export const LoadingBox = styled.div<{ height: string; width: string }>`
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  background-color: ${FOG_40};
  margin-right: 16px;
  animation: ${pulse} 1.5s ease-in-out 0.5s infinite;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ZillowLogoImage = styled.img`
  width: auto;
  height: 20px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 75px;
    height: 25px;
  }
`;

const CheckmarkGridItem = styled(OwnUpGridItem)`
  margin-top: 0px;
  ${({ theme }) => theme.breakpoints.up('lg')} {
    margin-top: 12px;
  }
`;

const RatingText = styled(OwnUpLargeSubheadlineRegular)`
  margin-right: 12px;
`;

export const ReviewLink = styled(OwnUpUnderlineLink)`
  margin-top: 4px;
  color: ${WALDEN_100};
  width: fit-content;
`;

export const NoReviewsText = styled(OwnUpDetailMedium)`
  margin-top: 2px;
`;

const ZillowBox = ({
  lenderReview,
  className,
  isLenderDirect = false,
  lenderName,
  dispatchAction
}: {
  lenderReview?: LenderBioType['lenderReview'];
  className?: string;
  isLenderDirect?: boolean;
  lenderName?: string;
  dispatchAction?: ActionDispatcher;
}) => {
  const content = useMemo(() => {
    if (!!lenderReview && isCompleteLenderBioReview(lenderReview)) {
      return (
        <Row>
          <RatingText variant="h1">{lenderReview.rating.toFixed(2)}</RatingText>
          <Column>
            <ReviewStars value={lenderReview.rating} />
            <ReviewLink link={lenderReview.profileURL} target="_new">
              <OwnUpDetailMedium variant="body1">
                {lenderReview.totalReviews} reviews
              </OwnUpDetailMedium>
            </ReviewLink>
          </Column>
        </Row>
      );
    } else if (!!lenderReview && lenderReview.loadingStatus === 'pending') {
      return (
        <Row>
          <LoadingBox height="36px" width="53px" />
          <Column>
            <LoadingBox height="16px" width="86px" />
            <LoadingBox height="14px" width="73px" style={{ marginTop: '4px' }} />
          </Column>
        </Row>
      );
    } else {
      return (
        <Column>
          <ReviewStars value={0} />
          <NoReviewsText variant="body1">
            No reviews{' '}
            {!!lenderReview && lenderReview.loadingStatus === 'lenderId_not_found'
              ? 'yet'
              : 'available'}
          </NoReviewsText>
        </Column>
      );
    }
  }, [lenderReview]);

  const zillowLink = 'http://www.zillow.com/';

  const sendZillowClickEvent = useCallback(() => {
    const eventName = isLenderDirect
      ? 'lender_direct_zillow_review_clicked_for'
      : 'rate_quotes_zillow_review_clicked_for';
    dispatchAction && dispatchAction(event(eventName, { lenderName }));
  }, [dispatchAction, lenderName, isLenderDirect]);

  return (
    <ZillowContainer className={className}>
      {content}
      <a
        href={zillowLink}
        target="_blank"
        // Avoids JS Attack vulnerability:
        //  https://www.jitbit.com/alexblog/256-targetblank---the-most-underestimated-vulnerability-ever/
        rel="noopener noreferrer"
        onClick={(e) => {
          e.preventDefault();
          sendZillowClickEvent();
          window.open(zillowLink); // Defaults to opening in a new window
        }}
      >
        <ZillowLogoImage src={zillowLogo} alt="Real Estate on Zillow" />
      </a>
    </ZillowContainer>
  );
};

export const LenderBio = ({
  lenderReview,
  highlights,
  zillowBoxClassName,
  lenderInfo: { lenderName } = {},
  isLenderDirect,
  dispatchAction
}: LenderBioProps) => (
  <OwnUpGridContainer variant="slim" spacing={0}>
    <OwnUpGridItem xs={12}>
      <OwnUpBody variant="body1">Reviews on Zillow</OwnUpBody>
    </OwnUpGridItem>
    <OwnUpGridItem xs={12} lg={7}>
      <ZillowBox
        lenderReview={lenderReview}
        className={zillowBoxClassName}
        dispatchAction={dispatchAction}
        lenderName={lenderName}
        isLenderDirect={isLenderDirect}
      />
    </OwnUpGridItem>
    <CheckmarkGridItem xs={12} lg={5}>
      <Bullets bullets={highlights} />
    </CheckmarkGridItem>
  </OwnUpGridContainer>
);
