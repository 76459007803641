import {
  ALOE_100,
  OwnUpBody,
  ownUpDarkTheme,
  OwnUpOverline,
  OwnUpThemeProvider,
  PINE_100
} from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';
import { TitleWrapper, Tooltip } from '../vertical-highlights';

const BreakEvenContainer = styled.div`
  background-color: ${ALOE_100};
  color: white;
  border-radius: 8px;
  padding: 24px;
`;

const BreakEvenHeader = styled.div`
  margin-bottom: 12px;
  display: flex;
  align-items: center;
`;

type BreakEventTextProps =
  | {
      variant: 'same';
    }
  | {
      variant: 'better';
      offer: 'A' | 'B';
    }
  | {
      variant: 'breakeven';
      timeSpan: string;
      offer: 'A' | 'B';
    };

const BreakEvenText = (props: BreakEventTextProps) => {
  switch (props.variant) {
    case 'same':
      return <OwnUpBody variant="body1">Offer A and B are the same.</OwnUpBody>;
    case 'better':
      return (
        <OwnUpBody variant="body1">Offer {props.offer} is the less expensive offer.</OwnUpBody>
      );
    case 'breakeven':
      return (
        <OwnUpBody variant="body1">
          If you plan to own your home for{' '}
          <em>
            {props.timeSpan}, Offer {props.offer}
          </em>{' '}
          is the less expensive choice.
        </OwnUpBody>
      );
  }
};

export interface BreakEvenProps {
  text: BreakEventTextProps;
  event: (_: string) => void;
}

export const BreakEven = ({ text, event }: BreakEvenProps) => (
  <OwnUpThemeProvider theme={ownUpDarkTheme}>
    <BreakEvenContainer>
      <BreakEvenHeader>
        <TitleWrapper backgroundColor={PINE_100}>
          <OwnUpOverline>BREAKEVEN</OwnUpOverline>
        </TitleWrapper>
        <Tooltip
          description={
            <span style={{ whiteSpace: 'pre-line' }}>
              Breakeven is the date when the monthly savings on your loan recoup, or &quot;break
              even&quot; with the costs you paid upfront. We calculate breakeven using three inputs:
              {`\n\n`}
              1. Lender fees{`\n\n`}
              2. The difference in your monthly interest payment{`\n\n`}
              3. How quickly you build equity{`\n\n`}
              Many mortgage companies don&apos;t consider equity when calculating Breakeven, but we
              believe it&apos;s an essential consideration as it helps you build your savings as a
              lower interest rate decreases your monthly payment AND increases the rate you build
              equity.
            </span>
          }
          onOpen={() => event('breakevenDefinition-comparisonShopping')}
        />
      </BreakEvenHeader>
      <BreakEvenText {...text} />
    </BreakEvenContainer>
  </OwnUpThemeProvider>
);
