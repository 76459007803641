import { ALOE_100, CHARCOAL_100, FOG_20, SAGE_100 } from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';
import { RateCard, RateCardProps } from '../rate-card';
import { transition } from './transition';

const SelectDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid ${ALOE_100};
  height: 24px;
  width: 24px;
  :hover {
    background-color: ${SAGE_100};
  }
`;

const SelectIcon = ({ selected }: { selected: boolean }) => (
  <SelectDiv>
    {selected && (
      <div
        style={{ height: '16px', width: '16px', backgroundColor: ALOE_100, borderRadius: '50%' }}
      />
    )}
  </SelectDiv>
);

const SelectableCardContainer = styled.button<{ open: boolean; selected: boolean }>`
  display: block;
  border: none;
  background-color: ${({ open }) => (open ? FOG_20 : 'white')};
  border-radius: ${({ open }) => (!open ? 0 : 8)}px;
  padding: ${({ open, selected }) => {
    if (selected && !open) {
      return '0';
    }
    return open ? '20px' : '24px';
  }};
  margin-bottom: 12px;
  width: 100%;
  color: ${CHARCOAL_100};
  ${transition('border-radius', 'padding', 'margin')};
  :hover {
    cursor: pointer;
    > div > div > span > div {
      background-color: ${SAGE_100};
    }
  }
`;

const DecorationContainer = styled.span<{ open: boolean }>`
  opacity: ${({ open }) => (open ? 1 : 0)};
  ${transition('opacity')};
`;

export type SelectableCardProps = RateCardProps & {
  key: string;
  open?: boolean;
  selected?: boolean;
  onClick: () => void;
};

export const SelectableCard = ({
  open = false,
  selected = false,
  onClick,
  key,
  ...props
}: SelectableCardProps) => (
  <SelectableCardContainer
    tabIndex={open ? undefined : -1}
    open={open}
    selected={selected}
    onClick={() => onClick()}
  >
    <RateCard
      {...props}
      decoration={
        <DecorationContainer open={open}>
          <SelectIcon selected={selected} />
        </DecorationContainer>
      }
    />
  </SelectableCardContainer>
);
