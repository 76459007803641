import { call, takeLatest } from 'redux-saga/effects';
import { fetchWithRetry, standardizeResponse } from '../../../modules/authentication';
import { loadResponseConverter } from '../../../modules/published-rates/type';
import { fetchRatesHelper } from '../../../redux/published-rates/saga';
import { dataLoadedAction, errorLoadingAction, LoadAction, loadActionType } from './actions';

export const fetchPublishedRates = async ({ id }: { id: string }) =>
  standardizeResponse(await fetch(`/rates/api/rates/${id}`), loadResponseConverter);

function* fetchRates(id: string) {
  return fetchRatesHelper(yield call(fetchPublishedRates, { id }), id);
}

function* loadingSaga({ payload: { id } }: LoadAction) {
  yield* fetchWithRetry(
    () => fetchRates(id),
    dataLoadedAction,
    () => errorLoadingAction(id)
  );
}

export function* saga() {
  yield takeLatest(loadActionType, loadingSaga);
}
