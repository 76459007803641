import {
  ALOE_100,
  MOSS_100,
  OwnUpIcon,
  OwnUpLargeBody,
  OwnUpLargeSubheadlineRegular,
  SLATE_100
} from '@rategravity/own-up-component-library';
import { StarFilledIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/star-filled';
import React from 'react';
import styled from 'styled-components';
import { FTUTemplate } from './template';

const Container = styled.div`
  background-color: ${MOSS_100};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 48px 64px;
  border-radius: 200px 200px 200px 4px;
  ${({ theme }) => theme.breakpoints.only('sm')} {
    padding: 40px 56px;
  }
  ${({ theme }) => theme.breakpoints.only('xs')} {
    padding: 24px 32px;
  }
`;

const RatingSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 32px;
  margin-right: 32px;
  border-right: 1px solid ${SLATE_100}80;
  ${({ theme }) => theme.breakpoints.only('xs')} {
    padding-right: 24px;
    margin-right: 24px;
  }
  > :first-child {
    margin-right: 16px;
  }
  > span:last-child > svg {
    height: 32px;
    width: 32px;
    ${({ theme }) => theme.breakpoints.down('sm')} {
      height: 24px;
      width: 24px;
    }
  }
`;

export const Page3 = () => (
  <FTUTemplate
    title="Your mortgage expert is standing by"
    body="Our highly rated team of Home Advisors is here to give you personalized advice and guidance for your unique scenario."
    content={
      <Container>
        <RatingSection>
          <OwnUpLargeSubheadlineRegular variant="h1">4.9</OwnUpLargeSubheadlineRegular>
          <OwnUpIcon icon={StarFilledIcon} color={ALOE_100} />
        </RatingSection>
        <OwnUpLargeBody variant="body1">Across Google, Zillow &amp; BBB</OwnUpLargeBody>
      </Container>
    }
  />
);
