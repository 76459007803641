import {
  FOG_100,
  IconLink,
  OwnUpBody,
  OwnUpBodyMedium,
  OwnUpOverline,
  OwnUpSmallBody,
  OwnUpSmallSubheadlineMedium,
  SLATE_100
} from '@rategravity/own-up-component-library';
import { CalendarRescheduleIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/calendar-reschedule';
import { EmailIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/email';
import { PhoneIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/phone';
import { OwnUpAdvisorImage } from '@rategravity/own-up-component-library-legacy';
import React from 'react';
import styled from 'styled-components';
import { AvailableAdvisors } from '../../../components/available-advisors/available-advisors';
import { YcbmButton, YcbmButtonProps } from '../../../components/schedule-call-button';

const Container = styled.div<{ padBottom?: boolean }>`
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding-left: 32px;
  }
  ${({ theme }) => theme.breakpoints.up('lg')} {
    padding-left: 40px;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    ${({ padBottom }) =>
      !!padBottom
        ? {
            paddingBottom: '48px',
            borderBottom: `1px solid ${FOG_100}`,
            marginBottom: '32px'
          }
        : 'margin-top: 48px'};
  }
`;

const Title = styled(OwnUpOverline)`
  color: ${SLATE_100};
  margin-bottom: 8px;
`;

const AdvisorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 16px 0 24px;
`;

const AdvisorImage = styled(OwnUpAdvisorImage)`
  height: 4.5rem;
  width: 4.5rem;
  border-radius: 50%;
`;

const TextWrapper = styled.div`
  margin-left: 20px;
  > :first-child {
    margin-bottom: 4px;
  }
`;

const StyledLink = styled(IconLink)`
  :not(:last-child) {
    margin-bottom: 8px;
  }
`;

interface AdvisorProps {
  name: {
    full: string;
  };
  phone: string;
  email: string;
  nmls: string;
  advisorId: string;
  scheduleCallAction: () => void;
}

export const Advisor = ({
  name,
  phone,
  email,
  nmls,
  advisorId,
  scheduleCallAction
}: AdvisorProps) => (
  <Container>
    <Title>YOUR ADVISOR</Title>
    <AdvisorWrapper>
      <AdvisorImage advisor={advisorId} alt="advisor image" />
      <TextWrapper>
        <OwnUpBodyMedium variant="body1">{name.full}</OwnUpBodyMedium>
        <OwnUpSmallBody variant="body2">NMLS: {nmls}</OwnUpSmallBody>
      </TextWrapper>
    </AdvisorWrapper>
    <StyledLink icon={PhoneIcon} link={`tel:+1-${phone}`} iconPlacement="left">
      {phone}
    </StyledLink>
    <StyledLink icon={EmailIcon} link={`mailto:${email}`} iconPlacement="left">
      {email}
    </StyledLink>
    <StyledLink
      icon={CalendarRescheduleIcon}
      link={`https://consult.ownup.com/schedule/general/${advisorId}`}
      iconPlacement="left"
      onClick={scheduleCallAction}
    >
      Schedule a call
    </StyledLink>
  </Container>
);

export const NoAdvisor = ({
  licensedAdvisors,
  beforeNavigation,
  ycbmLink
}: {
  licensedAdvisors: string[];
} & YcbmButtonProps) => (
  <Container padBottom>
    <Title>NEXT STEP</Title>
    <OwnUpSmallSubheadlineMedium variant="h6" style={{ marginBottom: '8px' }}>
      Unlock daily rate refreshes
    </OwnUpSmallSubheadlineMedium>
    <div style={{ marginBottom: '32px' }}>
      <OwnUpBody variant="body1">
        Confirm your profile to get up-to-date, lockable offers, on demand.
      </OwnUpBody>
    </div>
    <div style={{ marginBottom: '40px' }}>
      <YcbmButton beforeNavigation={beforeNavigation} ycbmLink={ycbmLink} />
    </div>
    <AvailableAdvisors advisors={licensedAdvisors} columnBreakpoint="xl" />
  </Container>
);
