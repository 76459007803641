import { Func1 } from './func';

/**
 * Turns a transform function into a pre-filter that transforms the input but leaves the output as-is
 */
export const pre = <TInput, TOut, TArgs extends any[], T = void>(
  func: Func1<TInput, TOut, TArgs, T>
) =>
  function <TResult>(
    this: T,
    next: Func1<TOut, TResult, TArgs>,
    input: TInput,
    ...args: TArgs
  ): TResult {
    return next((func as Func1<TInput, TOut, any[], T>).call(this, input, ...args), ...args);
  };

/**
 * Turns a transform function into a post-filter that transforms the output but leaves the input as-is
 * @param func
 */
export const post = <TResult, TOut, TArgs extends any[], T = void>(
  func: Func1<TResult, TOut, TArgs, T>
) =>
  function <TInput>(
    this: T,
    next: Func1<TInput, TResult, TArgs>,
    input: TInput,
    ...args: TArgs
  ): TOut {
    return (func as Func1<TResult, TOut, any[], T>).call(this, next(input, ...args), ...args);
  };
