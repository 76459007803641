import { OwnUpBodyMedium, OwnUpSmallBodyMedium } from '@rategravity/own-up-component-library';
import {
  GRAPHIK_MEDIUM,
  GRAPHIK_REGULAR,
  OwnUpText
} from '@rategravity/own-up-component-library-legacy';
import styled from 'styled-components';
import { colors } from '../../../modules/colors';

const getTabTextStyleLegacy = (isActive: boolean) => ({
  color: colors.CHARCOAL + (isActive ? '' : '99'), // charcoal 60%
  fontFamily: isActive ? GRAPHIK_MEDIUM.fontFamily : GRAPHIK_REGULAR.fontFamily
});

export const TabTextLegacy = styled(OwnUpText)<{ isActive: boolean }>`
  font-size: 14px;
  ${({ isActive }) => getTabTextStyleLegacy(isActive)}
`;

export const getActiveTabTextStyle = (isActive: boolean) => `
  color: ${isActive ? colors.CHARCOAL_100 : colors.SLATE};
`;

export const TabText = styled(OwnUpBodyMedium)<{ isActive: boolean }>`
  ${({ isActive }) => getActiveTabTextStyle(isActive)}
`;

export const SmallTabText = styled(OwnUpSmallBodyMedium)<{ isActive: boolean }>`
  ${({ isActive }) => getActiveTabTextStyle(isActive)}
`;
