import { colors } from '@rategravity/own-up-component-library-legacy';
import React, { useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { NotificationState } from '../../redux/notifications/state';
import { ApplicationState } from '../../redux/state';

const NoticeContainer = styled.div`
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 20;
  left: 0;
  bottom: 20px;
  transform: translate(0%);
  transition: transform 1000ms ease-in-out;
  @media (max-width: 720px) {
    bottom: 60px;
  }
`;

type NoticeType = Pick<NotificationState, 'color'>;

const StyledNotice = styled.div<NoticeType>`
  display: inline-block;
  padding: 10px 20px 10px 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  background-color: ${({ color }) => (color ? colors[color] : '#323232')};
  color: #ffffff;
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Notifications = ({ messages = [] }: { messages: NotificationState[] }) => {
  const [startIndex, dispatch] = useReducer(
    () => messages.findIndex((n) => Date.now() < n.timestamp + n.delay),
    0
  );
  useEffect(() => {
    const timer = setInterval(() => dispatch(), 100);
    return () => clearInterval(timer);
  }, [dispatch]);
  if (startIndex === -1) {
    return null;
  } else {
    return (
      <MessageContainer>
        {messages
          .filter((_, i) => i >= startIndex)
          .map(({ message, color }, i) => (
            <StyledNotice key={i} color={color}>
              {message}
            </StyledNotice>
          ))}
      </MessageContainer>
    );
  }
};

export const NotificationCenter = () => {
  const allNotifications = useSelector(({ notifications }: ApplicationState) => notifications);
  const [active, setActive] = useState([] as NotificationState[]);
  useEffect(
    () => setActive(allNotifications.filter((n) => Date.now() - n.timestamp < 2000)),
    [allNotifications, setActive]
  );
  return (
    <NoticeContainer>
      <Notifications messages={active} />
    </NoticeContainer>
  );
};
