import {
  FOG_40,
  OwnUpDropdown,
  OwnUpMenuItem,
  OwnUpSmallBody,
  OwnUpSmallBodyMedium
} from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div<{ marginTop?: string }>`
  margin-bottom: 24px;
  margin-top: ${({ marginTop }) => marginTop || '0'};
  > div > .MuiInputBase-root {
    margin-bottom: 0;
  }
`;

export const ProductTypeDropdown = ({
  value,
  setValue,
  options
}: {
  value: string;
  setValue: (newValue: string) => void;
  options: string[];
}) => (
  <Container>
    <OwnUpDropdown
      label="Product type"
      labelPosition="outer"
      value={value}
      onChange={(e) => setValue(e.target.value as string)}
    >
      {options.map((option, i) => (
        <OwnUpMenuItem key={`dd-${i}`} value={option}>
          {option}
        </OwnUpMenuItem>
      ))}
    </OwnUpDropdown>
  </Container>
);

const Pill = styled.div`
  background-color: ${FOG_40};
  border-radius: 20px;
  padding: 8px 16px;
  display: inline-flex;
  flex-direction: row;
  :not(:last-child) {
    margin: 0 8px 8px 0;
  }
  > :first-child {
    margin-right: 8px;
  }
`;
export const PermutationAttributes = ({
  sectionTitles,
  marginTop
}: {
  sectionTitles: { displayField: string; displayValue: string }[];
  marginTop?: string;
}) => (
  <Container marginTop={marginTop}>
    {sectionTitles.map(({ displayField, displayValue }, i) => (
      <Pill key={`attribute-${i}`}>
        <OwnUpSmallBody variant="body1">{displayField}</OwnUpSmallBody>
        <OwnUpSmallBodyMedium variant="body2">{displayValue}</OwnUpSmallBodyMedium>
      </Pill>
    ))}
  </Container>
);
