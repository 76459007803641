import { event } from '@rategravity/frontend/modules/user-actions';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useActionPipe } from '../../../hooks/use-action-pipe';
import { isUserOnboarded } from '../../../redux/account-attributes/selector';
import { updateAccountAttributes } from '../../../redux/account-attributes/slice';
import { FTUBanner } from '.';
import { Page1 } from './page-1';
import { Page2 } from './page-2';
import { Page3 } from './page-3';
import { Page4 } from './page-4';

export const FTUBannerView = () => {
  const dispatch = useDispatch();
  const actionPipe = useActionPipe();
  const onboarded = useRef(useSelector(isUserOnboarded));
  const showFTUOnboarding = window.location.pathname.includes('account');

  useEffect(() => {
    if (showFTUOnboarding && !onboarded.current) {
      dispatch(updateAccountAttributes({ data: { userOnboarded: true } }));
      actionPipe(event('FTUOnboarding-firstTime'));
    }
  }, [actionPipe, dispatch, onboarded, showFTUOnboarding]);

  return showFTUOnboarding ? (
    <FTUBanner isOpen={!onboarded.current}>
      <Page1 />
      <Page2 />
      <Page3 />
      <Page4 />
    </FTUBanner>
  ) : null;
};
