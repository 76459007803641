import {
  APRICOT_40,
  FOG_40,
  MANGO_40,
  MINT_100,
  MOSS_100,
  MYSTIC_100,
  MYSTIC_40,
  OwnUpBody,
  OwnUpLargeBodyMedium,
  OwnUpSmallBody,
  OwnUpSmallBodyMedium
} from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';
import LightBulbIcon from '../../../assets/light-bulb.svg';
import { FTUTemplate } from './template';

const OfferContainer = styled.div`
  margin: 0 20px 24px;
`;

const OfferTop = styled.div<{ color: string }>`
  border-radius: 8px 8px 0 0;
  background-color: ${({ color }) => color};
  padding: 12px 24px;
`;

const OfferBottom = styled.div<{ primary: string; secondary: string }>`
  border-radius: 0 0 8px 8px;
  border: ${({ primary }) => `1px solid ${primary}`};
  background-color: ${({ secondary }) => secondary};
  padding: 16px 24px 4px;
`;

const Bar = styled.div<{ color: string }>`
  height: 8px;
  width: 48px;
  background-color: ${({ color }) => color};
  margin-bottom: 12px;
  border-radius: 2px;
`;

const Offer = ({
  title,
  primary,
  secondary
}: {
  title: string;
  primary: string;
  secondary: string;
}) => (
  <OfferContainer>
    <OfferTop color={primary}>
      <OwnUpSmallBodyMedium variant="body1">{title}</OwnUpSmallBodyMedium>
    </OfferTop>
    <OfferBottom primary={primary} secondary={secondary}>
      <Bar color={primary} />
      <Bar color={primary} />
    </OfferBottom>
  </OfferContainer>
);

const TextContainer = styled.div`
  background-color: ${FOG_40};
  padding: 16px;
  border-radius: 4px;
  height: fit-content;
  display: flex;
  position: relative;
`;

const TextCarrot = styled.div<{ alignment: 'left' | 'right' }>`
  background-color: ${FOG_40};
  height: 16px;
  width: 16px;
  transform: rotate(45deg);
  position: absolute;
  ${({ alignment }) => (alignment === 'left' ? 'left: -6px' : 'right: -6px')};
`;

const Text = ({ text, alignment }: { text: JSX.Element; alignment: 'left' | 'right' }) => (
  <TextContainer>
    {alignment === 'left' && <TextCarrot alignment={alignment} />}
    {text}
    {alignment === 'right' && <TextCarrot alignment={alignment} />}
  </TextContainer>
);

const Row = styled.div<{ alignRight?: boolean; centerVertical?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ alignRight }) => (!!alignRight ? 'flex-end' : 'flex-start')};
  align-items: ${({ centerVertical }) => (!!centerVertical ? 'center' : 'flex-start')};
`;

const OtherOffer = styled.div`
  border-radius: 8px;
  background-color: ${MOSS_100};
  padding: 32px;
  margin-top: 24px;
`;

const Divider = styled.div`
  height: 1px;
  background-color: ${MINT_100};
  margin: 24px 0;
  width: 100%;
`;

export const Page1 = () => (
  <FTUTemplate
    title="Compare offers, side by side"
    body="Compare offers based on total costs, so you can compare apples to apples and make the right
    choice for your goals."
    content={
      <>
        <Row alignRight>
          <Offer title="Offer A" primary={MYSTIC_100} secondary={MYSTIC_40} />
          <Text
            text={
              <OwnUpSmallBody variant="body1">
                If you own your home for more than 4 years,{' '}
                <OwnUpSmallBodyMedium variant="body1" display="inline">
                  Offer A is less expensive
                </OwnUpSmallBodyMedium>
              </OwnUpSmallBody>
            }
            alignment="left"
          />
        </Row>
        <Row>
          <Text
            text={
              <OwnUpSmallBody variant="body1">
                <OwnUpSmallBodyMedium variant="body1" display="inline">
                  Offer B saves you $1,123
                </OwnUpSmallBodyMedium>{' '}
                at closing
              </OwnUpSmallBody>
            }
            alignment="right"
          />
          <Offer title="Offer B" primary={APRICOT_40} secondary={MANGO_40} />
        </Row>
        <OtherOffer>
          <Row centerVertical>
            <img src={LightBulbIcon} alt="" style={{ height: '20px', marginRight: '12px' }} />
            <OwnUpLargeBodyMedium variant="body1">
              Have an offer from another lender?
            </OwnUpLargeBodyMedium>
          </Row>
          <Divider />
          <OwnUpBody variant="body1">
            We&apos;ll help you compare that to our best, and even give you negotiating advice!
          </OwnUpBody>
        </OtherOffer>
      </>
    }
  />
);
