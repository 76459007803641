import { currency } from '@rategravity/frontend/modules/numbers';
import {
  OwnUpFillButtonSecondary,
  OwnUpGridContainer,
  OwnUpGridItem,
  ownUpLightTheme,
  OwnUpThemeProvider
} from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';
import { isNonZeroish } from '../../modules/non-zeroish';
import { LockComponent, LockStatusProps } from './lock-status';
import { RateDetails } from './scenario-rate-details';
import { ScenarioDetails } from './scenario-rate-details';

/**
 * If the value isn't a valid number this method will return []. Otherwise it will format the value.
 * This is useful for filtering out values we don't want to show
 * @param DisplayValue: { name, value }
 */
const getDisplay = ({ name, value }: { name: string; value: any }) =>
  isNonZeroish(value) ? [{ name, value: currency(value, 0) }] : [];

const OuterGrid = styled(OwnUpGridContainer)`
  padding: 32px 0;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: 40px 0;
  }
  ${({ theme }) => theme.breakpoints.up('lg')} {
    padding: 48px 0;
  }
`;

const SummaryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  ${({ theme }) => theme.breakpoints.down('md')} {
    flex-direction: column;
  }
`;

const CTAContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 210px;
  width: max-content;
  > :first-child {
    margin-bottom: 12px;
  }
  ${({ theme }) => theme.breakpoints.only('xs')} {
    max-width: unset;
    width: unset;
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-left: 32px;
  }
  ${({ theme }) => theme.breakpoints.up('lg')} {
    margin-left: 40px;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: 40px;
  }
`;

export interface OfferSummaryProps {
  apr: number;
  cashOutValue: number;
  downPayment: number | null;
  existingLoanSize: number | null;
  upFrontInsuranceFee?: { label: string; value: number };
  loanType: string;
  purchasePrice: number | null;
  rate: number;
  rateLock: number;
  rolledIn: number;
  closingFinanced: number;
  compareProps: {
    showComparisonShopping: boolean;
    onCompareOffer: () => void;
  };
  lockStatusProps: { showLock: false } | ({ showLock: true } & LockStatusProps);
}

export const OfferSummary = ({
  apr,
  cashOutValue = 0,
  downPayment,
  existingLoanSize,
  upFrontInsuranceFee,
  loanType,
  purchasePrice,
  rate,
  rateLock,
  rolledIn,
  closingFinanced = 0,
  compareProps: { showComparisonShopping, onCompareOffer },
  lockStatusProps
}: OfferSummaryProps) => (
  <OwnUpThemeProvider theme={ownUpLightTheme}>
    <OuterGrid variant="slim" spacing={0}>
      <OwnUpGridItem xs={12} md={7} lg={8}>
        <SummaryWrapper>
          <OwnUpGridContainer variant="slim" spacing={0}>
            <OwnUpGridItem xs={12} lg={11}>
              <RateDetails {...{ rate, apr }} />
            </OwnUpGridItem>
          </OwnUpGridContainer>
          <OwnUpGridContainer variant="slim" spacing={0}>
            <OwnUpGridItem xs={12} lg={11}>
              <ScenarioDetails
                values={[
                  {
                    name: 'Product',
                    value: loanType
                  },
                  {
                    name: 'Lock days',
                    value: `${rateLock} days`
                  },
                  ...getDisplay({
                    name: upFrontInsuranceFee?.label || '',
                    value: upFrontInsuranceFee?.value || 0
                  }),
                  ...getDisplay({
                    name: 'Closing financed',
                    value: closingFinanced
                  }),
                  ...getDisplay({
                    name: 'Down payment',
                    value: downPayment
                  }),
                  ...getDisplay({
                    name: 'Cash out',
                    value: cashOutValue
                  }),
                  ...(purchasePrice && downPayment
                    ? getDisplay({
                        name: 'Loan amount',
                        value:
                          purchasePrice -
                          downPayment +
                          (upFrontInsuranceFee?.value || 0) +
                          closingFinanced
                      })
                    : []),
                  ...(existingLoanSize
                    ? getDisplay({
                        name: 'New loan',
                        value:
                          existingLoanSize +
                          cashOutValue +
                          (upFrontInsuranceFee?.value || 0) +
                          rolledIn +
                          closingFinanced
                      })
                    : []),
                  ...getDisplay({
                    name: 'Rolled in',
                    value: rolledIn
                  })
                ].filter(({ value }) => !!value)}
              />
            </OwnUpGridItem>
          </OwnUpGridContainer>
        </SummaryWrapper>
      </OwnUpGridItem>
      <OwnUpGridItem xs={12} md={5} lg={4}>
        <CTAContainer>
          {lockStatusProps.showLock && <LockComponent {...lockStatusProps} />}
          {showComparisonShopping && (
            <OwnUpFillButtonSecondary onClick={onCompareOffer}>Compare</OwnUpFillButtonSecondary>
          )}
        </CTAContainer>
      </OwnUpGridItem>
    </OuterGrid>
  </OwnUpThemeProvider>
);
