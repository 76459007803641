import { compare, lowest, on } from '@rategravity/core-lib/func';
import { event } from '@rategravity/frontend/modules/user-actions';
import { OwnUpThemeProvider, ownUpWhiteTheme } from '@rategravity/own-up-component-library';
import { useAdvisors } from '@rategravity/own-up-component-library-legacy';
import { push, replace } from 'connected-react-router';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { useActionPipe } from '../../../hooks/use-action-pipe';
import { useConsultLink } from '../../../hooks/use-consult-link';
import { findLicensedAdvisors } from '../../../modules/find-licensed-advisors';
import { consultStateSelector } from '../../../redux/selector/consult-state-selector';
import { resolveFeeValue } from '../../modules/fee-helpers';
import {
  displayPermutationsSelector,
  licensedStateSelector,
  offerASelector,
  offerBSelector,
  rateQuoteSelector
} from '../../redux/selector';
import { Offer } from '../../redux/state';
import { ComparePage, ComparePageProps } from '.';
import { analysisSelector } from './analysis-selector';

interface CompareConnectProps {
  rootPath: string;
}

const offerToRateCardDisplay = ({
  id,
  rate,
  apr,
  lenderName,
  squareLogo,
  pAndI,
  interestOnly,
  allFees
}: Offer) => ({
  id,
  rate,
  apr,
  lenderName,
  squareLogo,
  points: allFees
    .filter(({ type }) => type === 'points')
    .map(({ value }) => resolveFeeValue(value))
    .reduce((l, r) => l + r),
  lenderCredit: allFees
    .filter(({ type }) => type === 'lenderCredit')
    .map(({ value }) => resolveFeeValue(value))
    .reduce((l, r) => l + r),
  pAndI,
  lenderFees: allFees
    .filter(({ group }) => group === 'LenderFee')
    .reduce((p, n) => p + resolveFeeValue(n.value), 0),
  interestOnly
});

const rateCardComparator = compare<ReturnType<typeof offerToRateCardDisplay>>(
  on(lowest, ({ rate }) => rate),
  on(lowest, ({ lenderFees }) => lenderFees)
);

const offerPermutationsSelector = createSelector(displayPermutationsSelector, (offerPermutations) =>
  offerPermutations
    .filter(({ name }) => !!name.match(/fixed/i))
    .reduce(
      (
        permutations: ComparePageProps['offerPermutations'],
        { name, loanType, attributes, offers }
      ) => {
        const label = `${name}${loanType ? ` ${loanType}` : ''}`;
        if (!permutations[label]) {
          permutations[label] = [];
        }
        permutations[label].push({
          sectionTitles: attributes,
          rates: offers.map((o) => offerToRateCardDisplay(o)).sort(rateCardComparator)
        });
        return permutations;
      },
      {}
    )
);

const comparePageSelector = createSelector(
  offerASelector,
  offerBSelector,
  offerPermutationsSelector,
  rateQuoteSelector,
  (
    offerA,
    offerB,
    offerPermutations,
    rq
  ): Pick<ComparePageProps, 'sections' | 'offerPermutations' | 'propertyValue'> => {
    const offerAId = offerA.map<string | null>((o) => o.id).orElse(() => null);
    const offerBId = offerB.map<string | null>((o) => o.id).orElse(() => null);
    return {
      sections: [
        {
          title: 'Offer A',
          selectedId: offerAId
        },
        {
          title: 'Offer B',
          selectedId: offerBId
        }
      ],
      offerPermutations,
      propertyValue: rq
        .map((quote) => quote.purchasePrice || quote.propertyValue)
        .orElse(() => null)
    };
  }
);

const pathModificationSelector = createSelector(
  offerASelector,
  offerBSelector,
  (offerA, offerB) => (title: string, id: string) => {
    if (title === 'Offer A') {
      return [id, [...offerB.map(({ id: x }) => x)]].join('/');
    }
    return [offerA.map(({ id: x }) => x).orElse(() => 'none'), id].join('/');
  }
);

export const CompareConnect = ({ rootPath }: CompareConnectProps) => {
  const dispatch = useDispatch();
  const dispatchAction = useActionPipe();
  const props = useSelector(comparePageSelector);
  const pathModification = useSelector(pathModificationSelector);
  const onClose = () => dispatch(replace(rootPath));
  const onSelect = useCallback(
    (title: string, id: string) => {
      dispatch(push(`${rootPath}/compare/${pathModification(title, id)}`));
    },
    [dispatch, rootPath, pathModification]
  );
  const allAdvisors = useAdvisors();
  const licensedState = useSelector(licensedStateSelector);
  const analysis = useSelector(analysisSelector);
  const state = useSelector(consultStateSelector);
  const licensedAdvisors = findLicensedAdvisors(allAdvisors, state);
  const ycbmLink = useConsultLink();
  const eventCreator = (eventName: string, properties?: Record<string, unknown>) =>
    dispatchAction(event(eventName, !!properties ? properties : {}));

  return (
    <OwnUpThemeProvider theme={ownUpWhiteTheme}>
      <ComparePage
        onClose={onClose}
        onSelect={onSelect}
        ycbmLink={ycbmLink}
        licensedAdvisors={licensedAdvisors}
        event={eventCreator}
        analysis={analysis}
        licensedState={licensedState}
        {...props}
      />
    </OwnUpThemeProvider>
  );
};
