import React from 'react';
import * as colors from '../../../colors';
import { useRandomId } from '../../../hooks/use-random-id';

export const EducationIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1545:46673)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2273 2.61599C10.0846 2.54312 9.91553 2.54312 9.77276 2.61599L0.412848 7.39294C0.0418543 7.58228 0.0514464 8.11565 0.429008 8.29153L4.25003 10.0715V14.0756C4.25003 14.4463 4.32417 14.9064 4.62303 15.3073C5.23011 16.1217 6.76192 17.5 10 17.5C13.2381 17.5 14.77 16.1217 15.377 15.3073C15.6759 14.9064 15.75 14.4463 15.75 14.0756V10.0715L18.36 8.8557V14.5C18.36 14.9142 18.6958 15.25 19.11 15.25C19.5242 15.25 19.86 14.9142 19.86 14.5V7.86C19.8678 7.6769 19.7772 7.48985 19.5873 7.39294L10.2273 2.61599ZM17.0798 7.79728L10.0001 4.18405L2.92031 7.79728L10.0001 11.0952L17.0798 7.79728ZM5.75003 14.0756V10.7702L9.57779 12.5533C9.84549 12.678 10.1546 12.678 10.4223 12.5533L14.25 10.7702V14.0756C14.25 14.2646 14.21 14.363 14.1744 14.4108C13.8344 14.8669 12.7383 16 10 16C7.26174 16 6.16565 14.8669 5.82567 14.4108C5.79005 14.363 5.75003 14.2646 5.75003 14.0756Z"
        fill={colors.CHARCOAL_100}
      />
    </g>
    <defs>
      <clipPath id={useRandomId('clip')}>
        <rect width="20" height="15" transform="translate(0 2.5)" />
      </clipPath>
    </defs>
  </svg>
);
