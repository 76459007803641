import React from 'react';
import { BreakEven, BreakEvenProps } from './break-even';
import { GenericHighlight, GenericHighlightProps } from './generic-highlight';

export interface AnalysisProps {
  breakEven: BreakEvenProps['text'];
  atClosing: Omit<GenericHighlightProps, 'title' | 'timeFrame'>;
  monthly: Omit<GenericHighlightProps, 'title' | 'timeFrame'>;
  lifetime: Omit<GenericHighlightProps, 'title'>;
}
export interface AnalysisSectionProps extends AnalysisProps {
  event: (_: string) => void;
}

export const AnalysisSection = ({
  breakEven,
  atClosing,
  monthly,
  lifetime,
  event
}: AnalysisSectionProps) => (
  <React.Fragment>
    <BreakEven text={breakEven} event={event} />
    <GenericHighlight
      title="LENDER FEES"
      helpText="Lender Fees are a subset of Loan Costs that include Processing, Application, Underwriting, and Origination fees as well as Points and Lender Credits. All other fees in an offer are from third parties, so Lender Fees tell you how much a lender is really charging you for the loan."
      timeFrame="At closing"
      {...atClosing}
    />
    <GenericHighlight title="MONTHLY PAYMENTS" timeFrame="Every month" {...monthly} />
    <GenericHighlight title="LIFETIME COST" {...lifetime} />
  </React.Fragment>
);
