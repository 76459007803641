import React, { Fragment, useMemo, useRef, useState } from 'react';
import { RateListProps } from '..';
import { BottomOffsetElement, HorizontalScrollableMenu } from '../../scrollable-menus/horizontal';
import { RateListPermutation } from '../permutation';
import { ScrollTarget } from './scroll-target';

//Menu Items are set to active when they are scrolled past.
//This hook prevents Menu Items being set to active when they are being scrolled past as a result of selecting a Menu Item.
const useMenuScrollController = (setActiveItem: React.Dispatch<string>) => {
  const scrollTarget = useRef<string | null>(null);
  return useMemo(
    () => [
      // onItemSelected: set scrollTarget to key, and set corresponding menuItem to active
      (key: string) => {
        scrollTarget.current = key;
        setActiveItem(key);
      },
      //onVisible: if scrollTarget matches key, clear scrollTarget. If scrollTarget is null, set the corresponding menuItem to active
      (key: string) => {
        if (scrollTarget.current === key) {
          scrollTarget.current = null;
        }
        if (scrollTarget.current === null) {
          setActiveItem(key);
        }
      }
    ],
    [scrollTarget, setActiveItem]
  );
};

export const MobilePermutationsMenu = ({
  onDetailsClick,
  onLockClick,
  onCompareClick,
  offerPermutations,
  showComparisonShopping
}: RateListProps & { showComparisonShopping: boolean }) => {
  const [activeItem, setActiveItem] = useState<string | null>(null);
  const menuItems = useMemo(
    () =>
      Array.from(new Set(offerPermutations.map((p) => p.name))).map((name) => {
        const offerPermutationsByName = offerPermutations.filter((o) => o.name === name);
        return {
          key: name,
          text: `${name} (${offerPermutationsByName.reduce(
            (acc, { offers }) => acc + offers.length,
            0
          )})`,
          permutations: offerPermutationsByName
        };
      }),
    [offerPermutations]
  );

  const menuRef = useRef<BottomOffsetElement | null>(null);

  const [onItemSelected, onVisible] = useMenuScrollController(setActiveItem);

  return (
    <Fragment>
      <HorizontalScrollableMenu
        ref={menuRef}
        items={menuItems.map(({ key, text }, i) => ({
          key,
          text,
          active: activeItem !== null ? activeItem === key : i === 0
        }))}
        onItemSelected={onItemSelected}
      />
      {menuItems.map(({ key, permutations }) => {
        return (
          <ScrollTarget
            key={key}
            onVisible={onVisible}
            name={key}
            menuRef={menuRef}
            active={key === activeItem}
          >
            {permutations.map((permutation, i) => (
              <RateListPermutation
                key={i}
                onDetailsClick={onDetailsClick}
                onLockClick={onLockClick}
                onCompareClick={onCompareClick}
                showComparisonShopping={showComparisonShopping}
                {...permutation}
              />
            ))}
          </ScrollTarget>
        );
      })}
    </Fragment>
  );
};
