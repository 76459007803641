import { event } from '@rategravity/frontend/modules/user-actions';
import { push } from 'connected-react-router';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { useActionPipe } from '../../../hooks/use-action-pipe';
import { resolveFeeValue } from '../../modules/fee-helpers';
import { getLockStatus } from '../../modules/get-lock-status';
import { displayPermutationsSelector, rateQuoteSelector } from '../../redux/selector';
import { Offer } from '../../redux/state';
import { RateList } from '.';
import { OfferDisplay } from './permutation';

const offerToOfferDisplay = (
  offer: Offer,
  showLockStatus: boolean,
  lockedOfferId?: string | null,
  expirationTime?: number
): OfferDisplay => ({
  apr: offer.apr,
  cashOutValue: offer.cashOutValue || 0,
  downPayment: offer.downPayment || 0,
  lenderFees: offer.allFees
    .filter(({ group }) => group === 'LenderFee')
    .reduce((p, n) => p + resolveFeeValue(n.value), 0),
  loanType: offer.loanType,
  logo: offer.logo,
  squareLogo: offer.squareLogo,
  offerId: offer.id,
  pAndI: offer.pAndI,
  rate: offer.rate,
  recommend: offer.recommend,
  lenderName: offer.lenderName,
  rateLock: offer.rateLock,
  points: offer.allFees
    .filter(({ type }) => type === 'points')
    .reduce((acc, cur) => acc + resolveFeeValue(cur.value), 0),
  lenderCredit: offer.allFees
    .filter(({ type }) => type === 'lenderCredit')
    .reduce((acc, cur) => acc + resolveFeeValue(cur.value), 0),
  fixedLenderFees: offer.allFees
    .filter(
      ({ type, group }) => group === 'LenderFee' && type !== 'points' && type !== 'lenderCredit'
    )
    .reduce((p, n) => p + resolveFeeValue(n.value), 0),
  monthlyMI: offer.monthlyMI,
  interestOnly: offer.interestOnly,
  ...(showLockStatus && lockedOfferId !== undefined
    ? {
        lockStatus: getLockStatus({
          currentOfferId: offer.id,
          lockedOfferId,
          expirationTime
        })
      }
    : {})
});

const permutationSelector = createSelector(
  rateQuoteSelector,
  displayPermutationsSelector,
  (rate, permutations) =>
    rate
      .map(({ lockedOfferId, sampleRate, isLicensedState, expirationTime }) =>
        permutations.map((permutation) => ({
          ...permutation,
          offers: permutation.offers.map((offer) =>
            offerToOfferDisplay(
              offer,
              !sampleRate && isLicensedState,
              lockedOfferId,
              expirationTime
            )
          )
        }))
      )
      .orElse(() => [])
);

export const RateListRedux = ({
  rootPath,
  id,
  eventProperties
}: {
  rootPath: string;
  id: string;
  eventProperties: { advisor: string; scenario: string };
}) => {
  const dispatch = useDispatch();
  const dispatchAction = useActionPipe();
  const permutations = useSelector(permutationSelector);

  const lockOfferEvent = () =>
    event('Lock', {
      ...eventProperties,
      url: window.location.href
    });

  const onDetailsClick = (offerId: string) =>
    dispatch(
      push({
        pathname: `${rootPath}/details/${offerId}`,
        state: {
          fromId: id
        }
      })
    );

  const onLockClick = (offerId: string) => {
    dispatchAction(lockOfferEvent());
    return dispatch(
      push({
        pathname: `${rootPath}/lock/${offerId}`,
        state: {
          fromId: id
        }
      })
    );
  };

  const onCompareClick = (offerId: string) => {
    dispatchAction(event('clickedCTA-comparisonShopping'));
    return dispatch(
      push({
        pathname: `${rootPath}/compare/${offerId}`,
        state: {
          fromId: id
        }
      })
    );
  };

  return (
    <React.Fragment>
      <RateList
        {...{
          onDetailsClick,
          onLockClick,
          onCompareClick,
          offerPermutations: permutations
        }}
      />
    </React.Fragment>
  );
};
