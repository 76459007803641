import {
  currency as formatCurrency,
  rate as formatRate
} from '@rategravity/frontend/modules/numbers';
import { OwnUpTooltip } from '@rategravity/own-up-component-library';
import {
  OwnUpButton,
  OwnUpIcon,
  OwnUpTableCell,
  OwnUpTableRow,
  OwnUpText,
  SortedBy
} from '@rategravity/own-up-component-library-legacy';
import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from '../../../modules/colors';
import { LockStatus } from '../../modules/get-lock-status';
import { MonthlyMI } from '../../redux/state';

export interface DesktopRateRowProps {
  rate: number;
  apr: number;
  lenderName: string;
  logo: string;
  points: number;
  lenderCredit: number;
  pAndI: number;
  lenderFees: number;
  fixedLenderFees: number;
  rateLock: number;
  monthlyMI: MonthlyMI;
  sorted: SortedBy<
    Omit<
      DesktopRateRowProps,
      | 'sorted'
      | 'showMortgageInsurance'
      | 'showLC'
      | 'showPoints'
      | 'showLockRequested'
      | 'showLockButton'
      | 'onLock'
      | 'onViewCostBreakdown'
    >
  >;
  showMortgageInsurance: boolean;
  showLC: boolean;
  showPoints: boolean;
  lockStatus?: LockStatus;
  onLock: () => void;
  onCompare: () => void;
  onViewCostBreakdown: () => void;
  showCompareButton: boolean;
}

const ButtonContainerLayout = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  padding-right: 9px;
`;

const detailsButtonActive = css`
  transition:
    background-color 0.15s,
    color 0.15s;
  color: ${colors.WHITE};
  & svg > * {
    transition: stroke 0.15s;
    stroke: ${colors.WHITE};
  }
`;

const TableButton = styled(OwnUpButton).attrs({ variant: 'square' })`
  border-radius: 8px;
  background-color: ${colors.BACKGROUND_CHART};
  color: ${colors.PRIMARY};
  box-sizing: border-box;
`;

const DetailsButton = styled(TableButton)`
  padding-left: 20px;
  padding-right: 20px;
  &:hover,
  &:focus {
    ${detailsButtonActive};
    background-color: ${colors.PRIMARY} !important;
  }
`;

const LockButton = styled(TableButton)`
  margin-right: 10px;
  &:not([disabled]):hover,
  &:not([disabled]):focus {
    transition:
      background-color 0.15s,
      color 0.15s;
    background-color: #b9ddcc !important;
    color: ${colors.WHITE};
    & svg > * {
      transition: stroke 0.15s;
      stroke: ${colors.WHITE};
    }
  }
  &[disabled] {
    background-color: ${colors.BACKGROUND};
    cursor: not-allowed;
    & svg > * {
      stroke: ${colors.GREY_ALT};
    }
  }
`;

const CompareButton = styled(TableButton)`
  margin-left: 10px;
  border: 2px solid ${colors.MINT};
  background-color: transparent;
  line-height: 24px;
  &:hover {
    background-color: ${colors.MINT};
  }
`;

const RequestedLock = styled.div`
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  display: inline-flex;
  border-radius: 8px;
  height: 49px;
  background-color: ${colors.WARN_ALT};
  padding: 0.3em 22px;
  margin-right: 10px;
`;

const TableRow = styled(OwnUpTableRow)`
  height: 84px;
  &:hover {
    transition: background-color 0.5s;
  }
  &:hover ${DetailsButton} {
    ${detailsButtonActive};
    background-color: ${colors.PRIMARY_ALT};
  }
  &:hover ${LockButton}:not([disabled]) {
    transition: background-color 0.15s;
    background-color: ${colors.SAGE};
  }
`;

const getTooltipText = (lockStatus?: LockStatus): string => {
  if (lockStatus === 'PENDING') {
    return 'Requested lock';
  } else if (lockStatus === 'AVAILABLE') {
    return 'Lock this rate';
  } else {
    return 'Unable to lock';
  }
};

const LockRequested = () => (
  <RequestedLock>
    <OwnUpIcon variant="noTop" icon="Lock" height="14px" width="11px" />
  </RequestedLock>
);

const ButtonContainer = ({
  onLock,
  lockStatus,
  showCompareButton,
  onCompare
}: Pick<DesktopRateRowProps, 'onLock' | 'lockStatus' | 'showCompareButton' | 'onCompare'>) => {
  const showLockButton = lockStatus !== undefined;
  const Lock = () => (
    <LockButton
      disabled={lockStatus !== 'AVAILABLE'}
      onClick={(e) => {
        onLock();
        e.stopPropagation();
      }}
    >
      <OwnUpIcon icon="Unlock" height="14px" width="11px" />
    </LockButton>
  );

  return (
    <ButtonContainerLayout>
      {showLockButton && (
        <OwnUpTooltip description={getTooltipText(lockStatus)}>
          <div>{!(lockStatus === 'PENDING') ? <Lock /> : <LockRequested />}</div>
        </OwnUpTooltip>
      )}
      {showLockButton && showCompareButton ? (
        <OwnUpTooltip description="View details">
          <div>
            <DetailsButton>
              <OwnUpIcon icon="Hamburger" style={{ marginRight: '-6px' }} />
            </DetailsButton>
          </div>
        </OwnUpTooltip>
      ) : (
        <DetailsButton>
          View details
          <OwnUpIcon icon="Hamburger" style={{ marginLeft: '10px', marginRight: '-3px' }} />
        </DetailsButton>
      )}
      {showCompareButton && (
        <CompareButton
          onClick={(e) => {
            onCompare();
            e.stopPropagation();
          }}
        >
          Compare
        </CompareButton>
      )}
    </ButtonContainerLayout>
  );
};

const OwnUpTableText = styled(OwnUpText)`
  display: inline-block;
  width: 100%;
  text-align: left;
  font-size: 14px;
`;

const LenderImg = styled.img`
  height: auto;
  width: 85px;
  :nth-child(2) {
    margin-top: 16px;
  }
`;

export const DesktopRateRow = ({
  logo,
  lenderName,
  rate,
  apr,
  pAndI,
  showMortgageInsurance,
  monthlyMI,
  sorted,
  showLC,
  lenderCredit,
  showPoints,
  points,
  lenderFees,
  fixedLenderFees,
  lockStatus,
  onViewCostBreakdown,
  onLock,
  onCompare,
  showCompareButton
}: DesktopRateRowProps) => (
  <TableRow
    variant="clickable"
    onClick={onViewCostBreakdown}
    data={[
      <OwnUpTableCell
        key={`${lenderName}-logo`}
        style={{ position: 'relative', overflow: 'unset' }}
      >
        {!!logo ? (
          <LenderImg src={logo} alt={`${lenderName} logo`} />
        ) : (
          <OwnUpText>{lenderName}</OwnUpText>
        )}
      </OwnUpTableCell>,
      <OwnUpTableText
        key={`${lenderName}-txt-1`}
        variant={sorted.rate?.sortOrder === 0 ? 'bold' : undefined}
      >{`${formatRate(rate, 3)} / ${formatRate(apr, 3)}`}</OwnUpTableText>,
      <OwnUpTableText
        key={`${lenderName}-txt-2`}
        variant={sorted.pAndI?.sortOrder === 0 ? 'bold' : undefined}
      >{`${formatCurrency(pAndI, 0)}`}</OwnUpTableText>,
      ...(showMortgageInsurance
        ? [
            <OwnUpTableText
              key={`${lenderName}-txt-3`}
              variant={sorted.monthlyMI?.sortOrder === 0 ? 'bold' : undefined}
            >{`${formatCurrency(monthlyMI.value, 0)}`}</OwnUpTableText>
          ]
        : []),
      ...(showLC
        ? [
            <OwnUpTableText
              key={`${lenderName}-txt-4`}
              variant={sorted.lenderCredit?.sortOrder === 0 ? 'bold' : undefined}
            >{`${formatCurrency(lenderCredit, 0)}`}</OwnUpTableText>
          ]
        : []),
      ...(showPoints
        ? [
            <OwnUpTableText
              key={`${lenderName}-txt-5`}
              variant={sorted.points?.sortOrder === 0 ? 'bold' : undefined}
            >{`${formatCurrency(points, 0)}`}</OwnUpTableText>
          ]
        : []),
      <OwnUpTableText
        key={`${lenderName}-txt-6`}
        variant={sorted.fixedLenderFees?.sortOrder === 0 ? 'bold' : undefined}
      >{`${formatCurrency(fixedLenderFees, 0)}`}</OwnUpTableText>,
      <OwnUpTableText
        key={`${lenderName}-txt-7`}
        variant={sorted.lenderFees?.sortOrder === 0 ? 'bold' : undefined}
      >{`${formatCurrency(lenderFees, 0)}`}</OwnUpTableText>,
      <ButtonContainer
        key={`${lenderName}-btn`}
        lockStatus={lockStatus}
        onLock={onLock}
        onCompare={onCompare}
        showCompareButton={showCompareButton}
      />
    ]}
  />
);
