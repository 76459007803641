import { AsyncFunc } from './func';

export interface AsyncAllFunction<
  TOut,
  TPFunc extends Record<string | number, AsyncFunc<any, TArgs, T>> | AsyncFunc<any, TArgs, T>[],
  TArgs extends any[],
  T = void
> {
  /**
   * The composed functions
   */
  functions: TPFunc;
  (this: T, ...args: TArgs): Promise<TOut>;
}

type OutType<TFunc extends AsyncFunc<any, any, any>> =
  TFunc extends AsyncFunc<infer TOut, any, any> ? TOut : never;

/**
 * Given an array of one or more functions
 * returns a function that invokes every function
 * in the array and returns an array of the results.
 *
 * Results are returned in the position their function
 * was placed.
 */
export function asyncAll<TO1, TArgs extends any[], T = void>(
  functions: [AsyncFunc<TO1, TArgs, T>]
): AsyncAllFunction<[TO1], typeof functions, TArgs, T>;
/**
 * Given an array of one or more functions
 * returns a function that invokes every function
 * in the array and returns an array of the results.
 *
 * Results are returned in the position their function
 * was placed.
 */
export function asyncAll<TO1, TO2, TArgs extends any[], T = void>(
  functions: [AsyncFunc<TO1, TArgs, T>, AsyncFunc<TO2, TArgs, T>]
): AsyncAllFunction<[TO1, TO2], typeof functions, TArgs, T>;
/**
 * Given an array of one or more functions
 * returns a function that invokes every function
 * in the array and returns an array of the results.
 *
 * Results are returned in the position their function
 * was placed.
 */
export function asyncAll<TO1, TO2, TO3, TArgs extends any[], T = void>(
  functions: [AsyncFunc<TO1, TArgs, T>, AsyncFunc<TO2, TArgs, T>, AsyncFunc<TO3, TArgs, T>]
): AsyncAllFunction<[TO1, TO2, TO3], typeof functions, TArgs, T>;
/**
 * Given an array of one or more functions
 * returns a function that invokes every function
 * in the array and returns an array of the results.
 *
 * Results are returned in the position their function
 * was placed.
 */
export function asyncAll<TO1, TO2, TO3, TO4, TArgs extends any[], T = void>(
  functions: [
    AsyncFunc<TO1, TArgs, T>,
    AsyncFunc<TO2, TArgs, T>,
    AsyncFunc<TO3, TArgs, T>,
    AsyncFunc<TO4, TArgs, T>
  ]
): AsyncAllFunction<[TO1, TO2, TO3, TO4], typeof functions, TArgs, T>;
/**
 * Given an array of one or more functions
 * returns a function that invokes every function
 * in the array and returns an array of the results.
 *
 * Results are returned in the position their function
 * was placed.
 */
export function asyncAll<TO1, TO2, TO3, TO4, TO5, TArgs extends any[], T = void>(
  functions: [
    AsyncFunc<TO1, TArgs, T>,
    AsyncFunc<TO2, TArgs, T>,
    AsyncFunc<TO3, TArgs, T>,
    AsyncFunc<TO4, TArgs, T>,
    AsyncFunc<TO5, TArgs, T>
  ]
): AsyncAllFunction<[TO1, TO2, TO3, TO4, TO5], typeof functions, TArgs, T>;
/**
 * Given an array of one or more functions
 * returns a function that invokes every function
 * in the array and returns an array of the results.
 *
 * Results are returned in the position their function
 * was placed.
 */
export function asyncAll<TO1, TO2, TO3, TO4, TO5, TO6, TArgs extends any[], T = void>(
  functions: [
    AsyncFunc<TO1, TArgs, T>,
    AsyncFunc<TO2, TArgs, T>,
    AsyncFunc<TO3, TArgs, T>,
    AsyncFunc<TO4, TArgs, T>,
    AsyncFunc<TO5, TArgs, T>,
    AsyncFunc<TO6, TArgs, T>
  ]
): AsyncAllFunction<[TO1, TO2, TO3, TO4, TO5, TO6], typeof functions, TArgs, T>;
/**
 * Given an array of one or more functions
 * returns a function that invokes every function
 * in the array and returns an array of the results.
 *
 * Results are returned in the position their function
 * was placed.
 */
export function asyncAll<TO1, TO2, TO3, TO4, TO5, TO6, TO7, TArgs extends any[], T = void>(
  functions: [
    AsyncFunc<TO1, TArgs, T>,
    AsyncFunc<TO2, TArgs, T>,
    AsyncFunc<TO3, TArgs, T>,
    AsyncFunc<TO4, TArgs, T>,
    AsyncFunc<TO5, TArgs, T>,
    AsyncFunc<TO6, TArgs, T>,
    AsyncFunc<TO7, TArgs, T>
  ]
): AsyncAllFunction<[TO1, TO2, TO3, TO4, TO5, TO6, TO7], typeof functions, TArgs, T>;
/**
 * Given an array of one or more functions
 * returns a function that invokes every function
 * in the array and returns an array of the results.
 *
 * Results are returned in the position their function
 * was placed.
 */
export function asyncAll<TO1, TO2, TO3, TO4, TO5, TO6, TO7, TO8, TArgs extends any[], T = void>(
  functions: [
    AsyncFunc<TO1, TArgs, T>,
    AsyncFunc<TO2, TArgs, T>,
    AsyncFunc<TO3, TArgs, T>,
    AsyncFunc<TO4, TArgs, T>,
    AsyncFunc<TO5, TArgs, T>,
    AsyncFunc<TO6, TArgs, T>,
    AsyncFunc<TO7, TArgs, T>,
    AsyncFunc<TO8, TArgs, T>
  ]
): AsyncAllFunction<[TO1, TO2, TO3, TO4, TO5, TO6, TO7, TO8], typeof functions, TArgs, T>;
/**
 * Given an array of one or more functions
 * returns a function that invokes every function
 * in the array and returns an array of the results.
 *
 * Results are returned in the position their function
 * was placed.
 */
export function asyncAll<
  TO1,
  TO2,
  TO3,
  TO4,
  TO5,
  TO6,
  TO7,
  TO8,
  TO9,
  TArgs extends any[],
  T = void
>(
  functions: [
    AsyncFunc<TO1, TArgs, T>,
    AsyncFunc<TO2, TArgs, T>,
    AsyncFunc<TO3, TArgs, T>,
    AsyncFunc<TO4, TArgs, T>,
    AsyncFunc<TO5, TArgs, T>,
    AsyncFunc<TO6, TArgs, T>,
    AsyncFunc<TO7, TArgs, T>,
    AsyncFunc<TO8, TArgs, T>,
    AsyncFunc<TO9, TArgs, T>
  ]
): AsyncAllFunction<[TO1, TO2, TO3, TO4, TO5, TO6, TO7, TO8, TO9], typeof functions, TArgs, T>;
/**
 * Given an array of one or more functions
 * returns a function that invokes every function
 * in the array and returns an array of the results.
 *
 * Results are returned in the position their function
 * was placed.
 */
export function asyncAll<
  TO1,
  TO2,
  TO3,
  TO4,
  TO5,
  TO6,
  TO7,
  TO8,
  TO9,
  TO10,
  TArgs extends any[],
  T = void
>(
  functions: [
    AsyncFunc<TO1, TArgs, T>,
    AsyncFunc<TO2, TArgs, T>,
    AsyncFunc<TO3, TArgs, T>,
    AsyncFunc<TO4, TArgs, T>,
    AsyncFunc<TO5, TArgs, T>,
    AsyncFunc<TO6, TArgs, T>,
    AsyncFunc<TO7, TArgs, T>,
    AsyncFunc<TO8, TArgs, T>,
    AsyncFunc<TO9, TArgs, T>,
    AsyncFunc<TO10, TArgs, T>
  ]
): AsyncAllFunction<
  [TO1, TO2, TO3, TO4, TO5, TO6, TO7, TO8, TO9, TO10],
  typeof functions,
  TArgs,
  T
>;
/**
 * Given an array of one or more functions
 * returns a function that invokes every function
 * in the array and returns an array of the results.
 *
 * Results are returned in the position their function
 * was placed.
 */
export function asyncAll<
  TO1,
  TO2,
  TO3,
  TO4,
  TO5,
  TO6,
  TO7,
  TO8,
  TO9,
  TO10,
  TO11,
  TArgs extends any[],
  T = void
>(
  functions: [
    AsyncFunc<TO1, TArgs, T>,
    AsyncFunc<TO2, TArgs, T>,
    AsyncFunc<TO3, TArgs, T>,
    AsyncFunc<TO4, TArgs, T>,
    AsyncFunc<TO5, TArgs, T>,
    AsyncFunc<TO6, TArgs, T>,
    AsyncFunc<TO7, TArgs, T>,
    AsyncFunc<TO8, TArgs, T>,
    AsyncFunc<TO9, TArgs, T>,
    AsyncFunc<TO10, TArgs, T>,
    AsyncFunc<TO11, TArgs, T>
  ]
): AsyncAllFunction<
  [TO1, TO2, TO3, TO4, TO5, TO6, TO7, TO8, TO9, TO10, TO11],
  typeof functions,
  TArgs,
  T
>;
/**
 * Given an array of one or more functions
 * returns a function that invokes every function
 * in the array and returns an array of the results.
 *
 * Results are returned in the position their function
 * was placed.
 */
export function asyncAll<
  TO1,
  TO2,
  TO3,
  TO4,
  TO5,
  TO6,
  TO7,
  TO8,
  TO9,
  TO10,
  TO11,
  TO12,
  TArgs extends any[],
  T = void
>(
  functions: [
    AsyncFunc<TO1, TArgs, T>,
    AsyncFunc<TO2, TArgs, T>,
    AsyncFunc<TO3, TArgs, T>,
    AsyncFunc<TO4, TArgs, T>,
    AsyncFunc<TO5, TArgs, T>,
    AsyncFunc<TO6, TArgs, T>,
    AsyncFunc<TO7, TArgs, T>,
    AsyncFunc<TO8, TArgs, T>,
    AsyncFunc<TO9, TArgs, T>,
    AsyncFunc<TO10, TArgs, T>,
    AsyncFunc<TO11, TArgs, T>,
    AsyncFunc<TO12, TArgs, T>
  ]
): AsyncAllFunction<
  [TO1, TO2, TO3, TO4, TO5, TO6, TO7, TO8, TO9, TO10, TO11, TO12],
  typeof functions,
  TArgs,
  T
>;
/**
 * Given an array of one or more functions
 * returns a function that invokes every function
 * in the array and returns an array of the results.
 *
 * Results are returned in the position their function
 * was placed.
 */
export function asyncAll<
  TO1,
  TO2,
  TO3,
  TO4,
  TO5,
  TO6,
  TO7,
  TO8,
  TO9,
  TO10,
  TO11,
  TO12,
  TO13,
  TArgs extends any[],
  T = void
>(
  functions: [
    AsyncFunc<TO1, TArgs, T>,
    AsyncFunc<TO2, TArgs, T>,
    AsyncFunc<TO3, TArgs, T>,
    AsyncFunc<TO4, TArgs, T>,
    AsyncFunc<TO5, TArgs, T>,
    AsyncFunc<TO6, TArgs, T>,
    AsyncFunc<TO7, TArgs, T>,
    AsyncFunc<TO8, TArgs, T>,
    AsyncFunc<TO9, TArgs, T>,
    AsyncFunc<TO10, TArgs, T>,
    AsyncFunc<TO11, TArgs, T>,
    AsyncFunc<TO12, TArgs, T>,
    AsyncFunc<TO13, TArgs, T>
  ]
): AsyncAllFunction<
  [TO1, TO2, TO3, TO4, TO5, TO6, TO7, TO8, TO9, TO10, TO11, TO12, TO13],
  typeof functions,
  TArgs,
  T
>;
/**
 * Given an array of one or more functions
 * returns a function that invokes every function
 * in the array and returns an array of the results.
 *
 * Results are returned in the position their function
 * was placed.
 */
export function asyncAll<
  TO1,
  TO2,
  TO3,
  TO4,
  TO5,
  TO6,
  TO7,
  TO8,
  TO9,
  TO10,
  TO11,
  TO12,
  TO13,
  TO14,
  TArgs extends any[],
  T = void
>(
  functions: [
    AsyncFunc<TO1, TArgs, T>,
    AsyncFunc<TO2, TArgs, T>,
    AsyncFunc<TO3, TArgs, T>,
    AsyncFunc<TO4, TArgs, T>,
    AsyncFunc<TO5, TArgs, T>,
    AsyncFunc<TO6, TArgs, T>,
    AsyncFunc<TO7, TArgs, T>,
    AsyncFunc<TO8, TArgs, T>,
    AsyncFunc<TO9, TArgs, T>,
    AsyncFunc<TO10, TArgs, T>,
    AsyncFunc<TO11, TArgs, T>,
    AsyncFunc<TO12, TArgs, T>,
    AsyncFunc<TO13, TArgs, T>,
    AsyncFunc<TO14, TArgs, T>
  ]
): AsyncAllFunction<
  [TO1, TO2, TO3, TO4, TO5, TO6, TO7, TO8, TO9, TO10, TO11, TO12, TO13, TO14],
  typeof functions,
  TArgs,
  T
>;
/**
 * Given an array of one or more functions
 * returns a function that invokes every function
 * in the array and returns an array of the results.
 *
 * Results are returned in the position their function
 * was placed.
 */
export function asyncAll<
  TO1,
  TO2,
  TO3,
  TO4,
  TO5,
  TO6,
  TO7,
  TO8,
  TO9,
  TO10,
  TO11,
  TO12,
  TO13,
  TO14,
  TO15,
  TArgs extends any[],
  T = void
>(
  functions: [
    AsyncFunc<TO1, TArgs, T>,
    AsyncFunc<TO2, TArgs, T>,
    AsyncFunc<TO3, TArgs, T>,
    AsyncFunc<TO4, TArgs, T>,
    AsyncFunc<TO5, TArgs, T>,
    AsyncFunc<TO6, TArgs, T>,
    AsyncFunc<TO7, TArgs, T>,
    AsyncFunc<TO8, TArgs, T>,
    AsyncFunc<TO9, TArgs, T>,
    AsyncFunc<TO10, TArgs, T>,
    AsyncFunc<TO11, TArgs, T>,
    AsyncFunc<TO12, TArgs, T>,
    AsyncFunc<TO13, TArgs, T>,
    AsyncFunc<TO14, TArgs, T>,
    AsyncFunc<TO15, TArgs, T>
  ]
): AsyncAllFunction<
  [TO1, TO2, TO3, TO4, TO5, TO6, TO7, TO8, TO9, TO10, TO11, TO12, TO13, TO14, TO15],
  typeof functions,
  TArgs,
  T
>;
/**
 * Given an array of one or more functions
 * returns a function that invokes every function
 * in the array and returns an array of the results.
 *
 * Results are returned in the position their function
 * was placed.
 */
export function asyncAll<
  TO1,
  TO2,
  TO3,
  TO4,
  TO5,
  TO6,
  TO7,
  TO8,
  TO9,
  TO10,
  TO11,
  TO12,
  TO13,
  TO14,
  TO15,
  TO16,
  TArgs extends any[],
  T = void
>(
  functions: [
    AsyncFunc<TO1, TArgs, T>,
    AsyncFunc<TO2, TArgs, T>,
    AsyncFunc<TO3, TArgs, T>,
    AsyncFunc<TO4, TArgs, T>,
    AsyncFunc<TO5, TArgs, T>,
    AsyncFunc<TO6, TArgs, T>,
    AsyncFunc<TO7, TArgs, T>,
    AsyncFunc<TO8, TArgs, T>,
    AsyncFunc<TO9, TArgs, T>,
    AsyncFunc<TO10, TArgs, T>,
    AsyncFunc<TO11, TArgs, T>,
    AsyncFunc<TO12, TArgs, T>,
    AsyncFunc<TO13, TArgs, T>,
    AsyncFunc<TO14, TArgs, T>,
    AsyncFunc<TO15, TArgs, T>,
    AsyncFunc<TO16, TArgs, T>
  ]
): AsyncAllFunction<
  [TO1, TO2, TO3, TO4, TO5, TO6, TO7, TO8, TO9, TO10, TO11, TO12, TO13, TO14, TO15, TO16],
  typeof functions,
  TArgs,
  T
>;
/**
 * Given an array of one or more functions
 * returns a function that invokes every function
 * in the array and returns an array of the results.
 *
 * Results are returned in the position their function
 * was placed.
 */
export function asyncAll<
  TO1,
  TO2,
  TO3,
  TO4,
  TO5,
  TO6,
  TO7,
  TO8,
  TO9,
  TO10,
  TO11,
  TO12,
  TO13,
  TO14,
  TO15,
  TO16,
  TO17,
  TArgs extends any[],
  T = void
>(
  functions: [
    AsyncFunc<TO1, TArgs, T>,
    AsyncFunc<TO2, TArgs, T>,
    AsyncFunc<TO3, TArgs, T>,
    AsyncFunc<TO4, TArgs, T>,
    AsyncFunc<TO5, TArgs, T>,
    AsyncFunc<TO6, TArgs, T>,
    AsyncFunc<TO7, TArgs, T>,
    AsyncFunc<TO8, TArgs, T>,
    AsyncFunc<TO9, TArgs, T>,
    AsyncFunc<TO10, TArgs, T>,
    AsyncFunc<TO11, TArgs, T>,
    AsyncFunc<TO12, TArgs, T>,
    AsyncFunc<TO13, TArgs, T>,
    AsyncFunc<TO14, TArgs, T>,
    AsyncFunc<TO15, TArgs, T>,
    AsyncFunc<TO16, TArgs, T>,
    AsyncFunc<TO17, TArgs, T>
  ]
): AsyncAllFunction<
  [TO1, TO2, TO3, TO4, TO5, TO6, TO7, TO8, TO9, TO10, TO11, TO12, TO13, TO14, TO15, TO16, TO17],
  typeof functions,
  TArgs,
  T
>;
/**
 * Given an array of one or more functions
 * returns a function that invokes every function
 * in the array and returns an array of the results.
 *
 * Results are returned in the position their function
 * was placed.
 */
export function asyncAll<
  TO1,
  TO2,
  TO3,
  TO4,
  TO5,
  TO6,
  TO7,
  TO8,
  TO9,
  TO10,
  TO11,
  TO12,
  TO13,
  TO14,
  TO15,
  TO16,
  TO17,
  TO18,
  TArgs extends any[],
  T = void
>(
  functions: [
    AsyncFunc<TO1, TArgs, T>,
    AsyncFunc<TO2, TArgs, T>,
    AsyncFunc<TO3, TArgs, T>,
    AsyncFunc<TO4, TArgs, T>,
    AsyncFunc<TO5, TArgs, T>,
    AsyncFunc<TO6, TArgs, T>,
    AsyncFunc<TO7, TArgs, T>,
    AsyncFunc<TO8, TArgs, T>,
    AsyncFunc<TO9, TArgs, T>,
    AsyncFunc<TO10, TArgs, T>,
    AsyncFunc<TO11, TArgs, T>,
    AsyncFunc<TO12, TArgs, T>,
    AsyncFunc<TO13, TArgs, T>,
    AsyncFunc<TO14, TArgs, T>,
    AsyncFunc<TO15, TArgs, T>,
    AsyncFunc<TO16, TArgs, T>,
    AsyncFunc<TO17, TArgs, T>,
    AsyncFunc<TO18, TArgs, T>
  ]
): AsyncAllFunction<
  [
    TO1,
    TO2,
    TO3,
    TO4,
    TO5,
    TO6,
    TO7,
    TO8,
    TO9,
    TO10,
    TO11,
    TO12,
    TO13,
    TO14,
    TO15,
    TO16,
    TO17,
    TO18
  ],
  typeof functions,
  TArgs,
  T
>;
/**
 * Given an array of one or more functions
 * returns a function that invokes every function
 * in the array and returns an array of the results.
 *
 * Results are returned in the position their function
 * was placed.
 */
export function asyncAll<
  TO1,
  TO2,
  TO3,
  TO4,
  TO5,
  TO6,
  TO7,
  TO8,
  TO9,
  TO10,
  TO11,
  TO12,
  TO13,
  TO14,
  TO15,
  TO16,
  TO17,
  TO18,
  TO19,
  TArgs extends any[],
  T = void
>(
  functions: [
    AsyncFunc<TO1, TArgs, T>,
    AsyncFunc<TO2, TArgs, T>,
    AsyncFunc<TO3, TArgs, T>,
    AsyncFunc<TO4, TArgs, T>,
    AsyncFunc<TO5, TArgs, T>,
    AsyncFunc<TO6, TArgs, T>,
    AsyncFunc<TO7, TArgs, T>,
    AsyncFunc<TO8, TArgs, T>,
    AsyncFunc<TO9, TArgs, T>,
    AsyncFunc<TO10, TArgs, T>,
    AsyncFunc<TO11, TArgs, T>,
    AsyncFunc<TO12, TArgs, T>,
    AsyncFunc<TO13, TArgs, T>,
    AsyncFunc<TO14, TArgs, T>,
    AsyncFunc<TO15, TArgs, T>,
    AsyncFunc<TO16, TArgs, T>,
    AsyncFunc<TO17, TArgs, T>,
    AsyncFunc<TO18, TArgs, T>,
    AsyncFunc<TO19, TArgs, T>
  ]
): AsyncAllFunction<
  [
    TO1,
    TO2,
    TO3,
    TO4,
    TO5,
    TO6,
    TO7,
    TO8,
    TO9,
    TO10,
    TO11,
    TO12,
    TO13,
    TO14,
    TO15,
    TO16,
    TO17,
    TO18,
    TO19
  ],
  typeof functions,
  TArgs,
  T
>;
/**
 * Given an array of one or more functions
 * returns a function that invokes every function
 * in the array and returns an array of the results.
 *
 * Results are returned in the position their function
 * was placed.
 */
export function asyncAll<
  TO1,
  TO2,
  TO3,
  TO4,
  TO5,
  TO6,
  TO7,
  TO8,
  TO9,
  TO10,
  TO11,
  TO12,
  TO13,
  TO14,
  TO15,
  TO16,
  TO17,
  TO18,
  TO19,
  TO20,
  TArgs extends any[],
  T = void
>(
  functions: [
    AsyncFunc<TO1, TArgs, T>,
    AsyncFunc<TO2, TArgs, T>,
    AsyncFunc<TO3, TArgs, T>,
    AsyncFunc<TO4, TArgs, T>,
    AsyncFunc<TO5, TArgs, T>,
    AsyncFunc<TO6, TArgs, T>,
    AsyncFunc<TO7, TArgs, T>,
    AsyncFunc<TO8, TArgs, T>,
    AsyncFunc<TO9, TArgs, T>,
    AsyncFunc<TO10, TArgs, T>,
    AsyncFunc<TO11, TArgs, T>,
    AsyncFunc<TO12, TArgs, T>,
    AsyncFunc<TO13, TArgs, T>,
    AsyncFunc<TO14, TArgs, T>,
    AsyncFunc<TO15, TArgs, T>,
    AsyncFunc<TO16, TArgs, T>,
    AsyncFunc<TO17, TArgs, T>,
    AsyncFunc<TO18, TArgs, T>,
    AsyncFunc<TO19, TArgs, T>,
    AsyncFunc<TO20, TArgs, T>
  ]
): AsyncAllFunction<
  [
    TO1,
    TO2,
    TO3,
    TO4,
    TO5,
    TO6,
    TO7,
    TO8,
    TO9,
    TO10,
    TO11,
    TO12,
    TO13,
    TO14,
    TO15,
    TO16,
    TO17,
    TO18,
    TO19,
    TO20
  ],
  typeof functions,
  TArgs,
  T
>;
/**
 * Given an array of one or more functions
 * returns a function that invokes every function
 * in the array and returns an array of the results.
 *
 * Results are returned in the position their function
 * was placed.
 */
export function asyncAll<TOFunc, TArgs extends any[], T = void>(
  functions: AsyncFunc<TOFunc, TArgs, T>[]
): AsyncAllFunction<TOFunc[], typeof functions, TArgs, T>;
/**
 * Given an object of one or more functions
 * returns a function that invokes every function
 * in the object and returns an object of the results.
 *
 * Results are returned under the key their function was.
 */
export function asyncAll<
  TPFunc extends Record<string | number, AsyncFunc<any, TArgs, T>>,
  TArgs extends any[],
  T = void
>(
  functions: TPFunc
): AsyncAllFunction<{ [K in keyof TPFunc]: OutType<TPFunc[K]> }, TPFunc, TArgs, T>;
export function asyncAll<
  TPFunc extends Record<string | number, AsyncFunc<any, TArgs, T>> | AsyncFunc<any, TArgs, T>[],
  TArgs extends any[],
  T = void
>(functions: TPFunc): AsyncAllFunction<any, TPFunc, TArgs, T> {
  const impl = function (this: T, ...args: TArgs): Promise<any> {
    if (Array.isArray(functions)) {
      return Promise.all(functions.map((func) => func.apply(this, args)));
    }
    return Promise.all(
      Object.keys(functions).map((key) => [key, (functions as any)[key].apply(this, args)])
    ).then((results) =>
      results.reduce((acc, [key, value]) => Object.assign(acc, { [key]: value }), {})
    );
  };
  Object.defineProperty(impl, 'functions', { value: functions, writable: false, enumerable: true });
  return impl as AsyncAllFunction<any, TPFunc, TArgs, T>;
}
