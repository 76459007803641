import React from 'react';
import * as colors from '../../../colors';

export const PhoneIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.05178 2.4212C6.89324 2.12145 6.53369 1.99016 6.2193 2.11722C4.49578 2.81375 3.398 4.37024 3.60335 6.05598C3.85085 8.08775 4.49272 10.6796 6.05539 12.9113C7.61807 15.1431 9.83408 16.6327 11.6587 17.5602C13.1725 18.3297 15.0106 17.8305 16.2546 16.4491C16.4815 16.1971 16.4811 15.8144 16.2537 15.5629L14.1724 13.2615C13.9187 12.9809 13.4958 12.9332 13.1859 13.1502L11.6276 14.2413C11.2445 14.5096 10.7071 14.5582 10.2683 14.2678C9.75463 13.9279 8.68706 13.1209 7.7228 11.7438C6.75855 10.3667 6.36537 9.08751 6.22161 8.48859C6.09878 7.97689 6.32833 7.48855 6.71145 7.22029L8.26976 6.12915C8.57959 5.9122 8.6794 5.49848 8.50255 5.16413L7.05178 2.4212ZM5.65726 0.726493C6.68466 0.311286 7.85964 0.740332 8.37773 1.71988L9.82851 4.46281C10.359 5.46588 10.0596 6.70702 9.13012 7.35787L7.73163 8.33711C7.88151 8.8783 8.22515 9.84605 8.95153 10.8834C9.67792 11.9208 10.4698 12.5747 10.9271 12.9007L12.3256 11.9214C13.2551 11.2706 14.5238 11.4137 15.2849 12.2553L17.3662 14.5567C18.1095 15.3786 18.1108 16.6295 17.3692 17.4529C15.7833 19.2139 13.2348 20.044 10.979 18.8973C9.03509 17.9092 6.58323 16.2803 4.82667 13.7717C3.0701 11.2631 2.37803 8.40195 2.11435 6.23736C1.80835 3.72535 3.46009 1.61445 5.65726 0.726493Z"
      fill={colors.CHARCOAL_100}
    />
  </svg>
);
