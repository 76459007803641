import {
  OwnUpBodyMedium,
  OwnUpSmallBody,
  PropsWithTheme
} from '@rategravity/own-up-component-library';
import { OwnUpHeader, OwnUpText } from '@rategravity/own-up-component-library-legacy';
import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../modules/colors';
import { LenderData } from '../../redux/selector';

const ContainerLegacy = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const Container = styled.div<PropsWithTheme<{}>>`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  display: -webkit-box;
  -webkit-box-pack: start;
`;

const LenderImageLegacy = styled.img`
  max-width: 75px;
  display: block;
`;
const LenderImage = styled.img<PropsWithTheme<{}>>`
  max-width: 48px;
  display: block;
`;

const TextContainerLegacy = styled.div`
  display: flex;
  flex-direction: column;
`;
const TextContainer = styled.div<PropsWithTheme<{}>>`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
`;

const SubText = styled(OwnUpText)`
  font-size: 12px;
`;
const LenderHeader = styled(OwnUpHeader)`
  color: ${colors.CHARCOAL};
  margin: 0;
`;
export const LegacyLender = ({
  lenderName,
  logo,
  nmls,
  isLenderDirect = false
}: {
  lenderName: string;
  logo: string;
  nmls: string;
  isLenderDirect?: boolean;
}) => (
  <ContainerLegacy style={{ marginLeft: isLenderDirect ? 0 : '24px' }}>
    <LenderImageLegacy
      style={{ width: isLenderDirect ? '40px' : '75px' }}
      src={logo}
      alt={`${lenderName} logo`}
    />
    <TextContainerLegacy style={{ marginLeft: isLenderDirect ? '16px' : '24px' }}>
      <LenderHeader variant="section">{lenderName}</LenderHeader>
      {!!nmls && (
        <SubText style={{ color: colors.CHARCOAL, fontSize: isLenderDirect ? '14px' : '12px' }}>
          NMLS: {nmls}
        </SubText>
      )}
    </TextContainerLegacy>
  </ContainerLegacy>
);

const LenderName = styled(OwnUpBodyMedium)`
  margin-bottom: 4px;
`;

export const Lender = ({ lenderName, logo, nmls }: LenderData['lenderInfo']) => (
  <Container>
    <LenderImage height="48px" src={logo} alt={`${lenderName} logo`} />
    <TextContainer>
      <LenderName variant="body1">{lenderName}</LenderName>
      {!!nmls && <OwnUpSmallBody variant="body1">NMLS: {nmls}</OwnUpSmallBody>}
    </TextContainer>
  </Container>
);
