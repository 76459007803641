import {
  OwnUpBodyMedium,
  OwnUpFillAccordion,
  OwnUpSmallBody,
  OwnUpSmallBodyMedium,
  PropsWithTheme
} from '@rategravity/own-up-component-library';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

export interface ScenarioProps {
  scenarioFields: {
    title: string;
    value: string;
  }[];
}

const Accordion = styled(OwnUpFillAccordion)`
  > div.MuiCollapse-root > * > * > * > * > p.MuiTypography-body1 {
    width: 100%;
  }
  ${({ theme }) => theme.breakpoints.only('md')} {
    margin-top: 8px !important;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(4)}px` /* 32px */} !important;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    flex-direction: column;
  }
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  :first-child {
    margin-right: 24px;
  }
  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 100%;
    :last-child {
      padding-top: 8px;
    }
  }
`;

const ScenarioFieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  :not(:last-child) {
    padding-bottom: 8px;
  }
`;

const ScenarioField = ({ title, value }: { title: string; value: string }) => (
  <ScenarioFieldContainer>
    <OwnUpSmallBody variant="body1" style={{ paddingRight: '8px' }}>
      {title}
    </OwnUpSmallBody>
    <OwnUpSmallBodyMedium variant="body2" style={{ textAlign: 'right' }}>
      {value}
    </OwnUpSmallBodyMedium>
  </ScenarioFieldContainer>
);

export const Scenario = ({ scenarioFields }: ScenarioProps) => {
  const middle = Math.ceil(scenarioFields.length / 2);
  const [expanded, setExpanded] = useState(true);
  const handleChange = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);
  return (
    <Accordion
      title={<OwnUpBodyMedium variant="body1">Your scenario</OwnUpBodyMedium>}
      expanded={expanded}
      onChange={handleChange}
    >
      <Row>
        <Col>
          {scenarioFields.slice(0, middle).map(({ title, value }, index) => (
            <ScenarioField {...{ title, value }} key={`${index}-col1`} />
          ))}
        </Col>
        <Col>
          {scenarioFields.slice(middle).map(({ title, value }, index) => (
            <ScenarioField {...{ title, value }} key={`${index}-col2`} />
          ))}
        </Col>
      </Row>
    </Accordion>
  );
};
