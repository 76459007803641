import React, { MutableRefObject, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { colors } from '../../../modules/colors';
import { TabTextLegacy } from './tab-text';

export interface MenuItem {
  key: string;
  text: string;
  active: boolean;
}

interface SetMenuProps {
  items: MenuItem[];
  onItemSelected: (item: string) => void;
}

const Container = styled.div<{ sticky: boolean }>`
  overflow-x: scroll;
  white-space: nowrap;
  padding: 16px 12px;
  width: 100%;
  border-bottom: 1px solid ${colors.GREY};
  z-index: 10;
  position: ${({ sticky }) => (sticky ? 'sticky' : 'unset')};
  top: 0;
  background-color: white;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const Item = styled.button<{ isActive: boolean }>`
  text-decoration: none;
  margin: 0 12px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;

  &:after {
    content: '';
    position: absolute;
    bottom: -16px;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: ${colors.SUCCESS};
    opacity: ${({ isActive }) => (isActive ? 1 : 0)};
    transition: opacity 300ms;
  }
`;

export interface BottomOffsetElement {
  getBottomOffset: () => number;
}

const setRef = (
  ref:
    | MutableRefObject<BottomOffsetElement | null>
    | ((v: BottomOffsetElement | null) => void)
    | null,
  val: BottomOffsetElement
) => {
  if (ref === null) {
    return;
  } else if (typeof ref === 'function') {
    ref(val);
  } else {
    ref.current = val;
  }
};

export const HorizontalScrollableMenu = React.forwardRef<BottomOffsetElement, SetMenuProps>(
  ({ items, onItemSelected }: SetMenuProps, ref) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    setRef(
      ref,
      useMemo(
        () => ({
          getBottomOffset: () => {
            if (containerRef.current) {
              return containerRef.current.clientHeight;
            }
            return 0;
          }
        }),
        [containerRef]
      )
    );
    return (
      <Container ref={containerRef} sticky={items.length > 1}>
        {items.map((item) => (
          <Item
            key={item.key}
            onClick={() => {
              onItemSelected(item.key);
            }}
            isActive={item.active}
          >
            <TabTextLegacy isActive={item.active}>{item.text}</TabTextLegacy>
          </Item>
        ))}
      </Container>
    );
  }
);
