import { AsyncFunc1 } from './func';

export interface AsyncPipedFunction<
  TIn,
  TOut,
  TArgs extends any[],
  TFunc extends AsyncFunc1<any, any, TArgs, T>[],
  T = void
> {
  /**
   * The functions composed TIn this try
   */
  readonly functions: TFunc;
  (this: T, i: TIn, ...args: TArgs): Promise<TOut>;
}

/**
 * Given two or more functions composes them from left-to-right
 * The result of a left function is passed as the first argument to the right function,
 * remaining arguments are passed into all functions.
 */
export function asyncPipe<TIn, TIn2, TOut, TArgs extends any[], T = void>(
  p1: AsyncFunc1<TIn, TIn2, TArgs, T>,
  p2: AsyncFunc1<TIn2, TOut, TArgs, T>
): AsyncPipedFunction<TIn, TOut, TArgs, [typeof p1, typeof p2], T>;
/**
 * Given two or more functions composes them from left-to-right
 * The result of a left function is passed as the first argument to the right function,
 * remaining arguments are passed into all functions.
 */
export function asyncPipe<TIn, TIn2, TIn3, TOut, TArgs extends any[], T = void>(
  p1: AsyncFunc1<TIn, TIn2, TArgs, T>,
  p2: AsyncFunc1<TIn2, TIn3, TArgs, T>,
  p3: AsyncFunc1<TIn3, TOut, TArgs, T>
): AsyncPipedFunction<TIn, TOut, TArgs, [typeof p1, typeof p2, typeof p3], T>;
/**
 * Given two or more functions composes them from left-to-right
 * The result of a left function is passed as the first argument to the right function,
 * remaining arguments are passed into all functions.
 */
export function asyncPipe<TIn, TIn2, TIn3, TIn4, TOut, TArgs extends any[], T = void>(
  p1: AsyncFunc1<TIn, TIn2, TArgs, T>,
  p2: AsyncFunc1<TIn2, TIn3, TArgs, T>,
  p3: AsyncFunc1<TIn3, TIn4, TArgs, T>,
  p4: AsyncFunc1<TIn4, TOut, TArgs, T>
): AsyncPipedFunction<TIn, TOut, TArgs, [typeof p1, typeof p2, typeof p3, typeof p4], T>;
/**
 * Given two or more functions composes them from left-to-right
 * The result of a left function is passed as the first argument to the right function,
 * remaining arguments are passed into all functions.
 */
export function asyncPipe<TIn, TIn2, TIn3, TIn4, TIn5, TOut, TArgs extends any[], T = void>(
  p1: AsyncFunc1<TIn, TIn2, TArgs, T>,
  p2: AsyncFunc1<TIn2, TIn3, TArgs, T>,
  p3: AsyncFunc1<TIn3, TIn4, TArgs, T>,
  p4: AsyncFunc1<TIn4, TIn5, TArgs, T>,
  p5: AsyncFunc1<TIn5, TOut, TArgs, T>
): AsyncPipedFunction<TIn, TOut, TArgs, [typeof p1, typeof p2, typeof p3, typeof p4, typeof p5], T>;
/**
 * Given two or more functions composes them from left-to-right
 * The result of a left function is passed as the first argument to the right function,
 * remaining arguments are passed into all functions.
 */
export function asyncPipe<TIn, TIn2, TIn3, TIn4, TIn5, TIn6, TOut, TArgs extends any[], T = void>(
  p1: AsyncFunc1<TIn, TIn2, TArgs, T>,
  p2: AsyncFunc1<TIn2, TIn3, TArgs, T>,
  p3: AsyncFunc1<TIn3, TIn4, TArgs, T>,
  p4: AsyncFunc1<TIn4, TIn5, TArgs, T>,
  p5: AsyncFunc1<TIn5, TIn6, TArgs, T>,
  p6: AsyncFunc1<TIn6, TOut, TArgs, T>
): AsyncPipedFunction<
  TIn,
  TOut,
  TArgs,
  [typeof p1, typeof p2, typeof p3, typeof p4, typeof p5, typeof p6],
  T
>;
/**
 * Given two or more functions composes them from left-to-right
 * The result of a left function is passed as the first argument to the right function,
 * remaining arguments are passed into all functions.
 */
export function asyncPipe<
  TIn,
  TIn2,
  TIn3,
  TIn4,
  TIn5,
  TIn6,
  TIn7,
  TOut,
  TArgs extends any[],
  T = void
>(
  p1: AsyncFunc1<TIn, TIn2, TArgs, T>,
  p2: AsyncFunc1<TIn2, TIn3, TArgs, T>,
  p3: AsyncFunc1<TIn3, TIn4, TArgs, T>,
  p4: AsyncFunc1<TIn4, TIn5, TArgs, T>,
  p5: AsyncFunc1<TIn5, TIn6, TArgs, T>,
  p6: AsyncFunc1<TIn6, TIn7, TArgs, T>,
  p7: AsyncFunc1<TIn7, TOut, TArgs, T>
): AsyncPipedFunction<
  TIn,
  TOut,
  TArgs,
  [typeof p1, typeof p2, typeof p3, typeof p4, typeof p5, typeof p6, typeof p7],
  T
>;
/**
 * Given two or more functions composes them from left-to-right
 * The result of a left function is passed as the first argument to the right function,
 * remaining arguments are passed into all functions.
 */
export function asyncPipe<
  TIn,
  TIn2,
  TIn3,
  TIn4,
  TIn5,
  TIn6,
  TIn7,
  TIn8,
  TOut,
  TArgs extends any[],
  T = void
>(
  p1: AsyncFunc1<TIn, TIn2, TArgs, T>,
  p2: AsyncFunc1<TIn2, TIn3, TArgs, T>,
  p3: AsyncFunc1<TIn3, TIn4, TArgs, T>,
  p4: AsyncFunc1<TIn4, TIn5, TArgs, T>,
  p5: AsyncFunc1<TIn5, TIn6, TArgs, T>,
  p6: AsyncFunc1<TIn6, TIn7, TArgs, T>,
  p7: AsyncFunc1<TIn7, TIn8, TArgs, T>,
  p8: AsyncFunc1<TIn8, TOut, TArgs, T>
): AsyncPipedFunction<
  TIn,
  TOut,
  TArgs,
  [typeof p1, typeof p2, typeof p3, typeof p4, typeof p5, typeof p6, typeof p7, typeof p8],
  T
>;
/**
 * Given two or more functions composes them from left-to-right
 * The result of a left function is passed as the first argument to the right function,
 * remaining arguments are passed into all functions.
 */
export function asyncPipe<
  TIn,
  TIn2,
  TIn3,
  TIn4,
  TIn5,
  TIn6,
  TIn7,
  TIn8,
  TIn9,
  TOut,
  TArgs extends any[],
  T = void
>(
  p1: AsyncFunc1<TIn, TIn2, TArgs, T>,
  p2: AsyncFunc1<TIn2, TIn3, TArgs, T>,
  p3: AsyncFunc1<TIn3, TIn4, TArgs, T>,
  p4: AsyncFunc1<TIn4, TIn5, TArgs, T>,
  p5: AsyncFunc1<TIn5, TIn6, TArgs, T>,
  p6: AsyncFunc1<TIn6, TIn7, TArgs, T>,
  p7: AsyncFunc1<TIn7, TIn8, TArgs, T>,
  p8: AsyncFunc1<TIn8, TIn9, TArgs, T>,
  p9: AsyncFunc1<TIn9, TOut, TArgs, T>
): AsyncPipedFunction<
  TIn,
  TOut,
  TArgs,
  [
    typeof p1,
    typeof p2,
    typeof p3,
    typeof p4,
    typeof p5,
    typeof p6,
    typeof p7,
    typeof p8,
    typeof p9
  ],
  T
>;
/**
 * Given two or more functions composes them from left-to-right
 * The result of a left function is passed as the first argument to the right function,
 * remaining arguments are passed into all functions.
 */
export function asyncPipe<
  TIn,
  TIn2,
  TIn3,
  TIn4,
  TIn5,
  TIn6,
  TIn7,
  TIn8,
  TIn9,
  TIn10,
  TOut,
  TArgs extends any[],
  T = void
>(
  p1: AsyncFunc1<TIn, TIn2, TArgs, T>,
  p2: AsyncFunc1<TIn2, TIn3, TArgs, T>,
  p3: AsyncFunc1<TIn3, TIn4, TArgs, T>,
  p4: AsyncFunc1<TIn4, TIn5, TArgs, T>,
  p5: AsyncFunc1<TIn5, TIn6, TArgs, T>,
  p6: AsyncFunc1<TIn6, TIn7, TArgs, T>,
  p7: AsyncFunc1<TIn7, TIn8, TArgs, T>,
  p8: AsyncFunc1<TIn8, TIn9, TArgs, T>,
  p9: AsyncFunc1<TIn9, TIn10, TArgs, T>,
  p10: AsyncFunc1<TIn10, TOut, TArgs, T>
): AsyncPipedFunction<
  TIn,
  TOut,
  TArgs,
  [
    typeof p1,
    typeof p2,
    typeof p3,
    typeof p4,
    typeof p5,
    typeof p6,
    typeof p7,
    typeof p8,
    typeof p9,
    typeof p10
  ],
  T
>;
/**
 * Given two or more functions composes them from left-to-right
 * The result of a left function is passed as the first argument to the right function,
 * remaining arguments are passed into all functions.
 */
export function asyncPipe<
  TIn,
  TIn2,
  TIn3,
  TIn4,
  TIn5,
  TIn6,
  TIn7,
  TIn8,
  TIn9,
  TIn10,
  TIn11,
  TOut,
  TArgs extends any[],
  T = void
>(
  p1: AsyncFunc1<TIn, TIn2, TArgs, T>,
  p2: AsyncFunc1<TIn2, TIn3, TArgs, T>,
  p3: AsyncFunc1<TIn3, TIn4, TArgs, T>,
  p4: AsyncFunc1<TIn4, TIn5, TArgs, T>,
  p5: AsyncFunc1<TIn5, TIn6, TArgs, T>,
  p6: AsyncFunc1<TIn6, TIn7, TArgs, T>,
  p7: AsyncFunc1<TIn7, TIn8, TArgs, T>,
  p8: AsyncFunc1<TIn8, TIn9, TArgs, T>,
  p9: AsyncFunc1<TIn9, TIn10, TArgs, T>,
  p10: AsyncFunc1<TIn10, TIn11, TArgs, T>,
  p11: AsyncFunc1<TIn11, TOut, TArgs, T>
): AsyncPipedFunction<
  TIn,
  TOut,
  TArgs,
  [
    typeof p1,
    typeof p2,
    typeof p3,
    typeof p4,
    typeof p5,
    typeof p6,
    typeof p7,
    typeof p8,
    typeof p9,
    typeof p10,
    typeof p11
  ],
  T
>;
/**
 * Given two or more functions composes them from left-to-right
 * The result of a left function is passed as the first argument to the right function,
 * remaining arguments are passed into all functions.
 */
export function asyncPipe<
  TIn,
  TIn2,
  TIn3,
  TIn4,
  TIn5,
  TIn6,
  TIn7,
  TIn8,
  TIn9,
  TIn10,
  TIn11,
  TIn12,
  TOut,
  TArgs extends any[],
  T = void
>(
  p1: AsyncFunc1<TIn, TIn2, TArgs, T>,
  p2: AsyncFunc1<TIn2, TIn3, TArgs, T>,
  p3: AsyncFunc1<TIn3, TIn4, TArgs, T>,
  p4: AsyncFunc1<TIn4, TIn5, TArgs, T>,
  p5: AsyncFunc1<TIn5, TIn6, TArgs, T>,
  p6: AsyncFunc1<TIn6, TIn7, TArgs, T>,
  p7: AsyncFunc1<TIn7, TIn8, TArgs, T>,
  p8: AsyncFunc1<TIn8, TIn9, TArgs, T>,
  p9: AsyncFunc1<TIn9, TIn10, TArgs, T>,
  p10: AsyncFunc1<TIn10, TIn11, TArgs, T>,
  p11: AsyncFunc1<TIn11, TIn12, TArgs, T>,
  p12: AsyncFunc1<TIn12, TOut, TArgs, T>
): AsyncPipedFunction<
  TIn,
  TOut,
  TArgs,
  [
    typeof p1,
    typeof p2,
    typeof p3,
    typeof p4,
    typeof p5,
    typeof p6,
    typeof p7,
    typeof p8,
    typeof p9,
    typeof p10,
    typeof p11,
    typeof p12
  ],
  T
>;
/**
 * Given two or more functions composes them from left-to-right
 * The result of a left function is passed as the first argument to the right function,
 * remaining arguments are passed into all functions.
 */
export function asyncPipe<
  TIn,
  TIn2,
  TIn3,
  TIn4,
  TIn5,
  TIn6,
  TIn7,
  TIn8,
  TIn9,
  TIn10,
  TIn11,
  TIn12,
  TIn13,
  TOut,
  TArgs extends any[],
  T = void
>(
  p1: AsyncFunc1<TIn, TIn2, TArgs, T>,
  p2: AsyncFunc1<TIn2, TIn3, TArgs, T>,
  p3: AsyncFunc1<TIn3, TIn4, TArgs, T>,
  p4: AsyncFunc1<TIn4, TIn5, TArgs, T>,
  p5: AsyncFunc1<TIn5, TIn6, TArgs, T>,
  p6: AsyncFunc1<TIn6, TIn7, TArgs, T>,
  p7: AsyncFunc1<TIn7, TIn8, TArgs, T>,
  p8: AsyncFunc1<TIn8, TIn9, TArgs, T>,
  p9: AsyncFunc1<TIn9, TIn10, TArgs, T>,
  p10: AsyncFunc1<TIn10, TIn11, TArgs, T>,
  p11: AsyncFunc1<TIn11, TIn12, TArgs, T>,
  p12: AsyncFunc1<TIn12, TIn13, TArgs, T>,
  p13: AsyncFunc1<TIn13, TOut, TArgs, T>
): AsyncPipedFunction<
  TIn,
  TOut,
  TArgs,
  [
    typeof p1,
    typeof p2,
    typeof p3,
    typeof p4,
    typeof p5,
    typeof p6,
    typeof p7,
    typeof p8,
    typeof p9,
    typeof p10,
    typeof p11,
    typeof p12,
    typeof p13
  ],
  T
>;
/**
 * Given two or more functions composes them from left-to-right
 * The result of a left function is passed as the first argument to the right function,
 * remaining arguments are passed into all functions.
 */
export function asyncPipe<
  TIn,
  TIn2,
  TIn3,
  TIn4,
  TIn5,
  TIn6,
  TIn7,
  TIn8,
  TIn9,
  TIn10,
  TIn11,
  TIn12,
  TIn13,
  TIn14,
  TOut,
  TArgs extends any[],
  T = void
>(
  p1: AsyncFunc1<TIn, TIn2, TArgs, T>,
  p2: AsyncFunc1<TIn2, TIn3, TArgs, T>,
  p3: AsyncFunc1<TIn3, TIn4, TArgs, T>,
  p4: AsyncFunc1<TIn4, TIn5, TArgs, T>,
  p5: AsyncFunc1<TIn5, TIn6, TArgs, T>,
  p6: AsyncFunc1<TIn6, TIn7, TArgs, T>,
  p7: AsyncFunc1<TIn7, TIn8, TArgs, T>,
  p8: AsyncFunc1<TIn8, TIn9, TArgs, T>,
  p9: AsyncFunc1<TIn9, TIn10, TArgs, T>,
  p10: AsyncFunc1<TIn10, TIn11, TArgs, T>,
  p11: AsyncFunc1<TIn11, TIn12, TArgs, T>,
  p12: AsyncFunc1<TIn12, TIn13, TArgs, T>,
  p13: AsyncFunc1<TIn13, TIn14, TArgs, T>,
  p14: AsyncFunc1<TIn14, TOut, TArgs, T>
): AsyncPipedFunction<
  TIn,
  TOut,
  TArgs,
  [
    typeof p1,
    typeof p2,
    typeof p3,
    typeof p4,
    typeof p5,
    typeof p6,
    typeof p7,
    typeof p8,
    typeof p9,
    typeof p10,
    typeof p11,
    typeof p12,
    typeof p13,
    typeof p14
  ],
  T
>;
/**
 * Given two or more functions composes them from left-to-right
 * The result of a left function is passed as the first argument to the right function,
 * remaining arguments are passed into all functions.
 */
export function asyncPipe<
  TIn,
  TIn2,
  TIn3,
  TIn4,
  TIn5,
  TIn6,
  TIn7,
  TIn8,
  TIn9,
  TIn10,
  TIn11,
  TIn12,
  TIn13,
  TIn14,
  TIn15,
  TOut,
  TArgs extends any[],
  T = void
>(
  p1: AsyncFunc1<TIn, TIn2, TArgs, T>,
  p2: AsyncFunc1<TIn2, TIn3, TArgs, T>,
  p3: AsyncFunc1<TIn3, TIn4, TArgs, T>,
  p4: AsyncFunc1<TIn4, TIn5, TArgs, T>,
  p5: AsyncFunc1<TIn5, TIn6, TArgs, T>,
  p6: AsyncFunc1<TIn6, TIn7, TArgs, T>,
  p7: AsyncFunc1<TIn7, TIn8, TArgs, T>,
  p8: AsyncFunc1<TIn8, TIn9, TArgs, T>,
  p9: AsyncFunc1<TIn9, TIn10, TArgs, T>,
  p10: AsyncFunc1<TIn10, TIn11, TArgs, T>,
  p11: AsyncFunc1<TIn11, TIn12, TArgs, T>,
  p12: AsyncFunc1<TIn12, TIn13, TArgs, T>,
  p13: AsyncFunc1<TIn13, TIn14, TArgs, T>,
  p14: AsyncFunc1<TIn14, TIn15, TArgs, T>,
  p15: AsyncFunc1<TIn15, TOut, TArgs, T>
): AsyncPipedFunction<
  TIn,
  TOut,
  TArgs,
  [
    typeof p1,
    typeof p2,
    typeof p3,
    typeof p4,
    typeof p5,
    typeof p6,
    typeof p7,
    typeof p8,
    typeof p9,
    typeof p10,
    typeof p11,
    typeof p12,
    typeof p13,
    typeof p14,
    typeof p15
  ],
  T
>;
/**
 * Given two or more functions composes them from left-to-right
 * The result of a left function is passed as the first argument to the right function,
 * remaining arguments are passed into all functions.
 */
export function asyncPipe<
  TIn,
  TIn2,
  TIn3,
  TIn4,
  TIn5,
  TIn6,
  TIn7,
  TIn8,
  TIn9,
  TIn10,
  TIn11,
  TIn12,
  TIn13,
  TIn14,
  TIn15,
  TIn16,
  TOut,
  TArgs extends any[],
  T = void
>(
  p1: AsyncFunc1<TIn, TIn2, TArgs, T>,
  p2: AsyncFunc1<TIn2, TIn3, TArgs, T>,
  p3: AsyncFunc1<TIn3, TIn4, TArgs, T>,
  p4: AsyncFunc1<TIn4, TIn5, TArgs, T>,
  p5: AsyncFunc1<TIn5, TIn6, TArgs, T>,
  p6: AsyncFunc1<TIn6, TIn7, TArgs, T>,
  p7: AsyncFunc1<TIn7, TIn8, TArgs, T>,
  p8: AsyncFunc1<TIn8, TIn9, TArgs, T>,
  p9: AsyncFunc1<TIn9, TIn10, TArgs, T>,
  p10: AsyncFunc1<TIn10, TIn11, TArgs, T>,
  p11: AsyncFunc1<TIn11, TIn12, TArgs, T>,
  p12: AsyncFunc1<TIn12, TIn13, TArgs, T>,
  p13: AsyncFunc1<TIn13, TIn14, TArgs, T>,
  p14: AsyncFunc1<TIn14, TIn15, TArgs, T>,
  p15: AsyncFunc1<TIn15, TIn16, TArgs, T>,
  p16: AsyncFunc1<TIn16, TOut, TArgs, T>
): AsyncPipedFunction<
  TIn,
  TOut,
  TArgs,
  [
    typeof p1,
    typeof p2,
    typeof p3,
    typeof p4,
    typeof p5,
    typeof p6,
    typeof p7,
    typeof p8,
    typeof p9,
    typeof p10,
    typeof p11,
    typeof p12,
    typeof p13,
    typeof p14,
    typeof p15,
    typeof p16
  ],
  T
>;
/**
 * Given two or more functions composes them from left-to-right
 * The result of a left function is passed as the first argument to the right function,
 * remaining arguments are passed into all functions.
 */
export function asyncPipe<
  TIn,
  TIn2,
  TIn3,
  TIn4,
  TIn5,
  TIn6,
  TIn7,
  TIn8,
  TIn9,
  TIn10,
  TIn11,
  TIn12,
  TIn13,
  TIn14,
  TIn15,
  TIn16,
  TIn17,
  TOut,
  TArgs extends any[],
  T = void
>(
  p1: AsyncFunc1<TIn, TIn2, TArgs, T>,
  p2: AsyncFunc1<TIn2, TIn3, TArgs, T>,
  p3: AsyncFunc1<TIn3, TIn4, TArgs, T>,
  p4: AsyncFunc1<TIn4, TIn5, TArgs, T>,
  p5: AsyncFunc1<TIn5, TIn6, TArgs, T>,
  p6: AsyncFunc1<TIn6, TIn7, TArgs, T>,
  p7: AsyncFunc1<TIn7, TIn8, TArgs, T>,
  p8: AsyncFunc1<TIn8, TIn9, TArgs, T>,
  p9: AsyncFunc1<TIn9, TIn10, TArgs, T>,
  p10: AsyncFunc1<TIn10, TIn11, TArgs, T>,
  p11: AsyncFunc1<TIn11, TIn12, TArgs, T>,
  p12: AsyncFunc1<TIn12, TIn13, TArgs, T>,
  p13: AsyncFunc1<TIn13, TIn14, TArgs, T>,
  p14: AsyncFunc1<TIn14, TIn15, TArgs, T>,
  p15: AsyncFunc1<TIn15, TIn16, TArgs, T>,
  p16: AsyncFunc1<TIn16, TIn17, TArgs, T>,
  p17: AsyncFunc1<TIn17, TOut, TArgs, T>
): AsyncPipedFunction<
  TIn,
  TOut,
  TArgs,
  [
    typeof p1,
    typeof p2,
    typeof p3,
    typeof p4,
    typeof p5,
    typeof p6,
    typeof p7,
    typeof p8,
    typeof p9,
    typeof p10,
    typeof p11,
    typeof p12,
    typeof p13,
    typeof p14,
    typeof p15,
    typeof p16,
    typeof p17
  ],
  T
>;
/**
 * Given two or more functions composes them from left-to-right
 * The result of a left function is passed as the first argument to the right function,
 * remaining arguments are passed into all functions.
 */
export function asyncPipe<
  TIn,
  TIn2,
  TIn3,
  TIn4,
  TIn5,
  TIn6,
  TIn7,
  TIn8,
  TIn9,
  TIn10,
  TIn11,
  TIn12,
  TIn13,
  TIn14,
  TIn15,
  TIn16,
  TIn17,
  TIn18,
  TOut,
  TArgs extends any[],
  T = void
>(
  p1: AsyncFunc1<TIn, TIn2, TArgs, T>,
  p2: AsyncFunc1<TIn2, TIn3, TArgs, T>,
  p3: AsyncFunc1<TIn3, TIn4, TArgs, T>,
  p4: AsyncFunc1<TIn4, TIn5, TArgs, T>,
  p5: AsyncFunc1<TIn5, TIn6, TArgs, T>,
  p6: AsyncFunc1<TIn6, TIn7, TArgs, T>,
  p7: AsyncFunc1<TIn7, TIn8, TArgs, T>,
  p8: AsyncFunc1<TIn8, TIn9, TArgs, T>,
  p9: AsyncFunc1<TIn9, TIn10, TArgs, T>,
  p10: AsyncFunc1<TIn10, TIn11, TArgs, T>,
  p11: AsyncFunc1<TIn11, TIn12, TArgs, T>,
  p12: AsyncFunc1<TIn12, TIn13, TArgs, T>,
  p13: AsyncFunc1<TIn13, TIn14, TArgs, T>,
  p14: AsyncFunc1<TIn14, TIn15, TArgs, T>,
  p15: AsyncFunc1<TIn15, TIn16, TArgs, T>,
  p16: AsyncFunc1<TIn16, TIn17, TArgs, T>,
  p17: AsyncFunc1<TIn17, TIn18, TArgs, T>,
  p18: AsyncFunc1<TIn18, TOut, TArgs, T>
): AsyncPipedFunction<
  TIn,
  TOut,
  TArgs,
  [
    typeof p1,
    typeof p2,
    typeof p3,
    typeof p4,
    typeof p5,
    typeof p6,
    typeof p7,
    typeof p8,
    typeof p9,
    typeof p10,
    typeof p11,
    typeof p12,
    typeof p13,
    typeof p14,
    typeof p15,
    typeof p16,
    typeof p17,
    typeof p18
  ],
  T
>;
/**
 * Given two or more functions composes them from left-to-right
 * The result of a left function is passed as the first argument to the right function,
 * remaining arguments are passed into all functions.
 */
export function asyncPipe<
  TIn,
  TIn2,
  TIn3,
  TIn4,
  TIn5,
  TIn6,
  TIn7,
  TIn8,
  TIn9,
  TIn10,
  TIn11,
  TIn12,
  TIn13,
  TIn14,
  TIn15,
  TIn16,
  TIn17,
  TIn18,
  TIn19,
  TOut,
  TArgs extends any[],
  T = void
>(
  p1: AsyncFunc1<TIn, TIn2, TArgs, T>,
  p2: AsyncFunc1<TIn2, TIn3, TArgs, T>,
  p3: AsyncFunc1<TIn3, TIn4, TArgs, T>,
  p4: AsyncFunc1<TIn4, TIn5, TArgs, T>,
  p5: AsyncFunc1<TIn5, TIn6, TArgs, T>,
  p6: AsyncFunc1<TIn6, TIn7, TArgs, T>,
  p7: AsyncFunc1<TIn7, TIn8, TArgs, T>,
  p8: AsyncFunc1<TIn8, TIn9, TArgs, T>,
  p9: AsyncFunc1<TIn9, TIn10, TArgs, T>,
  p10: AsyncFunc1<TIn10, TIn11, TArgs, T>,
  p11: AsyncFunc1<TIn11, TIn12, TArgs, T>,
  p12: AsyncFunc1<TIn12, TIn13, TArgs, T>,
  p13: AsyncFunc1<TIn13, TIn14, TArgs, T>,
  p14: AsyncFunc1<TIn14, TIn15, TArgs, T>,
  p15: AsyncFunc1<TIn15, TIn16, TArgs, T>,
  p16: AsyncFunc1<TIn16, TIn17, TArgs, T>,
  p17: AsyncFunc1<TIn17, TIn18, TArgs, T>,
  p18: AsyncFunc1<TIn18, TIn19, TArgs, T>,
  p19: AsyncFunc1<TIn19, TOut, TArgs, T>
): AsyncPipedFunction<
  TIn,
  TOut,
  TArgs,
  [
    typeof p1,
    typeof p2,
    typeof p3,
    typeof p4,
    typeof p5,
    typeof p6,
    typeof p7,
    typeof p8,
    typeof p9,
    typeof p10,
    typeof p11,
    typeof p12,
    typeof p13,
    typeof p14,
    typeof p15,
    typeof p16,
    typeof p17,
    typeof p18,
    typeof p19
  ],
  T
>;
/**
 * Given two or more functions composes them from left-to-right
 * The result of a left function is passed as the first argument to the right function,
 * remaining arguments are passed into all functions.
 */
export function asyncPipe<
  TIn,
  TIn2,
  TIn3,
  TIn4,
  TIn5,
  TIn6,
  TIn7,
  TIn8,
  TIn9,
  TIn10,
  TIn11,
  TIn12,
  TIn13,
  TIn14,
  TIn15,
  TIn16,
  TIn17,
  TIn18,
  TIn19,
  TIn20,
  TOut,
  TArgs extends any[],
  T = void
>(
  p1: AsyncFunc1<TIn, TIn2, TArgs, T>,
  p2: AsyncFunc1<TIn2, TIn3, TArgs, T>,
  p3: AsyncFunc1<TIn3, TIn4, TArgs, T>,
  p4: AsyncFunc1<TIn4, TIn5, TArgs, T>,
  p5: AsyncFunc1<TIn5, TIn6, TArgs, T>,
  p6: AsyncFunc1<TIn6, TIn7, TArgs, T>,
  p7: AsyncFunc1<TIn7, TIn8, TArgs, T>,
  p8: AsyncFunc1<TIn8, TIn9, TArgs, T>,
  p9: AsyncFunc1<TIn9, TIn10, TArgs, T>,
  p10: AsyncFunc1<TIn10, TIn11, TArgs, T>,
  p11: AsyncFunc1<TIn11, TIn12, TArgs, T>,
  p12: AsyncFunc1<TIn12, TIn13, TArgs, T>,
  p13: AsyncFunc1<TIn13, TIn14, TArgs, T>,
  p14: AsyncFunc1<TIn14, TIn15, TArgs, T>,
  p15: AsyncFunc1<TIn15, TIn16, TArgs, T>,
  p16: AsyncFunc1<TIn16, TIn17, TArgs, T>,
  p17: AsyncFunc1<TIn17, TIn18, TArgs, T>,
  p18: AsyncFunc1<TIn18, TIn19, TArgs, T>,
  p19: AsyncFunc1<TIn19, TIn20, TArgs, T>,
  p20: AsyncFunc1<TIn20, TOut, TArgs, T>
): AsyncPipedFunction<
  TIn,
  TOut,
  TArgs,
  [
    typeof p1,
    typeof p2,
    typeof p3,
    typeof p4,
    typeof p5,
    typeof p6,
    typeof p7,
    typeof p8,
    typeof p9,
    typeof p10,
    typeof p11,
    typeof p12,
    typeof p13,
    typeof p14,
    typeof p15,
    typeof p16,
    typeof p17,
    typeof p18,
    typeof p19,
    typeof p20
  ],
  T
>;
/**
 * Given two or more functions composes them from left-to-right
 * The result of a left function is passed as the first argument to the right function,
 * remaining arguments are passed into all functions.
 */
export function asyncPipe<TIn, TOut, TArgs extends any[], T = void>(
  p1: AsyncFunc1<TIn, any, TArgs, T>,
  ...ps: AsyncFunc1<any, any, TArgs, T>[]
): AsyncPipedFunction<TIn, TOut, TArgs, [typeof p1, ...typeof ps], T> {
  const functions = [p1, ...ps];
  const impl = function (this: T, i: TIn, ...args: TArgs): Promise<TOut> {
    return functions.reduce(
      (input: Promise<any>, func: AsyncFunc1<any, any, any, T>) =>
        input.then((n) => func.call(this, n, ...args)),
      Promise.resolve(i)
    );
  };
  Object.defineProperty(impl, 'functions', { value: functions, writable: false, enumerable: true });
  return impl as AsyncPipedFunction<TIn, TOut, TArgs, [typeof p1, ...typeof ps], T>;
}
