import React from 'react';
import * as colors from '../../../colors';

export const ChevronRightIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.21181 16.7724C5.92333 16.4751 5.93041 16.0003 6.22764 15.7118L12.1125 10L6.22764 4.28818C5.93041 3.99969 5.92333 3.52487 6.21181 3.22764C6.5003 2.93041 6.97513 2.92332 7.27236 3.21181L13.7118 9.46181C13.8573 9.60306 13.9394 9.79721 13.9394 10C13.9394 10.2028 13.8573 10.3969 13.7118 10.5382L7.27236 16.7882C6.97513 17.0767 6.5003 17.0696 6.21181 16.7724Z"
      fill={colors.CHARCOAL_100}
    />
  </svg>
);
