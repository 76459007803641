import { CACTUS_100, FOG_60 } from '@rategravity/own-up-component-library';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Star100, Star25, Star50, Star75 } from '../../assets/stars';

interface StarStyle {
  width: string;
  height: string;
  spacing: string;
}

const StarContainer = styled.div`
  margin: 4px 0;
  display: flex;
  height: 1.1em;
`;

const StarImage = styled.div<StarStyle>`
  margin-right: ${({ spacing }) => spacing};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;

const createStarComponent = (c: JSX.Element, style: StarStyle) => (
  <StarImage {...style}>{c}</StarImage>
);

const handleDecimals = (
  decimal: number,
  emptyFill: string,
  fill: string,
  maskId: string,
  style: StarStyle
): {
  value: number; // Used for generating labels for assistive technology.
  component: () => JSX.Element | null;
} => {
  if (decimal > 0.97) {
    return {
      value: 1,
      component: () => createStarComponent(<Star100 fill={fill} />, style)
    };
  } else if (decimal > 0.67) {
    return {
      value: 0.75,
      component: () =>
        createStarComponent(<Star75 fill={fill} emptyFill={emptyFill} maskId={maskId} />, style)
    };
  } else if (decimal > 0.4) {
    return {
      value: 0.5,
      component: () =>
        createStarComponent(<Star50 fill={fill} emptyFill={emptyFill} maskId={maskId} />, style)
    };
  } else if (decimal > 0.03) {
    return {
      value: 0.25,
      component: () =>
        createStarComponent(<Star25 fill={fill} emptyFill={emptyFill} maskId={maskId} />, style)
    };
  }
  return {
    value: 0,
    component: () => null
  };
};

/**
 * `value` prop (default is out of 5) dictates how many stars to fill in
 * overriding the filled in and empty icon color with CSS
 * @param value A value from 0-5
 * @param style Allows adjusting the size of the star components and the margin between them
 * @param emptyFill Allows adjusting the empty fill color
 * @param fill Allows adjusting the filled fill color
 * @param maskId Allows adjusting the mask id for the star components, useful if there are multiple instances of this component on the same page
 */
export const ReviewStars = ({
  value,
  style = {
    width: '12px',
    height: '12px',
    spacing: '2px'
  },
  emptyFill = FOG_60,
  fill = CACTUS_100,
  maskId = 'star-mask'
}: {
  value: number;
  style?: StarStyle;
  emptyFill?: string;
  fill?: string;
  maskId?: string;
}) => {
  const int = Math.floor(value);
  const decimal = Number('0.' + value.toFixed(2).split('.')[1]);
  const { value: sensibleDecimal, component: DecimalStar } = useMemo(
    () => handleDecimals(decimal, emptyFill, fill, maskId, style),
    [decimal, emptyFill, fill, style, maskId]
  );

  return (
    <StarContainer aria-label={`${int + sensibleDecimal} stars out of 5`} role="img">
      {value >= 1 &&
        [...Array(int)].map((_, i) => (
          <StarImage key={i} {...style}>
            <Star100 fill={fill} />
          </StarImage>
        ))}
      {value !== 0 && <DecimalStar />}
      {value < 5 &&
        [...Array((value === int ? 5 : 4) - int)].map((_, i) => (
          <StarImage key={i} {...style}>
            <Star100 fill={emptyFill} />
          </StarImage>
        ))}
    </StarContainer>
  );
};
