import { OwnUpLargeBody, OwnUpLogoWithText } from '@rategravity/own-up-component-library';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { LoadingLogo } from '../../../components/loading';

export type LoadingComponentProps = React.PropsWithChildren<{
  id: string;
  loading: boolean;
  load: () => void;
  error?: boolean;
}>;

export const LoadingComponent = ({ loading, load, error, children, id }: LoadingComponentProps) => {
  useEffect(() => {
    if (loading) {
      load();
    }
  }, [loading, load]);

  if (loading) {
    return (
      <>
        <Helmet title="Loading" />
        <LoadingLogo />
      </>
    );
  } else if (error) {
    return (
      <div className="loading-page loading-page__error">
        <Helmet title="Error" />
        <OwnUpLogoWithText />
        <OwnUpLargeBody variant="body1" className="copy--center copy--large">
          There was an error loading your rates.
          <br />
          Please contact your advisor.
        </OwnUpLargeBody>
      </div>
    );
  } else {
    const childrenToRender = React.Children.only(children);
    return React.cloneElement(
      childrenToRender as React.DetailedReactHTMLElement<{ id: string }, HTMLElement>,
      {
        id
      }
    );
  }
};
