import { Reducer } from 'redux-compose';
import { PushNotificationAction } from './actions';
import { NotificationState } from './state';

const push = (state: NotificationState[], action: PushNotificationAction) => {
  return [...state, action.payload];
};

export const notificationReducer: Reducer<NotificationState[]> = (state = [], action) => {
  switch (action.type) {
    case 'PUSH_NOTIFICATION':
      return push(state, action as PushNotificationAction);
    default:
      return state;
  }
};
