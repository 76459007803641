import { AsyncFunc } from '../func';

/**
 * Given a count of retries return a filter that will retry subseqent steps until receiving a success.
 * The subsequent steps will be run at most retries + 1 times.
 * @param retries The number of times to retry the function
 * @param backoff a function to determing the number of MS to backoff after each failed attempt
 * @returns A filter that will retry the next steps in the filter chain a fixed number of times.
 */
export const retry = (retries: number, backoff: (attempt: number) => number = () => 0) =>
  async function <TOut, TArgs extends any[], TThis = void>(
    this: TThis,
    next: AsyncFunc<TOut, TArgs>,
    ...args: TArgs
  ): Promise<TOut> {
    let currentTry = 0;
    let lastError: Error;
    do {
      const wait = currentTry > 0 ? backoff(currentTry) : 0;
      if (wait > 0) {
        console.log('Waiting ' + wait + 'ms');
        await new Promise((res) => setTimeout(res, wait));
      }
      try {
        return await next(...args);
      } catch (err) {
        lastError = err instanceof Error ? err : new Error(JSON.stringify(err));
        currentTry++;
      }
    } while (currentTry <= retries);
    throw lastError;
  };
