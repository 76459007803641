import {
  IconLink,
  MYSTIC_60,
  OwnUpBodyMedium,
  OwnUpIcon,
  OwnUpSmallBody
} from '@rategravity/own-up-component-library';
import { SuccessDarkIcon } from '@rategravity/own-up-component-library/icon-library/notification-icons/success-dark';
import { EditIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/edit';
import React from 'react';
import styled from 'styled-components';

const EditContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const AccordionHeaderContainer = styled.div<{ open: boolean }>`
  border-radius: 8px 8px 0 0;
  background-color: ${MYSTIC_60};
  padding: 16px 24px;
  display: flex;
  flex-direction: ${({ open }) => (open ? 'column' : 'row')};
  align-items: ${({ open }) => (open ? 'flex-start' : 'center')};
  align-content: ${({ open }) => (open ? 'flex-start' : 'center')};
  box-shadow: 4px 4px 8px rgba(29, 29, 31, 0.05);
`;

export type AccordionHeaderProps = {
  open?: boolean;
  title: string;
  selected: boolean;
  onEdit: () => void;
  showOutsideOffer: boolean;
};

export const AccordionHeader = ({
  open = false,
  title,
  selected,
  onEdit,
  showOutsideOffer
}: AccordionHeaderProps) => (
  <AccordionHeaderContainer open={open}>
    <React.Fragment>
      {selected && !open && (
        <OwnUpIcon icon={SuccessDarkIcon} height="24px" style={{ marginRight: '8px' }} />
      )}
      <OwnUpBodyMedium variant="body1">{title}</OwnUpBodyMedium>
      {open ? (
        <OwnUpSmallBody variant="body1" style={{ marginTop: '8px' }}>
          {showOutsideOffer
            ? 'Enter an outside offer, or add another Own Up offer to compare.'
            : 'Select an offer to compare'}
        </OwnUpSmallBody>
      ) : (
        <EditContainer>
          <IconLink icon={EditIcon} link="#" onClick={onEdit} iconPlacement="left">
            Edit
          </IconLink>
        </EditContainer>
      )}
    </React.Fragment>
  </AccordionHeaderContainer>
);
