import {
  MYSTIC_20,
  MYSTIC_40,
  OwnUpLargeBody,
  WALDEN_100
} from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';
import { LetterIcon } from '../../../assets/letter';
import TagSVG from '../../../assets/tag.svg';
import TalkSVG from '../../../assets/talk.svg';
import { FTUTemplate } from './template';

const AnimationWrapper = styled('div')`
  div:nth-child(2) {
    animation-delay: 1.2s;
  }
  div:nth-child(3) {
    animation-delay: 2.2s;
  }
`;

const StepDiv = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  background-color: ${MYSTIC_20};
  padding: 24px;
  margin-bottom: 12px;
  animation: box-shadow 3.6s;
  @keyframes box-shadow {
    50% {
      box-shadow: 4px 4px 8px rgba(29, 29, 31, 0.2);
    }
    100% {
      box-shadow: 0px;
    }
  }
`;

const StepCopy = styled(OwnUpLargeBody)`
  padding-left: 24px;
`;

const IconWrapper = styled('div')`
  background-color: ${MYSTIC_40};
  height: 64px;
  min-width: 64px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Step = ({
  icon,
  iconHeight,
  iconWidth,
  copy
}: {
  icon: string | JSX.Element;
  iconHeight?: number;
  iconWidth?: number;
  copy: string;
}) => (
  <StepDiv>
    <IconWrapper>
      {typeof icon === 'string' ? (
        <img src={icon} alt="" height={iconHeight} width={iconWidth} />
      ) : (
        icon
      )}
    </IconWrapper>
    <StepCopy variant="body1">{copy}</StepCopy>
  </StepDiv>
);

export const Page4 = () => (
  <FTUTemplate
    title="What&#39;s Next?"
    body=""
    content={
      <AnimationWrapper>
        <Step icon={<LetterIcon color={WALDEN_100} />} copy="Review your offers" />
        <Step
          icon={TalkSVG}
          iconHeight={20}
          iconWidth={28}
          copy="Schedule a call to confirm your information and get personalized advice"
        />
        <Step
          icon={TagSVG}
          iconHeight={30}
          iconWidth={26}
          copy="Lock in your offer whenever you&#39;re ready"
        />
      </AnimationWrapper>
    }
  />
);
