import { composeReducers, ofType, withDefault } from 'redux-compose';
import {
  CLEAR_LOCK_STATUS_ACTION_TYPE,
  ClearLockStatusAction,
  LOCK_OFFER_ACTION_TYPE,
  LOCK_OFFER_ERROR_ACTION_TYPE,
  LOCK_OFFER_SUCCESS_ACTION_TYPE,
  LockOfferAction,
  LockOfferErrorAction,
  LockOfferSuccessAction
} from '../../../redux/published-rates/actions';
import { ErroredLoadedRate, LoadedRate, RatesState, UnLoadedRate } from '../state';
import {
  DataLoadedAction,
  dataLoadedActionType,
  ErrorLoadingAction,
  errorLoadingActionType,
  LoadAction,
  loadActionType
} from './actions';

export { loadAction } from './actions';
export { saga } from './saga';

export const reducer = composeReducers(
  withDefault<RatesState>({ allRates: {} }),
  ofType(
    dataLoadedActionType,
    (state: RatesState, { payload }: DataLoadedAction): RatesState => ({
      allRates: {
        ...state.allRates,
        [payload.id]: { ...payload, loading: false, error: false, lockStatus: null } as LoadedRate
      }
    })
  ),
  ofType(
    errorLoadingActionType,
    (state: RatesState, { payload }: ErrorLoadingAction): RatesState => ({
      allRates: {
        ...state.allRates,
        [payload.id]: {
          ...payload,
          loading: false,
          error: true,
          lockStatus: null
        } as ErroredLoadedRate
      }
    })
  ),
  ofType(
    loadActionType,
    (state: RatesState, { payload }: LoadAction): RatesState => ({
      allRates: {
        ...state.allRates,
        [payload.id]: {
          loading: true,
          error: false,
          lockStatus: null
        } as UnLoadedRate
      }
    })
  ),
  ofType(
    LOCK_OFFER_ACTION_TYPE,
    (state: RatesState, { payload }: LockOfferAction): RatesState => ({
      allRates: {
        ...state.allRates,
        [payload.id]: {
          ...state.allRates[payload.id],
          lockStatus: 'loading',
          lockedOfferId: null
        } as LoadedRate
      }
    })
  ),
  ofType(
    LOCK_OFFER_SUCCESS_ACTION_TYPE,
    (state: RatesState, { payload }: LockOfferSuccessAction): RatesState => ({
      allRates: {
        ...state.allRates,
        [payload.id]: {
          ...state.allRates[payload.id],
          lockStatus: 'success',
          lockedOfferId: payload.offerId
        } as LoadedRate
      }
    })
  ),
  ofType(
    LOCK_OFFER_ERROR_ACTION_TYPE,
    (state: RatesState, { payload }: LockOfferErrorAction): RatesState => ({
      allRates: {
        ...state.allRates,
        [payload.id]: {
          ...state.allRates[payload.id],
          lockStatus: 'error',
          lockedOfferId: null
        } as LoadedRate
      }
    })
  ),
  ofType(
    CLEAR_LOCK_STATUS_ACTION_TYPE,
    (state: RatesState, { payload }: ClearLockStatusAction): RatesState => ({
      allRates: {
        ...state.allRates,
        [payload.id]: {
          ...state.allRates[payload.id],
          lockStatus: null,
          lockedOfferId: null
        } as LoadedRate
      }
    })
  )
);
