import { PropsWithTheme } from '@rategravity/own-up-component-library';
import { OwnUpAdvisorStack } from '@rategravity/own-up-component-library-legacy';
import React from 'react';
import styled from 'styled-components';
import { GreenDotText } from '../green-dot-text';

export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

const Container = styled.div<{ columnBreakpoint: Breakpoint; border?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ theme, columnBreakpoint }) => theme.breakpoints.down(columnBreakpoint)} {
    flex-direction: column;
    align-items: flex-start;
  }
  /* first-child is the div from OwnUpAdvisorStack */
  > :first-child {
    flex-shrink: 0;
    height: 48px;
    /* images overlap by 10px, this give space for the '+1' placeholder 48-10=38 */
    margin: 0 38px 0 0;

    ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('xs')} {
      margin: 0;
    }
    > img {
      ${({ border }) => border && `border: ${border}`};
      height: 48px !important;
      width: 48px !important;
      :last-child {
        margin-left: -10px;
      }
    }
  }
`;

export const AvailableAdvisors = ({
  advisors,
  numberToShow = 3,
  columnBreakpoint = 'xs',
  border
}: {
  advisors: string[];
  numberToShow?: number;
  columnBreakpoint?: Breakpoint;
  border?: string;
}) => (
  <Container columnBreakpoint={columnBreakpoint} border={border}>
    <OwnUpAdvisorStack advisors={advisors} advisorCount={numberToShow} />
    <GreenDotText>Available this week</GreenDotText>
  </Container>
);
