import { currency, rate as formatRate } from '@rategravity/frontend/modules/numbers';
import { OwnUpSmallBody, OwnUpSmallBodyMedium } from '@rategravity/own-up-component-library';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { isNonZeroish } from '../../modules/non-zeroish';

const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  border: none;
  width: 100%;
`;

const Row = styled.div<{ margin: string }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: ${({ margin }) => margin};
  width: 100%;
`;

const Lender = styled.div`
  display: flex;
  align-items: center;
`;

const RateCardRow = ({ title, value }: { title: string; value: string }) => (
  <Row margin="8px 0 0">
    <OwnUpSmallBody variant="body1">{title}</OwnUpSmallBody>
    <OwnUpSmallBodyMedium variant="body1">{value}</OwnUpSmallBodyMedium>
  </Row>
);

export interface RateCardProps {
  rate: number;
  apr: number;
  lenderName: string;
  squareLogo: string;
  points: number;
  lenderCredit: number;
  pAndI: number;
  lenderFees: number;
  interestOnly: boolean;
}

export const RateCard = ({
  rate,
  apr,
  lenderName,
  squareLogo,
  decoration,
  lenderCredit,
  points,
  pAndI,
  interestOnly,
  lenderFees
}: RateCardProps & { decoration?: ReactNode }) => (
  <Card>
    <Row margin="0 0 12px">
      <Lender>
        {!!squareLogo && (
          <img
            src={squareLogo}
            alt={`${lenderName} logo`}
            style={{ marginRight: '8px', height: '20px', width: 'auto' }}
          />
        )}
        <OwnUpSmallBody variant="body1">{lenderName}</OwnUpSmallBody>
      </Lender>
      {decoration ?? null}
    </Row>
    <RateCardRow title="Rate / APR" value={`${formatRate(rate, 3)} / ${formatRate(apr, 3)}`} />
    <RateCardRow
      title={`Mo. ${interestOnly ? 'Interest' : 'Principal & Interest'}`}
      value={currency(pAndI, 0)}
    />
    {isNonZeroish(lenderCredit) && (
      <RateCardRow title="Lender credits" value={currency(lenderCredit, 0)} />
    )}
    {isNonZeroish(points) && <RateCardRow title="Points" value={currency(points, 0)} />}
    <RateCardRow title="Lender fees" value={currency(lenderFees)} />
  </Card>
);
