import {
  FOG_100,
  FOG_20,
  OwnUpGridContainer,
  OwnUpGridItem,
  ownUpLightTheme,
  OwnUpSideModal,
  OwnUpThemeProvider
} from '@rategravity/own-up-component-library';
import React, { useReducer, useState } from 'react';
import styled from 'styled-components';
import { OfferHeader } from '../../components/comparison-shopping/header';
import {
  OfferAnalysis,
  OfferAnalysisProps
} from '../../components/comparison-shopping/offer-analysis';
import {
  OffersAccordion,
  OffersAccordionProps
} from '../../components/comparison-shopping/offers-accordion';
import {
  OfferComparisonCTA,
  OutsideOfferCTA,
  OutsideOfferCTAProps
} from '../../views/schedule-call-cta/offer-comparison-cta';

const StyledModal = styled(OwnUpSideModal)`
  .modal-body {
    padding: 0;
    max-width: max(100vw, 480px);
    width: fill-available;
    margin-left: 48px;
    :focus {
      outline: none;
    }
    ${({ theme }) => theme.breakpoints.up('lg')} {
      width: 960px;
      margin-left: 0;
    }
    ${({ theme }) => theme.breakpoints.down('xs')} {
      margin-left: 0;
      max-height: calc(100% - 24px);
    }
    > h2.MuiTypography-h2 {
      margin: 48px 0 40px 40px;
      ${({ theme }) => theme.breakpoints.down('xs')} {
        margin: 32px 0 24px 24px;
      }
    }
    > .modal-exit-button {
      margin: 24px 24px 0 0;
    }
    > .modal-content {
      padding: 0;
    }
  }
`;

const ModalBody = styled.div`
  height: 100%;
  border-top: 1px solid ${FOG_100};
  ${({ theme }) => theme.breakpoints.down('sm')} {
    background-color: ${FOG_20};
  }
`;

export interface ComparePageProps
  extends Omit<
      OffersAccordionProps,
      | 'openableSections'
      | 'offersAccordionDispatch'
      | 'tab'
      | 'setTab'
      | 'showOutsideOffer'
      | 'setScrollingToCta'
    >,
    Omit<OutsideOfferCTAProps, 'setScrollingToCta' | 'isScrollingToCta'>,
    Pick<OfferAnalysisProps, 'analysis'> {
  onClose: () => void;
}

export const ComparePage = ({
  onClose,
  ycbmLink,
  licensedAdvisors,
  event,
  analysis,
  sections,
  licensedState,
  ...accordionProps
}: ComparePageProps & { licensedState: boolean }) => {
  const [tab, setTab] = useState<number>(0);
  const [isScrollingToAnalysis, setScrollingToAnalysis] = useState(false);
  const [isScrollingToCta, setScrollingToCta] = useState(false);
  const cta = (
    <OwnUpThemeProvider theme={ownUpLightTheme}>
      {tab === 0 ? (
        <OfferComparisonCTA ycbmLink={ycbmLink} event={event} />
      ) : (
        <OutsideOfferCTA
          ycbmLink={ycbmLink}
          event={event}
          licensedAdvisors={licensedAdvisors}
          setScrollingToCta={setScrollingToCta}
          isScrollingToCta={isScrollingToCta}
        />
      )}
    </OwnUpThemeProvider>
  );
  const [openableSections, offersAccordionDispatch] = useReducer(
    (state: boolean[], action: { type: 'edit' | 'select'; index: number }) => {
      const newState = state.map((open, index) => {
        if (action.type === 'edit') {
          return action.index === index || sections[index]?.selectedId == null;
        }
        // automatically close the section that we just selected
        return action.index === index ? false : open;
      });
      // if the second accordion is now closed reset the tab back to 0
      if (!newState[1]) {
        setTab(0);
      }
      // if action was select and both accordions are closed, scroll to analysis section
      if (action.type === 'select' && !newState[0] && !newState[1]) {
        setScrollingToAnalysis(true);
      }
      return newState;
    },
    sections.map((section) => section.selectedId == null)
  );

  return (
    <StyledModal onClose={onClose} open={true} title="Compare offers">
      <ModalBody>
        <OwnUpGridContainer variant="slim" spacing={0}>
          <OwnUpGridItem xs={12} md={0}>
            <OfferHeader />
          </OwnUpGridItem>
          <OwnUpGridItem xs={12} md={6} lg={5}>
            <OffersAccordion
              {...accordionProps}
              event={event}
              openableSections={openableSections}
              offersAccordionDispatch={offersAccordionDispatch}
              sections={sections}
              tab={tab}
              setTab={setTab}
              setScrollingToCta={setScrollingToCta}
            />
          </OwnUpGridItem>
          <OwnUpGridItem xs={12} md={6} lg={7}>
            <OfferAnalysis
              event={event}
              cta={cta}
              analysis={analysis}
              isScrollingToAnalysis={isScrollingToAnalysis}
              setScrollingToAnalysis={setScrollingToAnalysis}
              tab={tab}
            />
          </OwnUpGridItem>
        </OwnUpGridContainer>
      </ModalBody>
    </StyledModal>
  );
};
