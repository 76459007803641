import React from 'react';

export const LetterIcon = ({
  color,
  height = 24,
  width = 20,
  viewBox = '0 0 20 24'
}: {
  color: string;
  height?: number;
  width?: number;
  viewBox?: string;
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.37109 6C4.37109 5.65482 4.65092 5.375 4.99609 5.375L14.9961 5.375C15.3413 5.375 15.6211 5.65482 15.6211 6C15.6211 6.34518 15.3413 6.625 14.9961 6.625L4.99609 6.625C4.65092 6.625 4.37109 6.34518 4.37109 6Z"
      fill={color}
    />
    <path
      d="M4.99609 9.375C4.65092 9.375 4.37109 9.65482 4.37109 10C4.37109 10.3452 4.65092 10.625 4.99609 10.625L14.9961 10.625C15.3413 10.625 15.6211 10.3452 15.6211 10C15.6211 9.65482 15.3413 9.375 14.9961 9.375L4.99609 9.375Z"
      fill={color}
    />
    <path
      d="M4.37109 14C4.37109 13.6548 4.65092 13.375 4.99609 13.375L14.9961 13.375C15.3413 13.375 15.6211 13.6548 15.6211 14C15.6211 14.3452 15.3413 14.625 14.9961 14.625L4.99609 14.625C4.65092 14.625 4.37109 14.3452 4.37109 14Z"
      fill={color}
    />
    <path
      d="M4.99609 17.375C4.65092 17.375 4.37109 17.6548 4.37109 18C4.37109 18.3452 4.65092 18.625 4.99609 18.625H9.99609C10.3413 18.625 10.6211 18.3452 10.6211 18C10.6211 17.6548 10.3413 17.375 9.99609 17.375H4.99609Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 0C0.895431 0 0 0.89543 0 2V22C0 23.1046 0.895431 24 2 24H18C19.1046 24 20 23.1046 20 22V2C20 0.895431 19.1046 0 18 0H2ZM18 1.5H2C1.72386 1.5 1.5 1.72386 1.5 2V22C1.5 22.2761 1.72386 22.5 2 22.5H18C18.2761 22.5 18.5 22.2761 18.5 22V2C18.5 1.72386 18.2761 1.5 18 1.5Z"
      fill={color}
    />
  </svg>
);
