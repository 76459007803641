import React from 'react';
import * as colors from '../../../colors';
import { useRandomId } from '../../../hooks/use-random-id';

export const EditIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1545:46643)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9942 2.59415C15.2132 1.8131 13.9469 1.8131 13.1658 2.59415L2.95411 12.8059C2.812 12.948 2.71601 13.1296 2.67865 13.3271L2.01868 16.8155C1.88769 17.5078 2.49476 18.1149 3.18715 17.9839L6.67554 17.324C6.87301 17.2866 7.05464 17.1906 7.19675 17.0485L17.4085 6.83679C18.1895 6.05574 18.1895 4.78941 17.4085 4.00836L15.9942 2.59415ZM16.3478 5.06902L14.9336 3.65481C14.7383 3.45955 14.4217 3.45955 14.2265 3.65481L13.3426 4.53869L15.4639 6.66001L16.3478 5.77613C16.5431 5.58087 16.5431 5.26428 16.3478 5.06902ZM5.21479 12.6665L12.2819 5.59935L14.4033 7.72067L7.33612 14.7878C7.1418 14.4162 6.85274 13.9587 6.4483 13.5543C6.04387 13.1499 5.58638 12.8608 5.21479 12.6665ZM4.11415 13.8086L3.62886 16.3737L6.19398 15.8885C6.16069 15.8054 6.11516 15.6998 6.05669 15.581C5.90631 15.2756 5.68434 14.9117 5.38764 14.615C5.09095 14.3183 4.72702 14.0963 4.42158 13.9459C4.3028 13.8875 4.19722 13.8419 4.11415 13.8086Z"
        fill={colors.CHARCOAL_100}
      />
    </g>
    <defs>
      <clipPath id={useRandomId('clip')}>
        <rect width="16" height="16" transform="translate(2 1.99994)" />
      </clipPath>
    </defs>
  </svg>
);
