import {
  ALOE_100,
  CACTUS_100,
  FOG_20,
  FOG_60,
  OwnUpBody,
  OwnUpOverline,
  OwnUpSmallBody,
  OwnUpSmallBodyMedium,
  OwnUpUrgentErrorNotification,
  OwnUpUrgentSuccessNotification,
  SLATE_100,
  WHITE_100
} from '@rategravity/own-up-component-library';
import { LoadingSpinner } from '@rategravity/own-up-component-library-legacy/components/images/icons/svgs/loading-spinner';
import React from 'react';
import styled from 'styled-components';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  align-items: center;
`;

const Circle = styled.div<{ completed: boolean }>`
  border-radius: 50%;
  padding: 2px 7.5px;
  background-color: ${({ completed }) => (completed ? ALOE_100 : FOG_60)};
  margin-right: 12px;
`;

const NextStepItem = ({
  n,
  text,
  completed = false
}: {
  n: number;
  text: string;
  completed?: boolean;
}) => (
  <Row>
    <Circle completed={completed}>
      <OwnUpSmallBodyMedium
        variant="body1"
        style={{ ...(completed ? { color: WHITE_100 } : { color: SLATE_100 }) }}
      >
        {n}
      </OwnUpSmallBodyMedium>
    </Circle>
    <OwnUpSmallBody variant="body1" style={{ ...(completed ? {} : { color: SLATE_100 }) }}>
      {text}
    </OwnUpSmallBody>
  </Row>
);

const Container = styled.div`
  background-color: ${FOG_20};
  border-radius: 8px;
  padding: 24px;
  margin-top: 32px;
`;

export const Success = () => (
  <>
    <OwnUpUrgentSuccessNotification
      title="Your lock request has been sent!"
      description="Your rate lock request is pending and is not final until the lender application is received. We have notified your advisor who will be in touch with you shortly."
      $hasIcon={true}
    />
    <Container>
      <OwnUpOverline>NEXT STEPS</OwnUpOverline>
      <NextStepItem n={1} text="Submit lock request" completed />
      <NextStepItem n={2} text="Advisor introduces you to your lender" />
      <NextStepItem n={3} text="Complete lender application" />
      <NextStepItem n={4} text="Confirmation of locked rate" />
    </Container>
  </>
);

export const Error = () => (
  <OwnUpUrgentErrorNotification
    description="You may have already submitted a lock request or another error occurred. Please contact your advisor for more details."
    $hasIcon={true}
  />
);

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-left: 24px;
`;

const LoadingWrapper = styled.div`
  height: 44px;
  width: 44px;
  margin-bottom: 16px;
`;

export const Loading = () => (
  <LoadingContainer>
    <LoadingWrapper>
      <LoadingSpinner color={CACTUS_100} secondaryColor={FOG_60} />
    </LoadingWrapper>
    <OwnUpBody variant="body1">Submitting your request...</OwnUpBody>
  </LoadingContainer>
);
