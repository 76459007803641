import React, { useCallback, useEffect, useRef } from 'react';
import { useScrollTo } from '../../../../components/scrollable-wrapper';
import { useIntersectionObserver } from '../../../../hooks/use-intersection';
import { BottomOffsetElement } from '../../scrollable-menus/horizontal';

const getTopOffset = (el: HTMLElement) =>
  el.getBoundingClientRect().top + window.pageYOffset || document.documentElement.scrollTop;

export const ScrollTarget = ({
  active,
  menuRef,
  name = '',
  onVisible,
  scrollIfVisible = false,
  children
}: React.PropsWithChildren<{
  active: boolean;
  menuRef?: React.RefObject<BottomOffsetElement>;
  name?: string;
  onVisible: (n: string) => void;
  scrollIfVisible?: boolean;
}>) => {
  const ref = useRef<HTMLDivElement>(null);
  const isIntersecting = useRef(false);
  const scrollTo = useScrollTo();

  useIntersectionObserver(
    ref,
    useCallback(
      (intersections) => {
        if (
          intersections.length > 0 &&
          intersections.reduce((acc, curr) => (curr.time > acc.time ? curr : acc)).isIntersecting
        ) {
          onVisible(name);
          isIntersecting.current = true;
        } else {
          isIntersecting.current = false;
        }
      },
      [isIntersecting, onVisible, name]
    )
  );
  useEffect(() => {
    if (active && ref.current && (!isIntersecting.current || scrollIfVisible)) {
      scrollTo.scrollTo({
        top:
          getTopOffset(ref.current) -
          (menuRef && menuRef.current ? menuRef.current.getBottomOffset() : 0)
      });
    }
  }, [active, ref, menuRef, isIntersecting, scrollTo, scrollIfVisible]);
  return <div ref={ref}>{children}</div>;
};
