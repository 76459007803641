import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OutsideOffer, OutsideOffersState } from './state';

export const outsideOffersSlice = createSlice({
  name: 'outsideOffers',
  initialState: { pending: false, offerSubmitted: null, outsideOffer: null } as OutsideOffersState,
  reducers: {
    fetchStatusSuccess: (
      s,
      {
        payload: { offerSubmitted, outsideOffer }
      }: PayloadAction<Pick<OutsideOffersState, 'outsideOffer' | 'offerSubmitted'>>
    ) => ({
      ...s,
      offerSubmitted,
      outsideOffer
    }),
    addOutsideOffer: (s, _: PayloadAction<{ outsideOffer: OutsideOffer }>) => ({
      ...s,
      pending: true
    }),
    addOutsideOfferSuccess: (
      s,
      { payload: { outsideOffer } }: PayloadAction<{ outsideOffer: OutsideOffer }>
    ) => ({
      ...s,
      pending: false,
      offerSubmitted: true,
      outsideOffer
    })
  }
});

export const { fetchStatusSuccess, addOutsideOffer, addOutsideOfferSuccess } =
  outsideOffersSlice.actions;

export const reducer = outsideOffersSlice.reducer;
