import { createSelector } from 'reselect';
import { propertyStateSelector } from '../../rates/redux/selector/scenario';
import { pitchScenarioSelector } from '../manifest/selector';

/**
 * Determines which state booking pool would be used if a consult
 *   were pitched.
 */
export const consultStateSelector = createSelector(
  pitchScenarioSelector,
  propertyStateSelector,
  (q, r) => q.value?.propertyState || r.value || ''
);
