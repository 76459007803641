export const FETCH_PUBLISHED_RATES_ACTION_TYPE = 'PUBLISHED_RATES__FETCH_ACTION' as const;
export const fetchPublishedRates = (accountId: string, rateQuoteRequestId: string) => ({
  type: FETCH_PUBLISHED_RATES_ACTION_TYPE,
  payload: {
    accountId,
    rateQuoteRequestId
  }
});
export type FetchPublishedRatesAction = ReturnType<typeof fetchPublishedRates>;

export const LOCK_OFFER_ACTION_TYPE = 'LOCK_OFFER' as const;
export const lockOffer = (accountId: string, rateQuoteRequestId: string, offerId: string) => ({
  type: LOCK_OFFER_ACTION_TYPE,
  payload: {
    accountId,
    id: rateQuoteRequestId,
    offerId
  }
});
export type LockOfferAction = ReturnType<typeof lockOffer>;

export const LOCK_OFFER_SUCCESS_ACTION_TYPE = 'LOCK_SUCCESS' as const;
export const lockOfferSuccess = (id: string, offerId: string) => ({
  type: LOCK_OFFER_SUCCESS_ACTION_TYPE,
  payload: {
    id,
    offerId
  }
});
export type LockOfferSuccessAction = ReturnType<typeof lockOfferSuccess>;

export const LOCK_OFFER_ERROR_ACTION_TYPE = 'LOCK_ERROR' as const;
export const lockOfferError = (id: string) => ({
  type: LOCK_OFFER_ERROR_ACTION_TYPE,
  payload: {
    id
  }
});
export type LockOfferErrorAction = ReturnType<typeof lockOfferError>;

export const CLEAR_LOCK_STATUS_ACTION_TYPE = 'CLEAR_LOCK_STATUS' as const;
export const clearLockStatus = (id: string) => ({
  type: CLEAR_LOCK_STATUS_ACTION_TYPE,
  payload: {
    id
  }
});
export type ClearLockStatusAction = ReturnType<typeof clearLockStatus>;
