import React from 'react';

export const Star100 = ({ fill }: { fill: string }) => (
  <svg
    role="presentation"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.28491 0.52931C5.50225 -0.176443 6.49776 -0.176431 6.71509 0.529312L7.78155 3.99243H11.2501C11.966 3.99243 12.2734 4.90419 11.7044 5.34031L8.86656 7.51579L9.94684 11.0238C10.162 11.7224 9.35646 12.2863 8.77739 11.8424L6 9.71325L3.22261 11.8424C2.64354 12.2863 1.83802 11.7224 2.05316 11.0238L3.13344 7.51579L0.295557 5.34031C-0.273358 4.90419 0.0339993 3.99243 0.749916 3.99243H4.21845L5.28491 0.52931Z"
      fill={fill}
    />
  </svg>
);

export const Star50 = ({
  emptyFill,
  fill,
  maskId = 'mask0_2540_22196'
}: {
  fill: string;
  emptyFill: string;
  maskId?: string;
}) => (
  <svg
    role="presentation"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id={maskId} maskUnits="userSpaceOnUse" x="0" y="0" width="12" height="12">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.28491 0.52931C5.50225 -0.176443 6.49776 -0.176431 6.71509 0.529312L7.78155 3.99243H11.2501C11.966 3.99243 12.2734 4.90419 11.7044 5.34031L8.86656 7.51579L9.94684 11.0238C10.162 11.7224 9.35646 12.2863 8.77739 11.8424L6 9.71325L3.22261 11.8424C2.64354 12.2863 1.83802 11.7224 2.05316 11.0238L3.13344 7.51579L0.295557 5.34031C-0.273358 4.90419 0.0339993 3.99243 0.749916 3.99243H4.21845L5.28491 0.52931Z"
        fill="#F8F9F9"
      />
    </mask>
    <g mask={`url(#${maskId})`}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.28491 0.52931C5.50225 -0.176443 6.49776 -0.176431 6.71509 0.529312L7.78155 3.99243H11.2501C11.966 3.99243 12.2734 4.90419 11.7044 5.34031L8.86656 7.51579L9.94684 11.0238C10.162 11.7224 9.35646 12.2863 8.77739 11.8424L6 9.71325L3.22261 11.8424C2.64354 12.2863 1.83802 11.7224 2.05316 11.0238L3.13344 7.51579L0.295557 5.34031C-0.273358 4.90419 0.0339993 3.99243 0.749916 3.99243H4.21845L5.28491 0.52931Z"
        fill={emptyFill}
      />
      <rect width="6" height="12" fill={fill} />
    </g>
  </svg>
);

export const Star75 = ({
  emptyFill,
  fill,
  maskId = 'mask0_2540_22196'
}: {
  fill: string;
  emptyFill: string;
  maskId?: string;
}) => (
  <svg
    role="presentation"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id={maskId} maskUnits="userSpaceOnUse" x="0" y="0" width="12" height="12">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.28491 0.52931C5.50225 -0.176443 6.49776 -0.176431 6.71509 0.529312L7.78155 3.99243H11.2501C11.966 3.99243 12.2734 4.90419 11.7044 5.34031L8.86656 7.51579L9.94684 11.0238C10.162 11.7224 9.35646 12.2863 8.77739 11.8424L6 9.71325L3.22261 11.8424C2.64354 12.2863 1.83802 11.7224 2.05316 11.0238L3.13344 7.51579L0.295557 5.34031C-0.273358 4.90419 0.0339993 3.99243 0.749916 3.99243H4.21845L5.28491 0.52931Z"
        fill="#F8F9F9"
      />
    </mask>
    <g mask={`url(#${maskId})`}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.28491 0.52931C5.50225 -0.176443 6.49776 -0.176431 6.71509 0.529312L7.78155 3.99243H11.2501C11.966 3.99243 12.2734 4.90419 11.7044 5.34031L8.86656 7.51579L9.94684 11.0238C10.162 11.7224 9.35646 12.2863 8.77739 11.8424L6 9.71325L3.22261 11.8424C2.64354 12.2863 1.83802 11.7224 2.05316 11.0238L3.13344 7.51579L0.295557 5.34031C-0.273358 4.90419 0.0339993 3.99243 0.749916 3.99243H4.21845L5.28491 0.52931Z"
        fill={emptyFill}
      />
      <rect width="8.86" height="12" fill={fill} />
    </g>
  </svg>
);

export const Star25 = ({
  emptyFill,
  fill,
  maskId = 'mask0_2540_22196'
}: {
  fill: string;
  emptyFill: string;
  maskId?: string;
}) => (
  <svg
    role="presentation"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id={maskId} maskUnits="userSpaceOnUse" x="0" y="0" width="12" height="12">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.28491 0.52931C5.50225 -0.176443 6.49776 -0.176431 6.71509 0.529312L7.78155 3.99243H11.2501C11.966 3.99243 12.2734 4.90419 11.7044 5.34031L8.86656 7.51579L9.94684 11.0238C10.162 11.7224 9.35646 12.2863 8.77739 11.8424L6 9.71325L3.22261 11.8424C2.64354 12.2863 1.83802 11.7224 2.05316 11.0238L3.13344 7.51579L0.295557 5.34031C-0.273358 4.90419 0.0339993 3.99243 0.749916 3.99243H4.21845L5.28491 0.52931Z"
        fill="#F8F9F9"
      />
    </mask>
    <g mask={`url(#${maskId})`}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.28491 0.52931C5.50225 -0.176443 6.49776 -0.176431 6.71509 0.529312L7.78155 3.99243H11.2501C11.966 3.99243 12.2734 4.90419 11.7044 5.34031L8.86656 7.51579L9.94684 11.0238C10.162 11.7224 9.35646 12.2863 8.77739 11.8424L6 9.71325L3.22261 11.8424C2.64354 12.2863 1.83802 11.7224 2.05316 11.0238L3.13344 7.51579L0.295557 5.34031C-0.273358 4.90419 0.0339993 3.99243 0.749916 3.99243H4.21845L5.28491 0.52931Z"
        fill={emptyFill}
      />
      <rect width="3.14" height="12" fill={fill} />
    </g>
  </svg>
);
