import { OwnUpSideModal, OwnUpThemeProvider } from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';
import { LockConfirmation } from '../../components/lock-request/lock-confirmation';
import { Error, Loading, Success } from '../../components/lock-request/lock-response';
import { LockRequest } from '../../redux/state';

const Modal = styled(OwnUpSideModal)`
  .modal-body {
    > .modal-content {
      margin-top: 24px;
      height: calc(100% - 32px);
      ${({ theme }) => theme.breakpoints.up('lg')} {
        margin-top: 32px;
      }
    }
    ${({ theme }) => theme.breakpoints.up('sm')} {
      width: 480px;
    }
    ${({ theme }) => theme.breakpoints.only('xs')} {
      height: calc(100% - 64px);
    }
  }
`;

const getLockContent = (
  data: LockRequest,
  onLock: () => void
): { title: string; content: React.ReactNode } => {
  switch (data.lockStatus) {
    case 'loading':
      return { title: '', content: <Loading /> };
    case 'success':
      return { title: "You're on your way to locking a great offer!", content: <Success /> };
    case 'error':
      return {
        title: 'Unfortunately, we were unable to submit your lock request.',
        content: <Error />
      };
    case null:
      return {
        title: 'Your selected loan option',
        content: <LockConfirmation data={data} onLock={onLock} />
      };
    default:
      return { title: '', content: null };
  }
};

export const LockOfferPage = ({
  offerLockData,
  onClose,
  onLock
}: {
  offerLockData: LockRequest;
  onClose: () => void;
  onLock: () => void;
}) => {
  const { title, content } = getLockContent(offerLockData, onLock);
  return (
    <OwnUpThemeProvider>
      <Modal title={title} onClose={onClose} open={true}>
        <>{content}</>
      </Modal>
    </OwnUpThemeProvider>
  );
};
