import {
  FOG_60,
  OwnUpIconButtonAccent,
  ownUpMidtoneTheme,
  OwnUpOverline,
  OwnUpThemeProvider,
  OwnUpTooltip,
  SAGE_100
} from '@rategravity/own-up-component-library';
import { MicroQuestionIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/micro-icons/question';
import React from 'react';
import styled from 'styled-components';

const IconContainer = styled.span`
  margin-left: 8px;
  display: flex;
`;

export const Tooltip = ({
  description,
  onOpen
}: {
  description: string | JSX.Element;
  onOpen?: () => void;
}) => (
  <OwnUpTooltip themeMode="dark" description={description} onOpen={onOpen}>
    <IconContainer>
      <OwnUpIconButtonAccent icon={MicroQuestionIcon} name="Help tooltip" size={1.25} />
    </IconContainer>
  </OwnUpTooltip>
);

const Container = styled.div`
  margin: 0 24px;
  padding: 32px 0;
  :not(:last-child) {
    border-bottom: 2px solid ${FOG_60};
  }
  ${({ theme }) => theme.breakpoints.up('lg')} {
    :last-child {
      padding: 32px 0 40px;
    }
  }
`;

export const TitleWrapper = styled.div<{ backgroundColor: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 4px;
  padding: 4px 8px 0;
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

export interface HighlightProps {
  title: string;
  body: JSX.Element;
  helpText?: string;
}

export const Highlight = ({ title, body, helpText }: HighlightProps) => (
  <Container>
    <TextContainer>
      <TitleWrapper backgroundColor={SAGE_100}>
        <OwnUpOverline>{title}</OwnUpOverline>
      </TitleWrapper>
      <OwnUpThemeProvider theme={ownUpMidtoneTheme}>
        {!!helpText && <Tooltip description={helpText} />}
      </OwnUpThemeProvider>
    </TextContainer>
    {body}
  </Container>
);
