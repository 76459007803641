import React from 'react';
import * as colors from '../../../colors';

export const StarFilledIcon = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.80546 1.87908C10.1679 0.703388 11.8321 0.703396 12.1945 1.87908L13.9733 7.64942H19.7747C20.9696 7.64942 21.4834 9.16534 20.5347 9.89184L15.7857 13.5286L17.5903 19.3829C17.949 20.5462 16.6023 21.4837 15.6358 20.7436L11 17.1935L6.36417 20.7436C5.39767 21.4837 4.05103 20.5462 4.40964 19.3829L6.21431 13.5286L1.46529 9.89184C0.516583 9.16533 1.03036 7.64942 2.22528 7.64942H8.02667L9.80546 1.87908Z"
      fill={colors.CHARCOAL_100}
    />
  </svg>
);
