import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { applyMiddleware, combineReducers, compose, createStore, Reducer } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { reducer as lendersReducer } from '../../redux/lenders/slice';
import { LendersState } from '../../redux/lenders/state';
import { notificationReducer } from '../../redux/notifications/reducer';
import { reducer as loadingReducer } from './loading';
import { history, middleware as routerMiddleware } from './router';
import { saga } from './saga';
import { RatesState } from './state';

const reducer = (h: History) =>
  combineReducers({
    router: connectRouter(h),
    rates: loadingReducer as Reducer<RatesState | undefined>,
    lenders: lendersReducer as Reducer<LendersState | undefined>,
    notifications: notificationReducer
  });

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
  reducer(history),
  composeEnhancers(applyMiddleware(sagaMiddleware), applyMiddleware(routerMiddleware))
);

sagaMiddleware.run(saga);
