import { of } from '@rategravity/core-lib/optional';
import { useAdvisors } from '@rategravity/own-up-component-library-legacy';
import React from 'react';
import { useSelector } from 'react-redux';
import { advisorSelector } from '../../redux/selector';
import { Advisor } from './reskin-advisor';

export const AdvisorView = ({ scheduleCallAction }: { scheduleCallAction: () => void }) => {
  const allAdvisors = useAdvisors();
  const advisor = useSelector(advisorSelector);

  return advisor
    .flatMap((id) => of(allAdvisors[id]).map((data) => ({ id, ...data })))
    .map(({ name, phone, id, email, nmls, photo }) => (
      <Advisor
        key={`advisor-${id}`}
        {...{ name, phone, email, nmls, photo, advisorId: id, scheduleCallAction }}
      />
    ))
    .orElse(() => <></>);
};
