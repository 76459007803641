import React, { Fragment } from 'react';
import styled from 'styled-components';

const AnimatedDot = styled.span<{ position: number }>`
  animation: ${({ position }) => `visibility${position}`} 3s linear infinite;

  @keyframes visibility${({ position }) => position} {
    0% {
      opacity: ${({ position }) => (position <= 0 ? 1 : 0)};
    }
    24% {
      opacity: ${({ position }) => (position <= 0 ? 1 : 0)};
    }
    25% {
      opacity: ${({ position }) => (position <= 1 ? 1 : 0)};
    }
    49% {
      opacity: ${({ position }) => (position <= 1 ? 1 : 0)};
    }
    50% {
      opacity: ${({ position }) => (position <= 2 ? 1 : 0)};
    }
    74% {
      opacity: ${({ position }) => (position <= 2 ? 1 : 0)};
    }
    75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
`;

export const AnimatedEllipsis = () => (
  <Fragment>
    <AnimatedDot position={0}>.</AnimatedDot>
    <AnimatedDot position={1}>.</AnimatedDot>
    <AnimatedDot position={2}>.</AnimatedDot>
  </Fragment>
);
