export const PUSH_NOTIFICATION_ACTION_TYPE = 'PUSH_NOTIFICATION' as const;
export const pushNotification = (message: string, delay?: number, color?: 'SUCCESS') => ({
  type: PUSH_NOTIFICATION_ACTION_TYPE,
  payload: {
    message,
    delay: delay ? delay : 2500,
    color,
    timestamp: Date.now()
  }
});
export type PushNotificationAction = ReturnType<typeof pushNotification>;
