import { Func } from './func';

export type Predicate<TArgs extends any[], TThis = void> = Func<boolean, TArgs, TThis>;
export type TypeGuard<TIn, TType extends TIn, TArgs extends any[], TThis = void> = (
  this: TThis,
  i: TIn,
  ...args: TArgs
) => i is TType;

/**
 * Given multiple predicates returns a predicate that returns true if all predicates return true
 * @returns a predicate that returns true if all given predicates return true
 */
export function and<TArgs extends any[], TThis = void>(
  ...predicates: [Predicate<TArgs, TThis>, Predicate<TArgs, TThis>, ...Predicate<TArgs, TThis>[]]
): Predicate<TArgs, TThis> {
  return function (this: TThis, ...args: TArgs): boolean {
    return predicates.every((p) => p.apply(this, args));
  };
}

/**
 * Given multiple type guards returns a type guard that matches the intersection of all types
 * @returns a type guard that matches if all given type guards match.
 */
export function intersect<
  TIn,
  TType1 extends TIn,
  TType2 extends TIn,
  TArgs extends any[],
  TThis = void
>(
  guard1: TypeGuard<TIn, TType1, TArgs, TThis>,
  guard2: TypeGuard<TIn, TType2, TArgs, TThis>
): TypeGuard<TIn, TType1 & TType2, TArgs, TThis>;
/**
 * Given multiple type guards returns a type guard that matches the intersection of all types
 * @returns a type guard that matches if all given type guards match.
 */
export function intersect<
  TIn,
  TType1 extends TIn,
  TType2 extends TIn,
  TType3 extends TIn,
  TArgs extends any[],
  TThis = void
>(
  guard1: TypeGuard<TIn, TType1, TArgs, TThis>,
  guard2: TypeGuard<TIn, TType2, TArgs, TThis>,
  guard3: TypeGuard<TIn, TType3, TArgs, TThis>
): TypeGuard<TIn, TType1 & TType2 & TType3, TArgs, TThis>;
/**
 * Given multiple type guards returns a type guard that matches the intersection of all types
 * @returns a type guard that matches if all given type guards match.
 */
export function intersect<
  TIn,
  TType1 extends TIn,
  TType2 extends TIn,
  TType3 extends TIn,
  TType4 extends TIn,
  TArgs extends any[],
  TThis = void
>(
  guard1: TypeGuard<TIn, TType1, TArgs, TThis>,
  guard2: TypeGuard<TIn, TType2, TArgs, TThis>,
  guard3: TypeGuard<TIn, TType3, TArgs, TThis>,
  guard4: TypeGuard<TIn, TType4, TArgs, TThis>
): TypeGuard<TIn, TType1 & TType2 & TType3 & TType4, TArgs, TThis>;
/**
 * Given multiple type guards returns a type guard that matches the intersection of all types
 * @returns a type guard that matches if all given type guards match.
 */
export function intersect<
  TIn,
  TType1 extends TIn,
  TType2 extends TIn,
  TType3 extends TIn,
  TType4 extends TIn,
  TType5 extends TIn,
  TArgs extends any[],
  TThis = void
>(
  guard1: TypeGuard<TIn, TType1, TArgs, TThis>,
  guard2: TypeGuard<TIn, TType2, TArgs, TThis>,
  guard3: TypeGuard<TIn, TType3, TArgs, TThis>,
  guard4: TypeGuard<TIn, TType4, TArgs, TThis>,
  guard5: TypeGuard<TIn, TType5, TArgs, TThis>
): TypeGuard<TIn, TType1 & TType2 & TType3 & TType4 & TType5, TArgs, TThis>;
/**
 * Given multiple type guards returns a type guard that matches the intersection of all types
 * @returns a type guard that matches if all given type guards match.
 */
export function intersect<
  TIn,
  TType1 extends TIn,
  TType2 extends TIn,
  TType3 extends TIn,
  TType4 extends TIn,
  TType5 extends TIn,
  TType6 extends TIn,
  TArgs extends any[],
  TThis = void
>(
  guard1: TypeGuard<TIn, TType1, TArgs, TThis>,
  guard2: TypeGuard<TIn, TType2, TArgs, TThis>,
  guard3: TypeGuard<TIn, TType3, TArgs, TThis>,
  guard4: TypeGuard<TIn, TType4, TArgs, TThis>,
  guard5: TypeGuard<TIn, TType5, TArgs, TThis>,
  guard6: TypeGuard<TIn, TType6, TArgs, TThis>
): TypeGuard<TIn, TType1 & TType2 & TType3 & TType4 & TType5 & TType6, TArgs, TThis>;
/**
 * Given multiple type guards returns a type guard that matches the intersection of all types
 * @returns a type guard that matches if all given type guards match.
 */
export function intersect<
  TIn,
  TType1 extends TIn,
  TType2 extends TIn,
  TType3 extends TIn,
  TType4 extends TIn,
  TType5 extends TIn,
  TType6 extends TIn,
  TType7 extends TIn,
  TArgs extends any[],
  TThis = void
>(
  guard1: TypeGuard<TIn, TType1, TArgs, TThis>,
  guard2: TypeGuard<TIn, TType2, TArgs, TThis>,
  guard3: TypeGuard<TIn, TType3, TArgs, TThis>,
  guard4: TypeGuard<TIn, TType4, TArgs, TThis>,
  guard5: TypeGuard<TIn, TType5, TArgs, TThis>,
  guard6: TypeGuard<TIn, TType6, TArgs, TThis>,
  guard7: TypeGuard<TIn, TType7, TArgs, TThis>
): TypeGuard<TIn, TType1 & TType2 & TType3 & TType4 & TType5 & TType6 & TType7, TArgs, TThis>;
/**
 * Given multiple type guards returns a type guard that matches the intersection of all types
 * @returns a type guard that matches if all given type guards match.
 */
export function intersect<
  TIn,
  TType1 extends TIn,
  TType2 extends TIn,
  TType3 extends TIn,
  TType4 extends TIn,
  TType5 extends TIn,
  TType6 extends TIn,
  TType7 extends TIn,
  TType8 extends TIn,
  TArgs extends any[],
  TThis = void
>(
  guard1: TypeGuard<TIn, TType1, TArgs, TThis>,
  guard2: TypeGuard<TIn, TType2, TArgs, TThis>,
  guard3: TypeGuard<TIn, TType3, TArgs, TThis>,
  guard4: TypeGuard<TIn, TType4, TArgs, TThis>,
  guard5: TypeGuard<TIn, TType5, TArgs, TThis>,
  guard6: TypeGuard<TIn, TType6, TArgs, TThis>,
  guard7: TypeGuard<TIn, TType7, TArgs, TThis>,
  guard8: TypeGuard<TIn, TType8, TArgs, TThis>
): TypeGuard<
  TIn,
  TType1 & TType2 & TType3 & TType4 & TType5 & TType6 & TType7 & TType8,
  TArgs,
  TThis
>;
/**
 * Given multiple type guards returns a type guard that matches the intersection of all types
 * @returns a type guard that matches if all given type guards match.
 */
export function intersect<
  TIn,
  TType1 extends TIn,
  TType2 extends TIn,
  TType3 extends TIn,
  TType4 extends TIn,
  TType5 extends TIn,
  TType6 extends TIn,
  TType7 extends TIn,
  TType8 extends TIn,
  TType9 extends TIn,
  TArgs extends any[],
  TThis = void
>(
  guard1: TypeGuard<TIn, TType1, TArgs, TThis>,
  guard2: TypeGuard<TIn, TType2, TArgs, TThis>,
  guard3: TypeGuard<TIn, TType3, TArgs, TThis>,
  guard4: TypeGuard<TIn, TType4, TArgs, TThis>,
  guard5: TypeGuard<TIn, TType5, TArgs, TThis>,
  guard6: TypeGuard<TIn, TType6, TArgs, TThis>,
  guard7: TypeGuard<TIn, TType7, TArgs, TThis>,
  guard8: TypeGuard<TIn, TType8, TArgs, TThis>,
  guard9: TypeGuard<TIn, TType9, TArgs, TThis>
): TypeGuard<
  TIn,
  TType1 & TType2 & TType3 & TType4 & TType5 & TType6 & TType7 & TType8 & TType9,
  TArgs,
  TThis
>;
/**
 * Given multiple type guards returns a type guard that matches the intersection of all types
 * @returns a type guard that matches if all given type guards match.
 */
export function intersect<
  TIn,
  TType1 extends TIn,
  TType2 extends TIn,
  TType3 extends TIn,
  TType4 extends TIn,
  TType5 extends TIn,
  TType6 extends TIn,
  TType7 extends TIn,
  TType8 extends TIn,
  TType9 extends TIn,
  TType10 extends TIn,
  TArgs extends any[],
  TThis = void
>(
  guard1: TypeGuard<TIn, TType1, TArgs, TThis>,
  guard2: TypeGuard<TIn, TType2, TArgs, TThis>,
  guard3: TypeGuard<TIn, TType3, TArgs, TThis>,
  guard4: TypeGuard<TIn, TType4, TArgs, TThis>,
  guard5: TypeGuard<TIn, TType5, TArgs, TThis>,
  guard6: TypeGuard<TIn, TType6, TArgs, TThis>,
  guard7: TypeGuard<TIn, TType7, TArgs, TThis>,
  guard8: TypeGuard<TIn, TType8, TArgs, TThis>,
  guard9: TypeGuard<TIn, TType9, TArgs, TThis>,
  guard10: TypeGuard<TIn, TType9, TArgs, TThis>
): TypeGuard<
  TIn,
  TType1 & TType2 & TType3 & TType4 & TType5 & TType6 & TType7 & TType8 & TType9 & TType10,
  TArgs,
  TThis
>;
/**
 * Given multiple type guards returns a type guard that matches the intersection of all types
 * @returns a type guard that matches if all given type guards match.
 */
export function intersect<
  TIn,
  TType1 extends TIn,
  TType2 extends TIn,
  TType3 extends TIn,
  TType4 extends TIn,
  TType5 extends TIn,
  TType6 extends TIn,
  TType7 extends TIn,
  TType8 extends TIn,
  TType9 extends TIn,
  TType10 extends TIn,
  TTypeRest extends TIn,
  TArgs extends any[],
  TThis = void
>(
  guard1: TypeGuard<TIn, TType1, TArgs, TThis>,
  guard2: TypeGuard<TIn, TType2, TArgs, TThis>,
  guard3: TypeGuard<TIn, TType3, TArgs, TThis>,
  guard4: TypeGuard<TIn, TType4, TArgs, TThis>,
  guard5: TypeGuard<TIn, TType5, TArgs, TThis>,
  guard6: TypeGuard<TIn, TType6, TArgs, TThis>,
  guard7: TypeGuard<TIn, TType7, TArgs, TThis>,
  guard8: TypeGuard<TIn, TType8, TArgs, TThis>,
  guard9: TypeGuard<TIn, TType9, TArgs, TThis>,
  guard10: TypeGuard<TIn, TType9, TArgs, TThis>,
  ...guardRest: TypeGuard<TIn, TTypeRest, TArgs, TThis>[]
): TypeGuard<
  TIn,
  TType1 &
    TType2 &
    TType3 &
    TType4 &
    TType5 &
    TType6 &
    TType7 &
    TType8 &
    TType9 &
    TType10 &
    TTypeRest,
  TArgs,
  TThis
>;
/**
 * Given multiple type guards returns a type guard that matches the intersection of all types
 * @returns a type guard that matches if all given type guards match.
 */
export function intersect<
  TIn,
  TType1 extends TIn,
  TType2 extends TIn,
  TTypeRest extends TIn,
  TArgs extends any[],
  TThis = void
>(
  ...guards: [
    TypeGuard<TIn, TType1, TArgs, TThis>,
    TypeGuard<TIn, TType2, TArgs, TThis>,
    ...TypeGuard<TIn, TTypeRest, TArgs, TThis>[]
  ]
): TypeGuard<TIn, TType1 & TType2 & TTypeRest, TArgs, TThis> {
  return and(...guards) as unknown as TypeGuard<TIn, TType1 & TType2 & TTypeRest, TArgs, TThis>;
}

/**
 * Given multiple predicates returns a predicate that returns true if any predicates return true
 * @returns a predicate that returns true if any given predicate returns true
 */
export function or<TArgs extends any[], TThis = void>(
  ...predicates: [Predicate<TArgs, TThis>, Predicate<TArgs, TThis>, ...Predicate<TArgs, TThis>[]]
): Predicate<TArgs, TThis> {
  return function (this: TThis, ...args: TArgs): boolean {
    return predicates.some((p) => p.apply(this, args));
  };
}

/**
 * Given multiple type guards returns a type guard that matches the union of all types
 * @returns a type guard that matches if any given type guard matches.
 */
export function union<
  TIn,
  TType1 extends TIn,
  TType2 extends TIn,
  TArgs extends any[],
  TThis = void
>(
  guard1: TypeGuard<TIn, TType1, TArgs, TThis>,
  guard2: TypeGuard<TIn, TType2, TArgs, TThis>
): TypeGuard<TIn, TType1 | TType2, TArgs, TThis>;
/**
 * Given multiple type guards returns a type guard that matches the union of all types
 * @returns a type guard that matches if any given type guard matches.
 */
export function union<
  TIn,
  TType1 extends TIn,
  TType2 extends TIn,
  TType3 extends TIn,
  TArgs extends any[],
  TThis = void
>(
  guard1: TypeGuard<TIn, TType1, TArgs, TThis>,
  guard2: TypeGuard<TIn, TType2, TArgs, TThis>,
  guard3: TypeGuard<TIn, TType3, TArgs, TThis>
): TypeGuard<TIn, TType1 | TType2 | TType3, TArgs, TThis>;
/**
 * Given multiple type guards returns a type guard that matches the union of all types
 * @returns a type guard that matches if any given type guard matches.
 */
export function union<
  TIn,
  TType1 extends TIn,
  TType2 extends TIn,
  TType3 extends TIn,
  TType4 extends TIn,
  TArgs extends any[],
  TThis = void
>(
  guard1: TypeGuard<TIn, TType1, TArgs, TThis>,
  guard2: TypeGuard<TIn, TType2, TArgs, TThis>,
  guard3: TypeGuard<TIn, TType3, TArgs, TThis>,
  guard4: TypeGuard<TIn, TType4, TArgs, TThis>
): TypeGuard<TIn, TType1 | TType2 | TType3 | TType4, TArgs, TThis>;
/**
 * Given multiple type guards returns a type guard that matches the union of all types
 * @returns a type guard that matches if any given type guard matches.
 */
export function union<
  TIn,
  TType1 extends TIn,
  TType2 extends TIn,
  TType3 extends TIn,
  TType4 extends TIn,
  TType5 extends TIn,
  TArgs extends any[],
  TThis = void
>(
  guard1: TypeGuard<TIn, TType1, TArgs, TThis>,
  guard2: TypeGuard<TIn, TType2, TArgs, TThis>,
  guard3: TypeGuard<TIn, TType3, TArgs, TThis>,
  guard4: TypeGuard<TIn, TType4, TArgs, TThis>,
  guard5: TypeGuard<TIn, TType5, TArgs, TThis>
): TypeGuard<TIn, TType1 | TType2 | TType3 | TType4 | TType5, TArgs, TThis>;
/**
 * Given multiple type guards returns a type guard that matches the union of all types
 * @returns a type guard that matches if any given type guard matches.
 */
export function union<
  TIn,
  TType1 extends TIn,
  TType2 extends TIn,
  TType3 extends TIn,
  TType4 extends TIn,
  TType5 extends TIn,
  TType6 extends TIn,
  TArgs extends any[],
  TThis = void
>(
  guard1: TypeGuard<TIn, TType1, TArgs, TThis>,
  guard2: TypeGuard<TIn, TType2, TArgs, TThis>,
  guard3: TypeGuard<TIn, TType3, TArgs, TThis>,
  guard4: TypeGuard<TIn, TType4, TArgs, TThis>,
  guard5: TypeGuard<TIn, TType5, TArgs, TThis>,
  guard6: TypeGuard<TIn, TType6, TArgs, TThis>
): TypeGuard<TIn, TType1 | TType2 | TType3 | TType4 | TType5 | TType6, TArgs, TThis>;
/**
 * Given multiple type guards returns a type guard that matches the union of all types
 * @returns a type guard that matches if any given type guard matches.
 */
export function union<
  TIn,
  TType1 extends TIn,
  TType2 extends TIn,
  TType3 extends TIn,
  TType4 extends TIn,
  TType5 extends TIn,
  TType6 extends TIn,
  TType7 extends TIn,
  TArgs extends any[],
  TThis = void
>(
  guard1: TypeGuard<TIn, TType1, TArgs, TThis>,
  guard2: TypeGuard<TIn, TType2, TArgs, TThis>,
  guard3: TypeGuard<TIn, TType3, TArgs, TThis>,
  guard4: TypeGuard<TIn, TType4, TArgs, TThis>,
  guard5: TypeGuard<TIn, TType5, TArgs, TThis>,
  guard6: TypeGuard<TIn, TType6, TArgs, TThis>,
  guard7: TypeGuard<TIn, TType7, TArgs, TThis>
): TypeGuard<TIn, TType1 | TType2 | TType3 | TType4 | TType5 | TType6 | TType7, TArgs, TThis>;
/**
 * Given multiple type guards returns a type guard that matches the union of all types
 * @returns a type guard that matches if any given type guard matches.
 */
export function union<
  TIn,
  TType1 extends TIn,
  TType2 extends TIn,
  TType3 extends TIn,
  TType4 extends TIn,
  TType5 extends TIn,
  TType6 extends TIn,
  TType7 extends TIn,
  TType8 extends TIn,
  TArgs extends any[],
  TThis = void
>(
  guard1: TypeGuard<TIn, TType1, TArgs, TThis>,
  guard2: TypeGuard<TIn, TType2, TArgs, TThis>,
  guard3: TypeGuard<TIn, TType3, TArgs, TThis>,
  guard4: TypeGuard<TIn, TType4, TArgs, TThis>,
  guard5: TypeGuard<TIn, TType5, TArgs, TThis>,
  guard6: TypeGuard<TIn, TType6, TArgs, TThis>,
  guard7: TypeGuard<TIn, TType7, TArgs, TThis>,
  guard8: TypeGuard<TIn, TType8, TArgs, TThis>
): TypeGuard<
  TIn,
  TType1 | TType2 | TType3 | TType4 | TType5 | TType6 | TType7 | TType8,
  TArgs,
  TThis
>;
/**
 * Given multiple type guards returns a type guard that matches the union of all types
 * @returns a type guard that matches if any given type guard matches.
 */
export function union<
  TIn,
  TType1 extends TIn,
  TType2 extends TIn,
  TType3 extends TIn,
  TType4 extends TIn,
  TType5 extends TIn,
  TType6 extends TIn,
  TType7 extends TIn,
  TType8 extends TIn,
  TType9 extends TIn,
  TArgs extends any[],
  TThis = void
>(
  guard1: TypeGuard<TIn, TType1, TArgs, TThis>,
  guard2: TypeGuard<TIn, TType2, TArgs, TThis>,
  guard3: TypeGuard<TIn, TType3, TArgs, TThis>,
  guard4: TypeGuard<TIn, TType4, TArgs, TThis>,
  guard5: TypeGuard<TIn, TType5, TArgs, TThis>,
  guard6: TypeGuard<TIn, TType6, TArgs, TThis>,
  guard7: TypeGuard<TIn, TType7, TArgs, TThis>,
  guard8: TypeGuard<TIn, TType8, TArgs, TThis>,
  guard9: TypeGuard<TIn, TType9, TArgs, TThis>
): TypeGuard<
  TIn,
  TType1 | TType2 | TType3 | TType4 | TType5 | TType6 | TType7 | TType8 | TType9,
  TArgs,
  TThis
>;
/**
 * Given multiple type guards returns a type guard that matches the union of all types
 * @returns a type guard that matches if any given type guard matches.
 */
export function union<
  TIn,
  TType1 extends TIn,
  TType2 extends TIn,
  TType3 extends TIn,
  TType4 extends TIn,
  TType5 extends TIn,
  TType6 extends TIn,
  TType7 extends TIn,
  TType8 extends TIn,
  TType9 extends TIn,
  TType10 extends TIn,
  TArgs extends any[],
  TThis = void
>(
  guard1: TypeGuard<TIn, TType1, TArgs, TThis>,
  guard2: TypeGuard<TIn, TType2, TArgs, TThis>,
  guard3: TypeGuard<TIn, TType3, TArgs, TThis>,
  guard4: TypeGuard<TIn, TType4, TArgs, TThis>,
  guard5: TypeGuard<TIn, TType5, TArgs, TThis>,
  guard6: TypeGuard<TIn, TType6, TArgs, TThis>,
  guard7: TypeGuard<TIn, TType7, TArgs, TThis>,
  guard8: TypeGuard<TIn, TType8, TArgs, TThis>,
  guard9: TypeGuard<TIn, TType9, TArgs, TThis>,
  guard10: TypeGuard<TIn, TType9, TArgs, TThis>
): TypeGuard<
  TIn,
  TType1 | TType2 | TType3 | TType4 | TType5 | TType6 | TType7 | TType8 | TType9 | TType10,
  TArgs,
  TThis
>;
/**
 * Given multiple type guards returns a type guard that matches the union of all types
 * @returns a type guard that matches if any given type guard matches.
 */
export function union<
  TIn,
  TType1 extends TIn,
  TType2 extends TIn,
  TType3 extends TIn,
  TType4 extends TIn,
  TType5 extends TIn,
  TType6 extends TIn,
  TType7 extends TIn,
  TType8 extends TIn,
  TType9 extends TIn,
  TType10 extends TIn,
  TTypeRest extends TIn,
  TArgs extends any[],
  TThis = void
>(
  guard1: TypeGuard<TIn, TType1, TArgs, TThis>,
  guard2: TypeGuard<TIn, TType2, TArgs, TThis>,
  guard3: TypeGuard<TIn, TType3, TArgs, TThis>,
  guard4: TypeGuard<TIn, TType4, TArgs, TThis>,
  guard5: TypeGuard<TIn, TType5, TArgs, TThis>,
  guard6: TypeGuard<TIn, TType6, TArgs, TThis>,
  guard7: TypeGuard<TIn, TType7, TArgs, TThis>,
  guard8: TypeGuard<TIn, TType8, TArgs, TThis>,
  guard9: TypeGuard<TIn, TType9, TArgs, TThis>,
  guard10: TypeGuard<TIn, TType9, TArgs, TThis>,
  ...guardRest: TypeGuard<TIn, TTypeRest, TArgs, TThis>[]
): TypeGuard<
  TIn,
  | TType1
  | TType2
  | TType3
  | TType4
  | TType5
  | TType6
  | TType7
  | TType8
  | TType9
  | TType10
  | TTypeRest,
  TArgs,
  TThis
>;
/**
 * Given multiple type guards returns a type guard that matches the union of all types
 * @returns a type guard that matches if any given type guard matches.
 */
export function union<
  TIn,
  TType1 extends TIn,
  TType2 extends TIn,
  TTypeRest extends TIn,
  TArgs extends any[],
  TThis = void
>(
  ...guards: [
    TypeGuard<TIn, TType1, TArgs, TThis>,
    TypeGuard<TIn, TType2, TArgs, TThis>,
    ...TypeGuard<TIn, TTypeRest, TArgs, TThis>[]
  ]
): TypeGuard<TIn, TType1 | TType2 | TTypeRest, TArgs, TThis> {
  return or(...guards) as unknown as TypeGuard<TIn, TType1 | TType2 | TTypeRest, TArgs, TThis>;
}

/**
 * Given multiple predicates returns a predicate that returns true if only one predicate returns true
 * @returns a predicate that returns true if only one given predicate returns true
 */
export function xor<TArgs extends any[], TThis = void>(
  ...predicates: [Predicate<TArgs, TThis>, Predicate<TArgs, TThis>, ...Predicate<TArgs, TThis>[]]
): Predicate<TArgs, TThis> {
  return function (this: TThis, ...args: TArgs): boolean {
    let foundTrue = false;
    for (const p of predicates) {
      const result = p.apply(this, args);
      if (result) {
        if (foundTrue) {
          return false;
        }
        foundTrue = true;
      }
    }
    return foundTrue;
  };
}

/**
 * Given a predicate returns a predicate that negates the result
 * @returns a predicate that negates the result of the given predicate.
 */
export function not<TArgs extends any[], TThis = void>(
  predicate: Predicate<TArgs, TThis>
): Predicate<TArgs, TThis> {
  return function (this: TThis, ...args: TArgs): boolean {
    return !predicate.apply(this, args);
  };
}
