import { of } from '@rategravity/core-lib/optional';
import React, { ReactNode, useMemo, useState } from 'react';
import styled from 'styled-components';
import { RateCard, RateCardProps } from '../rate-card';
import { PermutationAttributes, ProductTypeDropdown } from './product-type-menu';
import { SelectableCard } from './selectable-card';
import { transition } from './transition';

const AccordionBodyContainer = styled.div<{ open: boolean }>`
  border-radius: 0 0 8px 8px;
  background-color: white;
  padding: ${({ open }) => (open ? '24px 24px 12px' : '24px')};
  box-shadow: 4px 4px 8px rgba(29, 29, 31, 0.05);
`;

const CollapsibleStack = styled.div<{ open: boolean }>`
  display: flex;
  overflow: hidden;
  width: 100%;
  flex-direction: column;
  max-height: ${({ open }) => (open ? 500 : 0)}vh;
  ${({ open }) => (open ? transition('max-height') : '')};
`;

export type AccordionBodyProps = {
  open?: boolean;
  selectedId: string | null;
  excludedIds: string[];
  offerPermutations: {
    [product: string]: {
      sectionTitles: { displayField: string; displayValue: string }[];
      rates: (RateCardProps & { id: string })[];
    }[];
  };
  onSelect: (id: string) => void;
};

export const AccordionBody = ({
  open = false,
  selectedId,
  excludedIds,
  offerPermutations,
  onSelect
}: AccordionBodyProps) => {
  const [selectedPermutation, setSelectedPermutation] = useState(
    () =>
      Object.entries(offerPermutations).find(([, permutation]) =>
        permutation.some(({ rates }) => rates.some(({ id }) => id === selectedId))
      )?.[0] ??
      Object.keys(offerPermutations)[0] ??
      ''
  );

  const optionalSelectedPermutation = useMemo(
    () =>
      of(offerPermutations[selectedPermutation]).map((sections) =>
        sections.map(({ sectionTitles, rates }) => ({
          sectionTitles,
          rates: rates.filter(({ id }) => id === selectedId || !excludedIds.includes(id))
        }))
      ),
    [offerPermutations, selectedPermutation, excludedIds, selectedId]
  );

  // In order to support animations we're going to make a list of elements before the selected
  // card, and a list of elements after the selected card,
  // as well as the card it self.
  const [topNodes, selectedNode, bottomNodes] = optionalSelectedPermutation
    .map<[ReactNode[], ReactNode, ReactNode[]]>((permutation) => {
      // consistent header
      const top = [
        Object.keys(offerPermutations).length > 1 ? (
          <ProductTypeDropdown
            value={selectedPermutation}
            setValue={(value) => setSelectedPermutation(value)}
            options={Object.keys(offerPermutations)}
          />
        ) : null
      ];
      let selected: ReactNode = null;
      const bottom: ReactNode[] = [];
      // look through all permutations and all rates
      permutation.forEach(({ sectionTitles, rates }, i) => {
        if (rates.length < 1) {
          return;
        }
        if (sectionTitles.length > 0) {
          (selected === null ? top : bottom).push(
            <PermutationAttributes
              sectionTitles={sectionTitles}
              marginTop={i > 0 ? '12px' : undefined}
            />
          );
        }

        for (const { id, ...rate } of rates) {
          if (id === selectedId) {
            selected = open ? (
              <SelectableCard
                key={id}
                open={open}
                selected={true}
                onClick={() => onSelect(id)}
                {...rate}
              />
            ) : (
              <RateCard {...rate} />
            );
          } else {
            (selected === null ? top : bottom).push(
              <SelectableCard
                key={id}
                open={open}
                selected={false}
                onClick={() => onSelect(id)}
                {...rate}
              />
            );
          }
        }
      });
      return [top, selected, bottom];
    })
    .orElse(() => [[], null, []]);

  let ensureSelectedNode = selectedNode;

  // edge case if the accordion isn't showing the selected permutation
  // but it's closed by clicking another accordion section.
  if (!open && !ensureSelectedNode && selectedId !== null) {
    for (const { rates } of Object.values(offerPermutations).flat()) {
      for (const { id, ...rate } of rates) {
        if (id === selectedId) {
          ensureSelectedNode = <RateCard {...rate} />;
        }
      }
    }
  }
  return (
    <AccordionBodyContainer open={open}>
      <CollapsibleStack open={open}>{topNodes}</CollapsibleStack>
      {ensureSelectedNode}
      <CollapsibleStack open={open}>{bottomNodes}</CollapsibleStack>
    </AccordionBodyContainer>
  );
};
