import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import {
  fetchLenderRepresentations,
  fetchLenderReview,
  fetchOULenderReview
} from '../../modules/lenders/api';
import {
  lenderRepresentationsConverter,
  lenderReviewConverter,
  ouLenderReviewDataConverter
} from '../../modules/lenders/type';
import { dataLoadedActionType } from '../../rates/redux/loading/actions';
import { lenderIdsSelector as ratesLenderIdSelector } from '../../rates/redux/selector';
import { MANIFEST_LOADED_SUCCESS_ACTION_TYPE } from '../manifest/actions';
import { lenderDirectLenderIdsSelector } from '../manifest/selector';
import { allLendersSelector } from './selector';
import {
  fetchLenderRepresentationsFailed,
  fetchLenderRepresentationsSucceeded,
  fetchLenderReview as fetchLenderReviewAction,
  fetchLenderReviewFailed,
  fetchLenderReviewNotFound,
  fetchLenderReviewSucceeded,
  fetchOULenderReviewFailed,
  fetchOULenderReviewNotFound,
  fetchOULenderReviewSucceeded
} from './slice';

function* startRatesLenderReviews() {
  const lenderIds: string[] = yield select(ratesLenderIdSelector);
  const fetchedLenderIds: string[] = Object.keys(yield select(allLendersSelector));
  for (const lenderId of lenderIds.filter((id) => !fetchedLenderIds.includes(id))) {
    yield put(fetchLenderReviewAction({ lenderId }));
  }
}

function* startLenderDirectLenderReviews() {
  try {
    const lenderIds: string[] = yield select(lenderDirectLenderIdsSelector);
    const allLenders: string[] = yield select(allLendersSelector);
    const fetchedLenderIds = Object.keys(allLenders);

    for (const lenderId of lenderIds.filter((id) => !fetchedLenderIds.includes(id))) {
      yield put(fetchLenderReviewAction({ lenderId }));
    }
  } catch (err) {
    console.error(err);
    const result: ReturnType<typeof fetchLenderReviewFailed> = yield put(
      fetchLenderReviewFailed({ lenderId: 'all' })
    );
    return result;
  }
}

function* startLenderDirectLenderRepresentations() {
  try {
    const lenderIds: string[] = yield select(lenderDirectLenderIdsSelector);
    // Short-circuit if there's nothing to do.
    if (!lenderIds.length) {
      return;
    }
    const { success, error, json, code } = yield call(fetchLenderRepresentations, lenderIds);
    if (success) {
      const successResult: ReturnType<typeof fetchLenderRepresentationsSucceeded> = yield put(
        fetchLenderRepresentationsSucceeded({ lenders: lenderRepresentationsConverter(json) })
      );
      return successResult;
    }

    throw Error(`${code}: ${error}`);
  } catch (err) {
    console.error(err);
    const failedResult: ReturnType<typeof fetchLenderRepresentationsFailed> = yield put(
      fetchLenderRepresentationsFailed()
    );
    return failedResult;
  }
}

function* handleLenderReview({
  payload: { lenderId }
}: ReturnType<typeof fetchLenderReviewAction>) {
  // fetch zillow reviews
  try {
    const { success, error, json, code } = yield call(fetchLenderReview, lenderId);
    if (success) {
      const successResult: ReturnType<typeof fetchLenderReviewSucceeded> = yield put(
        fetchLenderReviewSucceeded({ lenderId, lenderReview: lenderReviewConverter(json) })
      );
      return successResult;
    }
    if (code === 404) {
      const notFoundResult: ReturnType<typeof fetchLenderReviewNotFound> = yield put(
        fetchLenderReviewNotFound({ lenderId })
      );
      return notFoundResult;
    }
    throw Error(`${code}: ${error}`);
  } catch (err) {
    console.log(err);
    const failedResult: ReturnType<typeof fetchLenderReviewFailed> = yield put(
      fetchLenderReviewFailed({ lenderId })
    );
    return failedResult;
  }
}

function* handleOULenderReview({
  payload: { lenderId }
}: ReturnType<typeof fetchLenderReviewAction>) {
  // fetch OU reviews
  try {
    const {
      success: successOU,
      error: errorOU,
      json: jsonOU,
      code: codeOU
    } = yield call(fetchOULenderReview, lenderId);

    if (successOU) {
      const successResult: ReturnType<typeof fetchOULenderReviewSucceeded> = yield put(
        fetchOULenderReviewSucceeded({
          lenderId,
          ouLenderReviewData: ouLenderReviewDataConverter(jsonOU)
        })
      );
      return successResult;
    }
    if (codeOU === 404) {
      const notFoundResult: ReturnType<typeof fetchOULenderReviewNotFound> = yield put(
        fetchOULenderReviewNotFound({ lenderId })
      );
      return notFoundResult;
    }
    throw Error(`${codeOU}: ${errorOU}`);
  } catch (err) {
    console.log(err);
    const failedResult: ReturnType<typeof fetchOULenderReviewFailed> = yield put(
      fetchOULenderReviewFailed({ lenderId })
    );
    return failedResult;
  }
}

export function* lenderReviewsSaga() {
  yield all([
    takeEvery(dataLoadedActionType, startRatesLenderReviews),
    takeEvery(MANIFEST_LOADED_SUCCESS_ACTION_TYPE, startLenderDirectLenderRepresentations),
    takeEvery(MANIFEST_LOADED_SUCCESS_ACTION_TYPE, startLenderDirectLenderReviews),
    takeEvery(fetchLenderReviewAction.type, handleLenderReview),
    takeEvery(fetchLenderReviewAction.type, handleOULenderReview)
  ]);
}
