import { RouterAction } from 'connected-react-router';
import React from 'react';
import { useSelector } from 'react-redux';
import { OfferSummary } from '../components/offer-summary';
import { lockStatusSelector, offerDetailsSelector } from '../redux/selector';

export const OfferSummaryView = ({
  compareProps,
  onLockRequest: onLockClick
}: {
  compareProps: { showComparisonShopping: boolean; onCompareOffer: () => void };
  onLockRequest: (s: string) => RouterAction;
}) => {
  const offerDetails = useSelector(offerDetailsSelector);
  const lockStatus = useSelector(lockStatusSelector);

  if (offerDetails.isSome()) {
    const summaryDetails = offerDetails.value;
    const lockStatusProps = lockStatus.showLock
      ? {
          showLock: true,
          onLockRequest: () => onLockClick(lockStatus.offerId),
          lockStatus: lockStatus.lockStatus
        }
      : { showLock: false as const };
    return (
      <OfferSummary
        {...summaryDetails}
        compareProps={compareProps}
        lockStatusProps={lockStatusProps}
      />
    );
  }
  return null;
};
