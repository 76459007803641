import { useCallback, useMemo, useState } from 'react';
import { ShowAllProps } from './show-all-toggle';

export const useTogglable = <T extends { recommend: boolean }>(
  data: T[],
  onToggleCallback: (s: boolean) => void
): [T[], ShowAllProps] => {
  const [showAllState, setShowAll] = useState(false);
  const onShowAll = useCallback(() => {
    onToggleCallback(!showAllState);
    setShowAll((v) => !v);
  }, [onToggleCallback, showAllState]);
  const recommended = useMemo(() => data.filter(({ recommend }) => recommend), [data]);
  const togglable = recommended.length > 0 && recommended.length !== data.length && data.length > 3;
  const showAll = showAllState || !togglable;
  return [
    showAll ? data : recommended,
    {
      togglable,
      showAll,
      onShowAll,
      countAllOffers: data.length
    }
  ];
};
