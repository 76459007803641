import { all, call, put, takeEvery } from 'redux-saga/effects';
import { fetchWithRetry } from '../../modules/authentication';
import {
  fetchPublishedRates,
  fetchRequestLockOffer,
  StandardizedPublishedRatesResponse
} from '../../modules/published-rates';
import { dataLoadedAction, errorLoadingAction } from '../../rates/redux/loading/actions';
import {
  MANIFEST_LOADED_SUCCESS_ACTION_TYPE,
  ManifestLoadedSuccessAction
} from '../manifest/actions';
import { fetchPublishedRates as fetchAllRatesAction } from '../published-rates/actions';
import {
  FETCH_PUBLISHED_RATES_ACTION_TYPE,
  FetchPublishedRatesAction,
  LOCK_OFFER_ACTION_TYPE,
  LockOfferAction,
  lockOfferError,
  lockOfferSuccess
} from './actions';

interface FetchSuccess {
  success: true;
  data: unknown;
}

interface FetchFailure {
  success: false;
  data: unknown;
}

export const fetchRatesHelper = (
  { success, json, error }: StandardizedPublishedRatesResponse,
  id: string
): FetchSuccess | FetchFailure => {
  if (success && json) {
    if (json.status === 'Ready') {
      return { success: true as const, data: { id, ...json.rates, shareId: json.shareId } };
    } else {
      return { success: false as const, data: 'Rates not ready, try refreshing' };
    }
  }
  return { success: false as const, data: error };
};

export function* handleFetchPublishedRates({
  payload: { accountId, rateQuoteRequestId }
}: FetchPublishedRatesAction) {
  yield* fetchWithRetry(
    () => fetchRates(accountId, rateQuoteRequestId),
    dataLoadedAction,
    () => errorLoadingAction(rateQuoteRequestId)
  );
}

function* fetchRates(accountId: string, rateQuoteRequestId: string) {
  return fetchRatesHelper(
    yield call(fetchPublishedRates, {
      accountId,
      rateQuoteRequestId
    }),
    rateQuoteRequestId
  );
}

function* fetchAllRates({ payload: { manifest } }: ManifestLoadedSuccessAction) {
  const { accountId, publishedRates } = manifest;
  if (publishedRates.length > 0) {
    for (const { rateQuoteRequestId } of publishedRates) {
      if (rateQuoteRequestId) {
        yield put(fetchAllRatesAction(accountId, rateQuoteRequestId));
      }
    }
  }
}

export function* handleLockOffer({ payload: { accountId, id, offerId } }: LockOfferAction) {
  try {
    const { success } = yield call(fetchRequestLockOffer, {
      accountId,
      rateQuoteRequestId: id,
      offerId
    });

    if (success) {
      yield put(lockOfferSuccess(id, offerId));
    } else {
      yield put(lockOfferError(id));
    }
  } catch (err) {
    yield put(lockOfferError(id));
  }
}

export function* publishedRatesSaga() {
  yield all([
    takeEvery(FETCH_PUBLISHED_RATES_ACTION_TYPE, handleFetchPublishedRates),
    takeEvery(MANIFEST_LOADED_SUCCESS_ACTION_TYPE, fetchAllRates),
    takeEvery(LOCK_OFFER_ACTION_TYPE, handleLockOffer)
  ]);
}
