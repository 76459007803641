import { MYSTIC_40, OwnUpBody, OwnUpBodyMedium } from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';
import { FTUTemplate } from './template';

const Container = styled.div`
  background-color: ${MYSTIC_40};
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

const FeeSection = ({ text }: { text: string }) => (
  <Container>
    <OwnUpBody variant="body1">{text}</OwnUpBody>
    <OwnUpBodyMedium variant="body1">[$]</OwnUpBodyMedium>
  </Container>
);

export const Page2 = () => (
  <FTUTemplate
    title="Real offers, not marketing"
    body={`These are real offers that are ready to be locked in.\n
      Not ready to lock or want to see different options? Schedule a quick call with your Home Advisor to see what else is available.`}
    content={
      <>
        <FeeSection text="Monthly principal &amp; interest" />
        <FeeSection text="Lender fees" />
        <FeeSection text="Cash to close" />
        <FeeSection text="Down payment" />
      </>
    }
  />
);
