import React from 'react';
import { useSelector } from 'react-redux';
import { FeeBreakdown } from '../components/fee-breakdown';
import { offerDetailsSelector } from '../redux/selector';

export const FeeBreakdownView = () => {
  const offerDetails = useSelector(offerDetailsSelector);

  if (offerDetails.isSome()) {
    return <FeeBreakdown {...{ ...offerDetails.value }} />;
  }
  return null;
};
