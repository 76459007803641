import { createSelector } from 'reselect';
import { ApplicationState } from '../state';

export const isUserOnboarded = createSelector(
  ({ accountAttributes }: ApplicationState) => accountAttributes,
  (attributes) => {
    // if we are in shareable link world return true
    if (!attributes) {
      return true;
    }
    const { error, loading, data } = attributes;
    if (error || loading || (data && data.userOnboarded)) {
      return true;
    }
    return false;
  }
);
