import {
  CHARCOAL_100,
  OwnUpBodyMedium,
  OwnUpDropdown,
  OwnUpFillButtonPrimary,
  OwnUpIconTooltipTriggerSmallBodyMedium,
  OwnUpMenuItem,
  OwnUpNumberInput,
  OwnUpSmallBody,
  OwnUpTooltip,
  PINE_100
} from '@rategravity/own-up-component-library';
import { DollarIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/dollar';
import { PercentIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/percent';
import mapValues from 'lodash/mapValues';
import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { outsideOffersSelector } from '../../../../redux/outside-offers/selectors';
import { addOutsideOffer } from '../../../../redux/outside-offers/slice';
import { OutsideOffer as OutsideOfferInterface } from '../../../../redux/outside-offers/state';

const InputWrapper = styled.div`
  margin-top: 24px;
  > * > label.Mui-focused {
    color: ${CHARCOAL_100};
  }
  > div > div.MuiInputBase-root {
    margin-bottom: 0;
  }
`;

const Container = styled.div`
  padding: 32px 20px 24px;
  background-color: white;
  border-radius: 0 0 8px 8px;
`;

const StyledSubmitButton = styled(OwnUpFillButtonPrimary)`
  :hover {
    background-color: ${PINE_100} !important;
  }
`;

export const OutsideOffer = ({
  propertyValue,
  event,
  setScrollingToCta
}: {
  propertyValue: number | null;
  event: (eventName: string, properties: Record<string, unknown>) => void;
  setScrollingToCta: Dispatch<SetStateAction<boolean>>;
}) => {
  const dispatch = useDispatch();
  const {
    pending,
    offerSubmitted,
    outsideOffer: outsideOfferState
  } = useSelector(outsideOffersSelector);
  const [outsideOffer, setOutsideOffer] = useState<Partial<OutsideOfferInterface>>(
    outsideOfferState === null
      ? {
          loanAmount: undefined,
          lenderFees: undefined,
          loanTerm: undefined,
          interestRate: undefined
        }
      : (mapValues(outsideOfferState, (v) =>
          v === null ? undefined : v
        ) as Partial<OutsideOfferInterface>)
  );
  const showMI = (outsideOffer.loanAmount || 0) / (propertyValue || Number.POSITIVE_INFINITY) > 0.8;
  const submitDisabled = useMemo(
    () =>
      offerSubmitted ||
      pending ||
      (outsideOffer.interestRate == undefined &&
        outsideOffer.lenderFees == undefined &&
        outsideOffer.loanAmount == undefined &&
        outsideOffer.loanTerm == undefined),
    [
      offerSubmitted,
      outsideOffer.interestRate,
      outsideOffer.lenderFees,
      outsideOffer.loanAmount,
      outsideOffer.loanTerm,
      pending
    ]
  );

  const Submit = (
    <StyledSubmitButton
      onClick={() => {
        event(
          'outsideOffer-submitOffer-comparisonShopping',
          outsideOffer as Record<string, unknown>
        );
        setScrollingToCta(true);
        dispatch(
          addOutsideOffer({
            outsideOffer: mapValues(outsideOffer, (v) =>
              v === undefined ? null : v
            ) as OutsideOfferInterface
          })
        );
      }}
      disabled={submitDisabled}
    >
      Submit
    </StyledSubmitButton>
  );
  return (
    <Container>
      <OwnUpBodyMedium variant="body1">Looking for an unbiased analysis?</OwnUpBodyMedium>
      <OwnUpSmallBody variant="body1" style={{ margin: '8px 0' }}>
        Tell us about your offer from another lender to see if we can beat it. We&apos;ll even coach
        you to negotiate a better deal with your lender.
      </OwnUpSmallBody>
      <InputWrapper>
        <OwnUpNumberInput
          label="Loan amount"
          labelPosition="outer"
          placeholder="400,000"
          $leadingIcon={<DollarIcon />}
          disabled={!!offerSubmitted}
          allowNegative={false}
          decimalScale={2}
          value={outsideOffer.loanAmount}
          onValueChange={(e) => {
            setOutsideOffer({
              ...outsideOffer,
              loanAmount: e.floatValue
            });
          }}
        />
      </InputWrapper>
      <InputWrapper>
        <OwnUpNumberInput
          label={
            (
              <OwnUpIconTooltipTriggerSmallBodyMedium
                iconPosition="right"
                tooltipTitle="Lender fees"
                description={
                  <span style={{ whiteSpace: 'pre-line' }}>
                    Lender Fees are the sum of fees specific to a particular lender. All other fees
                    in an offer are from third parties, so Lender Fees tells you how much the lender
                    is really charging you for the loan.{`\n\n`}
                    If you have a Loan Estimate, you can calculate its Net Lender Fees by summing
                    Section A and the Lender Credits in Section J.{`\n\n`}
                    If you don&apos;t have a Loan Estimate, sum any of the following fees included
                    in your offer: Processing, Application, Underwriting, Origination Fee, and
                    Points/Lender Credits.
                  </span>
                }
              >
                Lender fees
              </OwnUpIconTooltipTriggerSmallBodyMedium>
            ) as any
          }
          labelPosition="outer"
          placeholder="500"
          $leadingIcon={<DollarIcon />}
          disabled={!!offerSubmitted}
          allowNegative={false}
          decimalScale={2}
          value={outsideOffer.lenderFees}
          onValueChange={(e) =>
            setOutsideOffer({
              ...outsideOffer,
              lenderFees: e.floatValue
            })
          }
        />
      </InputWrapper>
      <InputWrapper>
        <OwnUpDropdown
          label="Loan term"
          labelPosition="outer"
          type="text"
          disabled={!!offerSubmitted}
          onChange={(e) =>
            setOutsideOffer({
              ...outsideOffer,
              loanTerm: String(e.target.value)
            })
          }
          value={outsideOffer && outsideOffer.loanTerm ? outsideOffer.loanTerm : ''}
          placeholder="30 Year Fixed"
        >
          {[30, 25, 20, 15, 10]
            .map((years) => `${years} Year Fixed`)
            .map((option, index) => (
              <OwnUpMenuItem key={index} value={option}>
                {option}
              </OwnUpMenuItem>
            ))}
        </OwnUpDropdown>
      </InputWrapper>
      <InputWrapper>
        <OwnUpNumberInput
          label="Interest rate"
          labelPosition="outer"
          placeholder="3.125"
          $leadingIcon={<PercentIcon />}
          disabled={!!offerSubmitted}
          allowNegative={false}
          decimalScale={3}
          value={outsideOffer.interestRate}
          onValueChange={(e) =>
            setOutsideOffer({
              ...outsideOffer,
              interestRate: e.floatValue
            })
          }
        />
      </InputWrapper>
      {showMI && (
        <InputWrapper>
          <OwnUpNumberInput
            label="Mortgage Insurance"
            labelPosition="outer"
            placeholder="350"
            $leadingIcon={<DollarIcon />}
            disabled={!!offerSubmitted}
            allowNegative={false}
            decimalScale={2}
            style={{ marginBottom: '32px' }}
            value={outsideOffer.pmi}
            onValueChange={(e) => {
              setOutsideOffer({
                ...outsideOffer,
                pmi: e.floatValue
              });
            }}
          />
        </InputWrapper>
      )}
      <div style={{ marginTop: '32px' }}>
        {submitDisabled && !pending ? (
          <OwnUpTooltip
            description={
              offerSubmitted
                ? 'Your outside offer was already submitted. Chat with your Home Advisor to confidently compare and understand your options.'
                : 'You must enter at least one of the fields to submit an outside offer.'
            }
          >
            <span style={{ padding: '6px 0 24px' }}>{Submit}</span>
          </OwnUpTooltip>
        ) : (
          Submit
        )}
      </div>
    </Container>
  );
};
