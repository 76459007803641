import { OwnUpBody, OwnUpSmallSubheadlineMedium } from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div<{ showOnDesktop?: boolean }>`
  display: none;
  flex-direction: column;
  > :first-child {
    margin-bottom: 16px;
  }
  ${({ showOnDesktop, theme }) =>
    !!showOnDesktop
      ? `
    ${theme.breakpoints.up('md')} {
      display: flex;
      margin: 32px 0;
    }
    ${theme.breakpoints.up('lg')} {
      margin: 40px 0;
    }
  `
      : `
    ${theme.breakpoints.down('sm')} {
      display: flex;
      margin: 32px 40px;
    }
    ${theme.breakpoints.down('xs')} {
      margin: 32px 24px;
    }
  `}
`;

export const OfferHeader = ({ showOnDesktop }: { showOnDesktop?: boolean }) => (
  <Container showOnDesktop={showOnDesktop}>
    <OwnUpSmallSubheadlineMedium variant="h1">
      Rate is just one part of finding a great deal
    </OwnUpSmallSubheadlineMedium>
    <OwnUpBody variant="body1">
      Make the choice that meets your goals by comparing loan costs, points, and lender credits.
    </OwnUpBody>
  </Container>
);
