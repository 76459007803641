import { event } from '@rategravity/frontend/modules/user-actions';
import { replace } from 'connected-react-router';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useActionPipe } from '../../../hooks/use-action-pipe';
import { manifestAccountInfoSelector } from '../../../redux/manifest';
import { lockOffer } from '../../../redux/published-rates/actions';
import {
  lockEventPropertiesSelector,
  lockRequestSelector,
  rateQuoteSelector
} from '../../redux/selector';
import { LockOfferPage } from '.';

export const LockOfferConnect = ({
  rootPath,
  id,
  offerId
}: {
  rootPath: string;
  id: string;
  offerId: string;
}) => {
  const dispatch = useDispatch();
  const dispatchAction = useActionPipe();
  const offerLockData = useSelector(lockRequestSelector);
  const rateQuote = useSelector(rateQuoteSelector);
  const { advisor, scenario } = useSelector(lockEventPropertiesSelector);
  const lockOfferEvent = event('Request Lock Confirm', {
    advisor,
    scenario,
    url: window.location.href
  });
  const { accountId } = useSelector(manifestAccountInfoSelector);

  // Only accessible if in accounts world
  if (
    offerLockData.isSome() &&
    rateQuote.filter(({ lockedOfferId }) => lockedOfferId !== undefined).isSome()
  ) {
    const onClose = () => {
      dispatch(replace(rootPath));
    };
    const onLock = () => {
      dispatchAction(lockOfferEvent);
      dispatch(lockOffer(accountId, rateQuote.value!.id, offerId));
    };
    return <LockOfferPage offerLockData={offerLockData.value} onClose={onClose} onLock={onLock} />;
  }
  return (
    <Redirect
      to={{
        pathname: `/rates/${id}`
      }}
    />
  );
};
