import { event } from '@rategravity/frontend/modules/user-actions';
import {
  CHARCOAL_100,
  FOG_100,
  IconLink,
  OwnUpBody,
  OwnUpFillButtonPrimary,
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpLargeBody,
  OwnUpLineButton,
  OwnUpSideModal
} from '@rategravity/own-up-component-library';
import { ArrowRightIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/arrow-right';
import React, { Children, useCallback, useState } from 'react';
import styled from 'styled-components';
import { useActionPipe } from '../../../hooks/use-action-pipe';

const ContentWrapper = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.spacing(3, 0)};
  display: flex;
  flex-direction: column;
  white-space: pre;
  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: row;
    padding: ${({ theme }) => theme.spacing(4, 0)};
  }
`;

// HACK: Regarding the `left: 0`, this should have been
//   covered by the $anchorSide="left" property, but the
//   $-prefix feature of styled-components causes any
//   `styled` component to shed its `$` properties as
//   soon as it sees them. Because this is a doubly-wrapped
//   styled component the $anchorSide prop is ripped off
//   here and never makes it to the styling inside the
//   component library. (As of v1.0.0-alpha.73) - BC
const StyledModal = styled(OwnUpSideModal)`
  .modal-body {
    left: 0;
    :focus {
      outline: none;
    }
    max-width: unset;
    width: 560px;
    padding-bottom: 0;
    > .modal-content {
      height: 100%;
    }
    ${({ theme }) => theme.breakpoints.only('sm')} {
      width: 480px;
    }
    ${({ theme }) => theme.breakpoints.only('xs')} {
      width: 100vw;
      max-height: calc(100% - 24px);
    }
  }
`;

const ModalContent = styled.div`
  height: 100%;
  width: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  > :first-child {
    margin-bottom: 32px;
  }
  ${({ theme }) => theme.breakpoints.only('xs')} {
    display: contents;
  }
`;

const Divider = styled.div`
  height: 1px;
  background-color: ${FOG_100};
  margin: 32px 0;
  width: 100%;
`;

const ButtonGrid = styled(OwnUpGridContainer)`
  border-top: 1px solid ${FOG_100};
  box-shadow: 0px -4px 8px -5px ${CHARCOAL_100}0D;
  padding: 32px 0;
  background-color: white;
  position: sticky;
  bottom: 0;
  ${({ theme }) => theme.breakpoints.only('xs')} {
    padding: 12px 0 32px;
  }
`;

const CenteredGridItem = styled(OwnUpGridItem)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RightAlignedGridItem = styled(OwnUpGridItem)`
  display: flex;
  justify-content: flex-end;
  ${({ theme }) => theme.breakpoints.only('xs')} {
    margin: 16px 0 12px;
  }
`;

export const FTUBanner = ({ children, isOpen }: { children: React.ReactNode; isOpen: boolean }) => {
  const actionPipe = useActionPipe();
  const [open, setOpen] = useState(isOpen);
  const [page, setPage] = useState(1);
  const childArray = Children.toArray(children);
  const scrollableDiv = document.getElementsByClassName('modal-body')[0];
  const onOpenModal = useCallback(() => {
    setOpen(true);
    setPage(1);
    actionPipe(event('FTUOnboarding-page1'));
  }, [setOpen, setPage, actionPipe]);

  const onCloseModal = useCallback(() => {
    setOpen(false);
    actionPipe(event('FTUOnboarding-exitFlow', { exitPage: page }));
  }, [setOpen, actionPipe, page]);

  const onNextClick = useCallback(() => {
    if (page === childArray.length) {
      onCloseModal();
    }
    actionPipe(event(`FTUOnboarding-page${page + 1}`));
    setPage(page + 1);
    scrollableDiv.scrollTop = 0;
  }, [page, childArray, setPage, actionPipe, onCloseModal, scrollableDiv]);

  const onBackClick = useCallback(() => {
    actionPipe(event(`FTUOnboarding-page${page - 1}`));
    setPage(page - 1);
    scrollableDiv.scrollTop = 0;
  }, [actionPipe, page, setPage, scrollableDiv]);

  const BackButton =
    page === 1 ? (
      <span style={{ width: '101.25px' }} />
    ) : (
      <OwnUpLineButton onClick={onBackClick}>Back</OwnUpLineButton>
    );
  return (
    <>
      <ContentWrapper>
        <OwnUpLargeBody variant="body1">Not sure where to start? </OwnUpLargeBody>
        <IconLink onClick={onOpenModal} link="#" icon={<ArrowRightIcon />} size="large">
          Here&apos;s a quick overview
        </IconLink>
      </ContentWrapper>
      <StyledModal title="" open={open} onClose={onCloseModal} $anchorSide="left">
        <ModalContent>
          <div>
            <OwnUpBody variant="body1">
              Here&apos;s a quick overview before you get started!
            </OwnUpBody>
            <Divider />
            {childArray[page - 1]}
          </div>
          <ButtonGrid variant="legacy" spacing={0}>
            <OwnUpGridItem xs={0} sm={5}>
              {BackButton}
            </OwnUpGridItem>
            <CenteredGridItem xs={12} sm={2}>
              <OwnUpBody variant="body1">
                {page} of {childArray.length}
              </OwnUpBody>
            </CenteredGridItem>
            <RightAlignedGridItem xs={12} sm={5}>
              <OwnUpFillButtonPrimary
                icon={<ArrowRightIcon />}
                iconPosition="right"
                onClick={onNextClick}
              >
                {page === childArray.length ? 'Finish' : 'Next'}
              </OwnUpFillButtonPrimary>
            </RightAlignedGridItem>
            <OwnUpGridItem xs={12} sm={0}>
              {BackButton}
            </OwnUpGridItem>
          </ButtonGrid>
        </ModalContent>
      </StyledModal>
    </>
  );
};
