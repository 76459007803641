import { AsyncFunc } from '../func';

export class TimeoutError extends Error {
  constructor(ms: number) {
    super(`Timeout after ${ms} milliseconds`);
  }
}

/**
 * Create a filter that ensures that subsequent filter steps run in less than the given number of milliseconds.
 * The filter function uses setTimeout and Promise.race to handle the timeout. In the case that the remaining
 * steps in the filter function are doing a lot of computationally expensive work and not freeing up the
 * event loop the timeout may not happen.
 * @param ms the number of milliseconds downstream work should take.
 */
export const timeout = (ms: number) =>
  async function <TOut, TArgs extends any[], TThis = void>(
    this: TThis,
    next: AsyncFunc<TOut, TArgs>,
    ...args: TArgs
  ): Promise<TOut> {
    return Promise.race([
      // make next a promise even if it isn't
      Promise.resolve().then(() => next(...args)),
      // trigger a failing promise after given ms
      new Promise<TOut>((_, rej) =>
        setTimeout(() => {
          rej(new TimeoutError(ms));
        }, ms)
      )
    ]);
  };
