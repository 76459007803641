import { createContext } from 'react';

export interface Observer {
  observe(
    target: HTMLElement,
    callback: (intersections: IntersectionObserverEntry[]) => void
  ): () => void;
}

export class ObserverImpl implements Observer {
  private readonly observer: IntersectionObserver;
  private readonly targets: Map<HTMLElement, (intersections: IntersectionObserverEntry[]) => void>;
  constructor(root: HTMLElement | null, rootMargin?: string) {
    this.targets = new Map();
    this.observer = new IntersectionObserver(
      (intersections) => {
        for (const [target, callback] of this.targets) {
          const targetedIntersections = intersections.filter((i) => i.target === target);
          if (targetedIntersections.length > 0) {
            callback(targetedIntersections);
          }
        }
      },
      {
        root,
        threshold: 0.3,
        rootMargin
      }
    );
  }
  observe(
    target: HTMLElement,
    callback: (intersections: IntersectionObserverEntry[]) => void
  ): () => void {
    this.targets.set(target, callback);
    this.observer.observe(target);
    return () => {
      this.targets.delete(target);
      this.observer.unobserve(target);
    };
  }
  disconnect(): void {
    this.observer.disconnect();
  }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const IntersectionContext = createContext<Observer>(new ObserverImpl(null));
