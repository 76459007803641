import { event } from '@rategravity/frontend/modules/user-actions';
import {
  aktivGroteskRegularMixin,
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridWrapper,
  OwnUpSmallBodyMedium,
  OwnUpSmallSubheadlineMedium
} from '@rategravity/own-up-component-library';
import React, { Fragment, useMemo } from 'react';
import styled from 'styled-components';
import { useIsMobile } from '../../../hooks/is-mobile';
import { useActionPipe } from '../../../hooks/use-action-pipe';
import { LockStatus } from '../../modules/get-lock-status';
import { isNonZeroish } from '../../modules/non-zeroish';
import { MonthlyMI } from '../../redux/state';
import { Accordion } from '../accordion';
import { RateActions } from '.';
import { DesktopRateTable } from './desktop-table';
import { MobileRateCard } from './mobile-card';

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
export interface AttributeDisplay {
  displayField: string;
  displayValue: string;
}
export type RateListPermutationProps = {
  name: string;
  attributes: AttributeDisplay[];
  offers: OfferDisplay[];
  loanType?: string;
  showComparisonShopping: boolean;
} & RateActions;

export interface OfferDisplay {
  apr: number;
  cashOutValue: number;
  downPayment: number;
  /** Sum of all "Lender Fee" line items, which includes points and credits */
  lenderFees: number;
  loanType: string;
  logo: any;
  offerId: string;
  pAndI: number;
  rate: number;
  recommend: boolean;
  lenderName: string;
  rateLock: number;
  points: number;
  lenderCredit: number;
  /** Lender-specific Fees, excluding points and credits */
  fixedLenderFees: number;
  monthlyMI: MonthlyMI;
  interestOnly: boolean;
  squareLogo: string;
  lockStatus?: LockStatus;
}

const MobileRatesHeaderContainer = styled.div`
  margin: 32px 0 24px;
`;

const attributesOrderMap: Record<string, number> = {
  downPayment: 0,
  closingCostOption: 1,
  escrowOptions: 2,
  points: 3,
  interestOptions: 4
};

const ListItem = styled.li`
  margin: ${({ theme }) => theme.spacing(1, 0)};
`;

const PermutationAttributeText = styled(OwnUpSmallBodyMedium)`
  & em {
    ${aktivGroteskRegularMixin};
    margin-left: ${({ theme }) => theme.spacing(1)}px;
  }
`;

const PermutationAttributeListItem = ({
  displayField,
  displayValue
}: {
  displayField: string;
  displayValue: string;
}) => (
  <ListItem>
    <PermutationAttributeText variant="body1">
      {displayField}: <em>{displayValue}</em>
    </PermutationAttributeText>
  </ListItem>
);

const PermutationAttributesList = ({
  attributes
}: {
  attributes: Array<{ displayField: string; displayValue: string }>;
}) => (
  <ul>
    {attributes
      .sort((a, b) => attributesOrderMap[a.displayField] - attributesOrderMap[b.displayField])
      .map(({ displayField, displayValue }) => (
        <PermutationAttributeListItem
          key={displayField}
          displayField={displayField}
          displayValue={displayValue}
        />
      ))}
  </ul>
);

export const MobilePermutationHeader = ({
  name,
  attributes
}: {
  name: string;
  attributes: Array<{ displayField: string; displayValue: string }>;
}) => (
  <MobileRatesHeaderContainer>
    <OwnUpSmallSubheadlineMedium variant="h1">{name}</OwnUpSmallSubheadlineMedium>
    <PermutationAttributesList attributes={attributes} />
  </MobileRatesHeaderContainer>
);

export const offersCompare = (
  { rate: rateA, lenderFees: feesA }: OfferDisplay,
  { rate: rateB, lenderFees: feesB }: OfferDisplay
) => (rateA === rateB ? feesA - feesB : rateA - rateB);

const MobileSortedRatesWrapper = ({
  offers,
  onDetailsClick,
  onLockClick,
  onCompareClick,
  showInterestOnly,
  showCompareButton
}: Pick<
  RateListPermutationProps,
  'offers' | 'onDetailsClick' | 'onLockClick' | 'onCompareClick'
> & {
  showInterestOnly: boolean;
  showCompareButton: boolean;
}) => {
  const sortedOffers = useMemo(() => [...offers].sort(offersCompare), [offers]);

  return (
    <Fragment>
      {sortedOffers.map(
        ({
          rate,
          apr,
          lenderName,
          squareLogo,
          lenderCredit,
          points,
          lenderFees,
          fixedLenderFees,
          pAndI,
          lockStatus,
          offerId
        }) => (
          <MobileRateCard
            key={offerId}
            rate={rate}
            apr={apr}
            lenderName={lenderName}
            squareLogo={squareLogo}
            lenderCredit={lenderCredit}
            points={points}
            lenderFees={lenderFees}
            fixedLenderFees={fixedLenderFees}
            pAndI={pAndI}
            showLockRequested={lockStatus === 'PENDING'}
            showLockButton={lockStatus === 'AVAILABLE'}
            showInterestOnly={showInterestOnly}
            onLock={() => onLockClick(encodeURI(offerId))}
            onViewCostBreakdown={() => {
              onDetailsClick(encodeURI(offerId));
            }}
            onCompare={() => onCompareClick(offerId)}
            showCompareButton={showCompareButton}
          />
        )
      )}
    </Fragment>
  );
};

export const RateListPermutation = ({
  offers,
  name,
  loanType,
  attributes,
  onDetailsClick,
  onLockClick,
  onCompareClick,
  showComparisonShopping
}: RateListPermutationProps) => {
  const isMobile = useIsMobile();
  const dispatchAction = useActionPipe();

  const showPoints = offers.some(({ points }) => isNonZeroish(points));
  const showLC = offers.some(({ lenderCredit }) => isNonZeroish(lenderCredit));
  const showMortgageInsurance = offers.some(({ monthlyMI }) => isNonZeroish(monthlyMI.value));
  const showInterestOnly = offers.some(({ interestOnly }) => interestOnly);
  // only show compare button on fixed rate permutations
  const showCompareButton = showComparisonShopping && name.includes('Fixed');
  const onToggleEvent = (open: boolean) =>
    dispatchAction(event(open ? 'clickedShowAllOffers' : 'clickedShowLessOffers'));
  const onAccordionClose = () => dispatchAction(event('collapseOfferTable'));

  return (
    <OwnUpGridWrapper>
      <OwnUpGridContainer variant="slim">
        <OwnUpGridItem xs={12}>
          {isMobile ? (
            <Fragment>
              <MobilePermutationHeader name={name} attributes={attributes} />
              <MobileSortedRatesWrapper
                offers={offers}
                onDetailsClick={onDetailsClick}
                onLockClick={onLockClick}
                onCompareClick={onCompareClick}
                showInterestOnly={showInterestOnly}
                showCompareButton={showCompareButton}
              />
            </Fragment>
          ) : (
            <Accordion
              name={name}
              arrowDisplay={`${offers.length} offers`}
              fields={attributes}
              subtitle={loanType}
              onClose={onAccordionClose}
            >
              <DesktopRateTable
                rows={offers}
                showMortgageInsurance={showMortgageInsurance}
                showLC={showLC}
                showPoints={showPoints}
                showInterestOnly={showInterestOnly}
                onLock={(id) => onLockClick(encodeURI(id))}
                onCompare={(id) => onCompareClick(id)}
                onViewCostBreakdown={(id) => {
                  onDetailsClick(encodeURI(id));
                }}
                showCompareButton={showCompareButton}
                onToggle={onToggleEvent}
              />
            </Accordion>
          )}
        </OwnUpGridItem>
      </OwnUpGridContainer>
    </OwnUpGridWrapper>
  );
};
