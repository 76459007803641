import { rate as formatRate } from '@rategravity/frontend/modules/numbers';
import {
  FOG_100,
  OwnUpBody,
  OwnUpBodyMedium,
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpSubheadlineMedium,
  OwnUpSubheadlineRegular
} from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';

const RateWrapper = styled.div`
  border-right: 1px solid ${FOG_100};
  height: 100%;
  ${({ theme }) => theme.breakpoints.down('md')} {
    border-right: none;
    border-bottom: 1px solid ${FOG_100};
    padding-bottom: 32px;
  }
`;

const RateValueWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const RateDetails = ({ rate, apr }: { rate: number; apr: number }) => (
  <RateWrapper>
    <OwnUpBody variant="body1" style={{ marginBottom: '8px' }}>
      Rate / APR
    </OwnUpBody>
    <RateValueWrapper>
      <OwnUpSubheadlineMedium variant="h1">{formatRate(rate, 3)}</OwnUpSubheadlineMedium>
      <OwnUpSubheadlineRegular variant="h1" style={{ whiteSpace: 'pre' }}>
        {' / ' + formatRate(apr, 3)}
      </OwnUpSubheadlineRegular>
    </RateValueWrapper>
  </RateWrapper>
);

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  :not(:last-child) {
    margin-bottom: 8px;
  }
  :first-child {
    ${({ theme }) => theme.breakpoints.down('md')} {
      margin-top: 32px;
    }
  }
`;

const RightCol = styled(OwnUpGridItem)`
  text-align: right;
  ${({ theme }) => theme.breakpoints.only('md')} {
    text-align: left;
  }
  ${({ theme }) => theme.breakpoints.only('sm')} {
    text-align: left;
  }
`;

export const ScenarioDetails = ({ values }: { values: { name: string; value: string }[] }) => (
  <OwnUpGridContainer variant="slim" spacing={0}>
    {values.map(({ name, value }, i) => (
      <Row key={i}>
        <OwnUpGridItem xs={6}>
          <OwnUpBody variant="body1">{name}</OwnUpBody>
        </OwnUpGridItem>
        <RightCol xs={6}>
          <OwnUpBodyMedium variant="body1">{value}</OwnUpBodyMedium>
        </RightCol>
      </Row>
    ))}
  </OwnUpGridContainer>
);
